export default (pageCaptchaConfigs = [], captchaImplementationConfig = []) => {

    const captchaResults = [];

    try {

        for (let i = 0; i < pageCaptchaConfigs.length; i++) {

            const pageCaptchaConfig = pageCaptchaConfigs[i];
            const implementationConfig = captchaImplementationConfig.find(impl => impl.implementation === pageCaptchaConfig.implementation);

            if (implementationConfig
                && pageCaptchaConfig.enabled
                && implementationConfig.enabled
                && implementationConfig.token) {

                captchaResults.push({

                    implementation: implementationConfig.implementation,
                    token: implementationConfig.token,
                    isForced: pageCaptchaConfig.isForcedConfiguration && pageCaptchaConfig.enabled

                });

            }

        }

    } catch (error) {

        console.error(error);

    }

    return captchaResults;

};