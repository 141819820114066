const getDateString = incomingDate => {
	const date = typeof incomingDate !== 'object' ? new Date(incomingDate || null) : incomingDate;
	let dd = date.getDate();
	let mm = date.getMonth() + 1;
	const yyyy = date.getFullYear();

	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}

	return `${mm}/${dd}/${yyyy}`;
};

const incrementDate = (date, days) => new Date(getDateString(date.setDate(date.getDate() + days)));

const compareDates = (min, max) => new Date(getDateString(min)) < new Date(getDateString(max));

const now = new Date();
const todayString = getDateString(now);
const todayDate = new Date(todayString);
const today = {
	string: todayString,
	date: todayDate
};

const yesterdayDate = incrementDate(todayDate, -1);
const yesterdayString = getDateString(yesterdayDate);
const yesterday = {
	string: yesterdayString,
	date: yesterdayDate
};

const tomorrowDate = incrementDate(todayDate, 1);
const tomorrowString = getDateString(tomorrowDate);
const tomorrow = {
	string: tomorrowString,
	date: tomorrowDate
};

const getAge = birthDay => {
	if (typeof birthDay.getMonth !== 'function') {
		birthDay = new Date(birthDay);
	}

	let yearDiff = today.date.getFullYear() - birthDay.getFullYear();
	const monthDiff = today.date.getMonth() - birthDay.getMonth();
	const todayIsLess = today.date.getDate() < birthDay.getDate();

	return monthDiff < 0 || (monthDiff === 0 && todayIsLess) ? yearDiff - 1 : yearDiff;
};

const getDateTimeString = incomingDate => {
	const date = typeof incomingDate === 'object' ? incomingDate : new Date(incomingDate || null);
	let dd = date.getDate();
	let MM = date.getMonth() + 1;
	const yyyy = date.getFullYear();
	let hh = date.getHours();
	let mm = date.getMinutes();
	let ss = date.getSeconds();
	let timePart = 'AM';

	if (dd < 10) {
		dd = `0${dd}`;
	}

	if (MM < 10) {
		MM = `0${MM}`;
	}

	if (hh > 12) {
		hh -= 12;
		hh = `0${hh}`;
		timePart = 'PM';
	} else if (hh < 10) {
		hh = `0${hh}`;
	}

	if (mm < 10) {
		mm = `0${mm}`;
	}

	if (ss < 10) {
		ss = `0${ss}`;
	}

	return `${MM}/${dd}/${yyyy} ${hh}:${mm}:${ss} ${timePart}`;
};

export { today, yesterday, tomorrow, getDateString, incrementDate, compareDates, getAge, getDateTimeString };

export default {
	today,
	yesterday,
	tomorrow,
	getDateString,
	incrementDate,
	compareDates,
	getAge,
	getDateTimeString
};
