import { mapMutations } from 'vuex';

export default {
	data() {
		return {
			loading: false,
			submitting: false
		};
	},
	methods: {
		...mapMutations(['setLoading']),
		showSpinner({ loading = false, submitting = false }) {
			this.loading = loading;
			this.submitting = submitting;

			this.setLoading(loading || submitting);
		}
	}
};
