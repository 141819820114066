export default {

    namespaced: true,
	state: {
        
        statusType: '500',
        statusText: 'Error',
        message: 'There was an error processing your request.'

	},
	mutations: {
        
        setData(state, { objName, data }) { state[objName] = data; },
        resetState(state) {
            
            state.statusType = '500';
            state.statusText = 'Error';
            state.message = 'There was an error processing your request.';

        }

    }

};