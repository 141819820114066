<template>
	<section class="accordion-wrapper">
		<button @click="isOpen = !isOpen" class="button">
			<p class="title">{{ title }}</p>
			<BaseIcon name="Arrow" :title="isOpen ? 'Collapse' : 'Expand'" height="1.25rem" width="1.25rem" :class="[isOpen ? 'icon-reversed' : 'icon']">
				<ChevronRight />
			</BaseIcon>
		</button>
		<section v-if="isOpen" class="content">
			<slot />
		</section>
	</section>
</template>

<script>
import { BaseIcon, ChevronRight } from '@/components/SwitchThink/icons';

export default {
	name: 'Accordion',
	components: {
		BaseIcon,
		ChevronRight
	},
	props: {
		title: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isOpen: false
		};
	}
};
</script>

<style lang="scss" scoped>
.accordion-wrapper {
	border: 1px solid #ddd;

	.button {
		background: none;
		border: none;
		padding: 0.5rem;
		margin: 0;
		cursor: pointer;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:hover {
			opacity: 0.6;
		}
	}

	.title {
		flex: 1;
		margin: 0;
		padding: 0;
		margin-right: 1rem;
		font-size: 1rem;
		text-align: left;
	}

	.icon {
		transform: rotate(90deg);
	}
	.icon-reversed {
		transform: rotate(-90deg);
	}

	.content {
		padding: 1rem;
		padding-top: 0;
	}
	@media only screen and (min-width: 850px) {
		.title {
			font-size: 0.85rem;
		}
	}
}
</style>
