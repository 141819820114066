<template functional>
	<g>
		<path
			fill="#ADB4BA"
			d="M9,3.4c1.8,0,3.2,1.4,3.2,3.2S10.8,9.8,9,9.8S5.8,8.4,5.8,6.6S7.2,3.4,9,3.4z M3.5,15.3
		c-0.1-1-0.2-3.1,0.4-4.4c0,0.1,0.5-1.7,2.4-1.6c0,0,1.5,1.3,3,1.1c0,0,1.2,0.1,2.6-1.3c0,0,2,0,2.5,2.4c0,0,0.4,1.4,0.2,3.8l0,0
		c-1.5,1.3-3.4,2.1-5.5,2.1C6.9,17.4,4.9,16.6,3.5,15.3L3.5,15.3z"
		/>
		<path
			fill="#2391EC"
			d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9s3.8,8.4,8.4,8.4
		s8.4-3.8,8.4-8.4S13.6,0.6,9,0.6z"
		/>
	</g>
</template>





<script>
export default {
	name: 'SingleOwner'
};
</script>