import { constructUrl } from './index';
import router from '@/router';
import store from '@/store';

export default (error, previousRoute = {}) => {
    
    console.error(error);
    
    const { response = null, message = 'There was an error processing your request.' } = error;

    const statusType = response && response.status ? response.status : '500';
    const statusText = response && response.statusText ? response.statusText : 'Error';
    const productType = previousRoute.params && previousRoute.params.productType ? previousRoute.params.productType : '';

    store.commit('error/setData', { objName: 'statusType', data: statusType });
    store.commit('error/setData', { objName: 'statusText', data: statusText });
    store.commit('error/setData', { objName: 'message', data: message });
    store.commit('error/setData', { objName: 'productType', data: productType });

    const params = { statusType, statusText, message, productType };

    return productType
        ? router.push(constructUrl('Error', params))
        : router.push({ name: 'Error', params});


};