export default {
	NONE: 0,
	AUTO: 1,
	MARINE: 2,
	MOTORCYCLE: 3,
	RV: 4,
	0: 'None',
	1: 'Auto',
	2: 'Marine',
	3: 'Motorcycle',
	4: 'RV'
};
