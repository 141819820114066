<template>
	<div class="message-box">
		<div v-if="!noteSubmitted">
			<div v-if="isAuthenticated">
				Click
				<a @click="openNoteModal" title="Leave a note for your loan specialist." class="contact-text lg">
					here
				</a>
				to send a note to your loan specialist.
				<transition name="fade">
					<StsFormModal
						v-if="showNoteModal"
						@close="closeModal"
						title="Is there anything you need to share with your loan specialist?"
					>
						<template>
							<ValidationObserver
								ref="observer"
								v-slot="{ invalid }"
								tag="form"
								@submit.prevent="handleSubmit"
							>
								<section>
									<StsVTextArea
										class="modal-text-title"
										v-model="noteText"
										:disabled="submitting"
										:custom-messages="{ required: 'Please enter note before clicking Submit.' }"
										label=""
										type="textarea"
										validationRules="required|max:1024"
									/>
									<StsButton :disabled="invalid || submitting" button-type="submit" title="SUBMIT" />
								</section>
							</ValidationObserver>
						</template>
					</StsFormModal>
				</transition>
			</div>

			<div>&nbsp;</div>

			Call us at
			<a
				v-bind:href="`tel:${defaultPhoneNumber ? defaultPhoneNumber : ''}`"
				title="Have a question? Call us!"
				class="contact-text lg"
			>
				{{ defaultPhoneNumber || '' }}
			</a>
			or contact your loan specialist directly.
		</div>
		<div v-if="noteSubmitted">Your note has been submitted to your loan specialist.</div>
	</div>
</template>

<script>
	import { noteAPI } from '@/api';
	import { StsButton, StsFormModal } from '@/components/SwitchThink';
	import { StsVTextArea } from '@/components/SwitchThink/form';
	import showSpinner from '@/mixins/showSpinner';
	import { ruleBuilder, toastConfig } from '@/utils';
	import axios from 'axios';
	import { ValidationObserver } from 'vee-validate';
	import { createToastInterface } from 'vue-toastification';

	import { mapGetters, mapState } from 'vuex';

	ruleBuilder(['required', 'digits', 'max']);
	const toast = createToastInterface(toastConfig);

	export default {
		name: 'ContactModal',
		components: {
			StsVTextArea,
			StsFormModal,
			StsButton,
			ValidationObserver
		},
		mixins: [showSpinner],
		data() {
			return {
				defaultPhoneNumber: '',
				loggedIn: true,
				title: 'Additional Comments (Optional)',
				noteText: '',
				showNoteModal: false,
				noteSubmitted: false
			};
		},
		computed: {
			...mapGetters('applicant', ['isAuthenticated']),
			...mapGetters('applicant', ['activeApplication']),
			...mapState({
				modal: state => state.modal
			})
		},
		async created() {
			try {
				this.showSpinner({ loading: true });
				const {
					data: { defaultPhoneNumber }
				} = await axios.get('/api/Configuration/DefaultPhoneNumber');
				this.defaultPhoneNumber = defaultPhoneNumber;
				this.showSpinner({ loading: false });
			} catch (error) {
				this.showSpinner({ loading: false });
				toast.error(error);
			}
		},
		methods: {
			async handleSubmit() {
				try {
					const isValid = await this.$refs.observer.validate();

					if (!isValid) {
						return toast.error('Additional comment is invalid.');
					}

					this.showSpinner({ submitting: true });

					return await this.submitNote();
				} catch (error) {
					this.showSpinner({ submitting: false });

					return toast.error(error);
				}
			},
			async submitNote() {
				try {
					/** @type {noteCreateViewModelRequest} */
					const request = {
						applicationId: this.activeApplication.application.applicationId,
						noteText: this.noteText
					};
					this.showSpinner({ submitting: true });

					await noteAPI.createNote(request);
					this.noteSubmitted = true;
					this.modal.title = 'Note submitted!';
					this.showSpinner({ submitting: false });
					return this.closeModal();
				} catch (error) {
					this.showSpinner({ submitting: false });

					return toast.error('Failed to send note, try again later');
				}
			},
			closeModal() {
				this.showNoteModal = false;
			},
			openNoteModal() {
				this.showNoteModal = true;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.contact-text {
		color: var(--secondary);
		text-decoration: none;
		font-weight: 600;
		transition: color 0.3s;

		&:hover {
			color: var(--primary);
		}

		&.lg {
			font-size: 1.25rem;
			line-height: 1.25rem;
		}

		&.sm {
			font-size: 0.85rem;
			line-height: 0.85rem;
		}
	}

	.message-box {
		text-align: center;
		padding: 1.5rem;
		position: relative;
		border: 1px solid var(--tertiary);
		border-radius: 11px;
	}

	#contact-modal {
		text-align: left !important;
		color: var(--black-75) !important;
	}

	a {
		cursor: pointer;
	}
</style>
