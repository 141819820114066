import { CaptchaImplementation } from '@/constants';
import { buildCaptchaImplementationConfigurations } from '@/utils';
import Axios from 'axios';
import _cloneDeep from 'lodash.clonedeep';

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import modules from './modules';

const axios = Axios.create({ timeout: 0 });

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
			paths: [
				'applicant',
				'challenge',
				'branding.colors',
				'branding.images',
				'branding.name',
				'config',
				'error',
				'routeEnforcer',
				'sessionTimer',
                'uiTextValues'
			]
		})
	],
	modules,
	state: {
		isLoading: false,
		defaultsLoaded: false,
		siteAvailability: null,
		token: null,
		unsupportedBrowser: false,
		captchaUseConfig: null,
		captchaV3: {
			enabled: false,
			implementation: CaptchaImplementation.RECAPTCHAV3
		},
		captchaV2: {
			enabled: false,
			implementation: CaptchaImplementation.RECAPTCHAV2
		},
		captchaV2Hidden: {
			enabled: false,
			implementation: CaptchaImplementation.RECAPTCHAV2HIDDEN
		},
		redirectUrl: '',
		allowApplicantSearch: false,
		allowAuthenticatedApplicantSearch: false,
		collateralValuationAvailability: null
	},
	mutations: {
		/**
		 *
		 * @param {any} state Vue state
		 * @param {boolean} isLoading True if we're still loading the page
		 */
		setLoading(state, isLoading) {
			state.isLoading = isLoading;
		},
		setData(state, { objName, data }) {
			state[objName] = data;
		},
		resetState(state) {
			state.isLoading = false;
			state.defaultsLoaded = false;
			state.siteAvailability = null;
			state.unsupportedBrowser = false;
			state.captchaUseConfig = null;
			state.captchaV3 = {
				enabled: false,
				implementation: CaptchaImplementation.RECAPTCHAV3
			};
			state.captchaV2 = {
				enabled: false,
				implementation: CaptchaImplementation.RECAPTCHAV2
			};
			state.captchaV2Hidden = {
				enabled: false,
				implementation: CaptchaImplementation.RECAPTCHAV2HIDDEN
			};
			state.redirectUrl = '';
			state.allowApplicantSearch = false;
			state.allowAuthenticatedApplicantSearch = false;
			state.collateralValuationAvailability = null;
		}
	},
	actions: {
		async setDefaults({ commit, dispatch, state }, isDisplayOnly) {
			try {
				if (!isDisplayOnly) {
					commit('setLoading', true);

					await Promise.all([
						dispatch('getApplicationConfiguration'),
						dispatch('branding/getBranding'),
						dispatch('getSiteAvailability'),
						dispatch('getCollateralValuationAvailability')
					]);

					commit('setData', {
						objName: 'defaultsLoaded',
						data: true
					});
					commit('setLoading', false);

					return state.defaultsLoaded;
				}

				return await dispatch('branding/getBranding');
			} catch (error) {
				commit('setLoading', false);

				throw new Error(error);
			}
		},
		async getApplicationConfiguration({ commit, state }) {
			if (state.captcha) {
				return state.captcha;
			}

			try {
				const {
					data: {
						captchaConfigurations: captcha,
						captchaUseConfigurations: captchaUseConfig,
						redirectUrl,
						allowApplicantSearch,
						allowAuthenticatedApplicantSearch
					}
				} = await axios.get('/api/Application/Configuration');

				if (captcha) {
					let captchaConfigs = buildCaptchaImplementationConfigurations(captcha);

					if (captchaConfigs.v2.enabled) {
						commit('setData', {
							objName: 'captchaV2',
							data: captchaConfigs.v2
						});
					}
					if (captchaConfigs.v2Hidden.enabled) {
						commit('setData', {
							objName: 'captchaV2Hidden',
							data: captchaConfigs.v2Hidden
						});
					}
					if (captchaConfigs.v3) {
						commit('setData', {
							objName: 'captchaV3',
							data: captchaConfigs.v3
						});
					}
				}

				if (captchaUseConfig) {
					commit('setData', {
						objName: 'captchaUseConfig',
						data: captchaUseConfig
					});
				}

				if (redirectUrl) {
					commit('setData', {
						objName: 'redirectUrl',
						data: redirectUrl
					});
				}

				if (allowApplicantSearch) {
					commit('setData', {
						objName: 'allowApplicantSearch',
						data: allowApplicantSearch
					});
				}

				if (allowAuthenticatedApplicantSearch) {
					commit('setData', {
						objName: 'allowAuthenticatedApplicantSearch',
						data: allowAuthenticatedApplicantSearch
					});
				}

				return {
					captcha,
					redirectUrl
				};
			} catch (error) {
				throw error;
			}
		},
		async getSiteAvailability({ commit, state }) {
			if (state.siteAvailability) {
				return state.siteAvailability;
			}

			try {
				const {
					data: {
						isMemberChannelDisabled: memberDisabled = false,
						memberChannelDisabledMessage: memberMessage = '',
						isNonMemberChannelDisabled: nonMemberDisabled = false,
						nonMemberChannelMessage: nonMemberMessage = '',
						isBrowserUnsupported,
						token
					}
				} = await axios.get(`/api/Availability`);

				const data = {
					memberDisabled,
					nonMemberDisabled,
					memberMessage,
					nonMemberMessage
				};

				commit('setData', {
					objName: 'siteAvailability',
					data
				});

				commit('setData', {
					objName: 'unsupportedBrowser',
					data: isBrowserUnsupported ? true : false
				});

				commit('setData', {
					objName: 'token',
					data: token
				});

				return data;
			} catch (error) {
				throw error;
			}
		},
		resetState({ commit, dispatch }) {
			commit('resetState');
			dispatch('applicant/resetState');
			commit('config/resetState');
			commit('error/resetState');

			//dispatch('setDefaults');
		},
		updateCaptcha({ commit, state }, { implementation, captcha }) {
			if (captcha) {
				switch (implementation) {
					case CaptchaImplementation.RECAPTCHAV2:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV2), captcha);
							commit('setData', {
								objName: 'captchaV2',
								data: merged
							});
						}
						break;
					case CaptchaImplementation.RECAPTCHAV2HIDDEN:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV2Hidden), captcha);
							commit('setData', {
								objName: 'captchaV2Hidden',
								data: merged
							});
						}
						break;
					case CaptchaImplementation.RECAPTCHAV3:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV3), captcha);
							commit('setData', {
								objName: 'captchaV3',
								data: merged
							});
						}
						break;
				}
			}
		},
		updateCaptchaUse({ commit, state }, captchaUseConfig) {
			const origUseConfigs = _cloneDeep(state.captchaUseConfig);

			const index = origUseConfigs.findIndex(
				config =>
					config.implementation === captchaUseConfig.implementation &&
					config.processingStage === captchaUseConfig.processingStage
			);

			if (index) {
				origUseConfigs.splice(index, 1);
				origUseConfigs.push(captchaUseConfig);
			}

			commit('setData', {
				objName: 'captchaUseConfig',
				data: origUseConfigs
			});
		},
		async getCollateralValuationAvailability({ commit, state }) {
			if (state.collateralValuationAvailability) {
				return state.collateralValuationAvailability;
			}

			const {
				data: {
					isCollateralValuationAutoDisabled = false,
					isCollateralValuationAutoRefiDisabled = false,
					isCollateralValuationMarineDisabled = false,
					isCollateralValuationMotorcycleDisabled = false,
					isCollateralValuationRvDisabled = false
				}
			} = await axios.get(`/api/CollateralValuation/Availability`);

			const data = {
				isCollateralValuationAutoDisabled,
				isCollateralValuationAutoRefiDisabled,
				isCollateralValuationMarineDisabled,
				isCollateralValuationMotorcycleDisabled,
				isCollateralValuationRvDisabled
			};

			commit('setData', {
				objName: 'collateralValuationAvailability',
				data
			});

			return data;
		}
	},
	getters: {
		xsrfToken: state => state.token,
		captchaV3: state => state.captchaV3,
		captchaV2: state => state.captchaV2,
		captchaV2Hidden: state => state.captchaV2Hidden,
		captchaConfig: state => [state.captchaV2, state.captchaV2Hidden, state.captchaV3],
		captchaUseConfig: state => state.captchaUseConfig
	}
});
