var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({class:[
		'is-' + _vm.type,
		{
			'is-fullwidth': _vm.fullwidth,
			'is-small': _vm.small,
			'is-lowercase': _vm.lowercase,
			'is-alternative': _vm.alternative
		}
	],attrs:{"type":_vm.buttonType,"title":_vm.title,"disabled":_vm.disabled}},_vm.$listeners),[(_vm.$slots.default)?_c('div',{staticClass:"default-slot"},[_vm._t("default")],2):_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.type === 'text' && !_vm.noIcon)?_c('BaseIcon',{attrs:{"name":"arrow"}},[_c('Arrow')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }