export default {
	cards: '/img/pages/cards.jpg',
	contact: '/img/pages/contact.jpg',
	disclosures: '/img/pages/disclosures.jpg',
	eligibility: '/img/pages/eligibility.jpg',
	employment: '/img/pages/employment.jpg',
	funding: '/img/pages/funding.jpg',
	fundingcard: '/img/pages/fundingcard.jpg',
	fundingtransfer: '/img/pages/fundingtransfer.jpg',
	authentication: '/img/pages/authentication.jpg',
	identity: '/img/pages/identity.jpg',
	joint: '/img/pages/joint.jpg',
	logo: '/img/logo.svg',
	print: '/img/pages/print.jpg',
	select: '/img/pages/select.jpg',
	sign: '/img/pages/sign.jpg',
	verify: '/img/pages/verify.jpg',
	reselect: '/img/pages/cards.jpg',
	default: '/img/pages/default.jpg'
};
