import { axios } from '@/utils';

/**
 * @typedef {object} noteCreateViewModelRequest
 * @property {number} applicationId Application ID
 * @property {string} noteText Note Text
 */

/**
 * @param {noteCreateViewModelRequest} request The create seller request.
 * @returns {Promise<boolean>} True if the seller was created successfully.
 */
const createNote = async request => {
	const { created } = await axios.post('/api/Application/Note', request);

	return created;
};

/**
 * @typedef {object} note
 * @property {number} id Note id
 * @property {number} applicationId Application id
 * @property {string} noteText Note Text
 * @property {string} createdDate Created Date
 */

/**
 * @param {number} applicationId The application id.
 * @returns {Promise<note[]>} The notes for the application.
 */
const getNotes = async applicationId => {
	const { data } = await axios.get(`/api/Application/${applicationId}/Notes`);

	return data;
};

export default { createNote, getNotes };
