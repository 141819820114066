<template>
	<section>
		<header :class="{ 'is-Solo': onlyOneTab }">
			<button
				type="button"
				v-for="tab in tabs"
				:key="tab.id"
				:class="{ 'is-active': tab.isActive, 'is-Solo': onlyOneTab }"
				:style="{ 'background-color': getTabColorByActiveState(tab.isActive) }"
				@click="selectTab(tab, tab.id)"
			>
				{{ tab.name }}
			</button>
		</header>
		<article :id="containerId" :style="{ 'min-height': height }" :class="[!noShadow && 'box-shadow']">
			<slot />
		</article>
	</section>
</template>

<script>
	import { getDeviceSize } from '@/mixins';

	export default {
		name: 'Tabs',
		mixins: [getDeviceSize],
		props: {
			noShadow: {
				type: Boolean,
				required: false
			},
			noGap: {
				type: Boolean,
				required: false
			},
			desktopBreakpoints: {
				type: Array,
				required: false,
				default: () => [2, 1]
			},
			mobileBreakpoints: {
				type: Array,
				required: false,
				default: () => [2, 1]
			},
			inactiveTabColor: {
				type: String,
				required: false
			},
			activeTabColor: {
				type: String,
				required: false
			}
		},
		data() {
			return {
				tabs: [],
				onlyOneTab: false,
				height: 'auto'
			};
		},
		computed: {
			containerId() {
				return `tabs-container-${this._uid}`;
			},
			sortedBreakpoints() {
				let desktop = [...this.desktopBreakpoints].sort((a, b) => b - a);

				// If there's more than 1 tab and the last desktop breakpoint is 1, remove it
				if (this.tabs.length > 1 && desktop[desktop.length - 1] === 1) {
					desktop.pop();
				}

				return {
					desktop,
					mobile: [...this.mobileBreakpoints].sort((a, b) => b - a)
				};
			},
			breakpoints() {
				const totalTabLength = this.tabs.length;
				const availableBreakpoints = this.isMobile
					? this.sortedBreakpoints.mobile
					: this.sortedBreakpoints.desktop;
				let closestMatchTuple = [null, null]; // [Breakpoint, Difference]

				// If there's only one tab, return the smallest breakpoint
				if (totalTabLength === 1) {
					return availableBreakpoints[availableBreakpoints.length - 1];
				}

				// Else, loop through our breakpoint and return the first one that has no remainder or the smallest division amount
				for (const breakpoint of availableBreakpoints) {
					if (!(totalTabLength % breakpoint)) {
						return breakpoint;
					}

					const divisionDifference = totalTabLength / breakpoint;

					if (!closestMatchTuple[0] || closestMatchTuple[1] > divisionDifference) {
						closestMatchTuple = [breakpoint, divisionDifference];
					}
				}

				return closestMatchTuple[0];
			}
		},
		async created() {
			this.tabs = await this.$children;
			if (this.tabs.length === 1) {
				this.onlyOneTab = true;
			}
			this.emitChange(this.setInitialData(this.tabs));
		},
		methods: {
			setInitialData(tabs) {
				for (const tab of tabs) {
					if (tab.isActive) {
						return tab.id;
					}
				}
			},
			selectTab(selectedTab, id) {
				this.tabs.forEach(tab => {
					tab.isActive = tab.name == selectedTab.name;
				});

				this.emitChange(id);
			},
			emitChange(id) {
				this.$emit('select', id);
			},
			matchHeight(children) {
				let heights = [];

				children.forEach(child => {
					if (child.$el.offsetHeight) {
						heights.push(child.$el.offsetHeight);
					} else {
						child.isActive = true;
						this.$nextTick(() => {
							heights.push(child.$el.offsetHeight);
							child.isActive = false;
						});
					}
				});

				this.height = `${Math.max.apply(null, heights)}px`;
			},
			getTabColorByActiveState(active) {
				let color;

				if (active) {
					color = this.activeTabColor ? this.activeTabColor : 'var(--white)';
				} else {
					color = this.inactiveTabColor ? this.inactiveTabColor : 'var(--white-75)';
				}

				return color;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.is-Solo {
		text-align: center;
		width: 100%;
		display: block;
	}
	* {
		box-sizing: border-box;
	}
	section {
		header {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 0.25rem;

			button {
				text-align: center;
				padding: 0.5rem;
				color: var(--primary-75);
				cursor: pointer;
				font-weight: 400;
				font-size: 1rem;
				transition: color 0.5s, background-color 0.5s, border-bottom-color 0.5s;
				border: 2px solid transparent;
				border-bottom: 2px solid var(--primary-25);
				background-color: var(--lightGrey-25);

				&.is-active {
					color: var(--primary);
					font-weight: 600;
					border-bottom-color: var(--white);
					background-color: var(--white);

					&:hover {
						border-bottom-color: var(--white);
					}
				}

				&:hover {
					font-weight: 600;
					color: var(--primary);
				}
			}
		}

		article {
			background: var(--white);
			padding: 1.75rem;
		}
	}
</style>
