import _cloneDeep from 'lodash.clonedeep';

import { applicantAPI, applicationAPI } from '@/api';
import { MemberType } from '@/constants';
import {
	applicantActions,
	applicantMutations,
	applicantState,
	arrayMutations,
	objectMutations,
	resetApplicantState
} from '../shared';
import application from '@/api/application';

export default {
	namespaced: true,
	state: {
		...applicantState,
		userName: '',
		memberType: MemberType.NONE,
		activeApplication: 0,
		activeApplicationIsDeclined: false,
		activeApplicationProductTypeID: 0,
		applications: []
	},
	mutations: {
		...arrayMutations,
		...objectMutations,
		...applicantMutations,
		setUserData(
			state,
			{
				memberType = MemberType.NONE,
				firstName = '',
				lastName = '',
				userName = '',
				applicantId = '',
				applicantToken = '',
				applications = []
			}
		) {
			state.memberType = memberType;
			state.firstName = firstName;
			state.lastName = lastName;
			state.userName = userName;
			state.applicantId = applicantId;
			state.applicantToken = applicantToken;
			state.applications = applications;
		},
		resetContainedState(state) {
			state.userName = '';
			state.memberType = MemberType.NONE;
			state.identificationId = 0;
			state.activeApplication = 0;
			state.activeApplicationIsDeclined = false;
			state.activeApplicationProductTypeID = 0;
			state.applications = [];
		},
		resetApplicantState
	},
	actions: {
		...applicantActions,
		resetState({ commit }) {
			commit('resetContainedState');
			commit('resetApplicantState');
		},
		async logOut({ commit }) {
			try {
				await applicantAPI.logOut();

				commit('setData', { objName: 'sessionToken', data: '' });
				commit('setData', { objName: 'authenticated', data: false });

				return true;
			} catch (error) {
				throw error;
			}
		},
		async authenticateApplicant({ commit, state }, request) {
			try {
				const {
					applicantId,
					applicantToken,
					isVerified,
					isEligibleToUse,
					captchaAction,
					requiresMFA,
					sessionToken,
					authenticated
				} = await applicantAPI.authenticateApplicant({ ...request, applicantToken: state.applicantToken });

				commit('setUserData', {
					memberType: MemberType.EXISTING,
					applicantId,
					applicantToken: applicantToken ? applicantToken : state.applicantToken
				});

				commit('config/setData', { objName: 'forceMFAAuthentication', data: requiresMFA }, { root: true });
				commit('setData', { objName: 'sessionToken', data: sessionToken });
				commit('setData', { objName: 'authenticated', data: authenticated });

				return { isVerified, isEligibleToUse, captchaAction, requiresMFA };
			} catch (error) {
				throw error;
			}
		},
		async authenticateSSO({ commit, state }, request) {
			try {
				const {
					applicantId,
					applicantToken,
					isVerified,
					isEligibleToUse,
					captchaAction,
					requiresMFA,
					sessionToken,
					authenticated
				} = await applicantAPI.authenticateSSO({ ...request, applicantToken: state.applicantToken });

				commit('setUserData', {
					memberType: MemberType.EXISTING,
					applicantId,
					applicantToken: applicantToken ? applicantToken : state.applicantToken
				});

				commit('config/setData', { objName: 'forceMFAAuthentication', data: requiresMFA }, { root: true });
				commit('setData', { objName: 'sessionToken', data: sessionToken });
				commit('setData', { objName: 'authenticated', data: authenticated });

				return { isVerified, isEligibleToUse, captchaAction, requiresMFA };
			} catch (error) {
				throw error;
			}
		},
		async verifyApplicant({ commit, state }, request) {
			try {
				const {
					applicantId,
					applicantToken,
					captchaAction,
					isEligibleToUse,
					isVerified,
					requiresMFA,
					sessionToken,
					authenticated
				} = await applicantAPI.verifyApplicant({ ...request, applicantToken: state.applicantToken });

				const { lastName } = request;

				commit('setUserData', {
					memberType: MemberType.NEW,
					lastName,
					applicantToken: applicantToken ? applicantToken : state.applicantToken,
					applicantId
				});

				commit('config/setData', { objName: 'forceMFAVerification', data: requiresMFA }, { root: true });
				commit('setData', { objName: 'sessionToken', data: sessionToken });
				commit('setData', { objName: 'authenticated', data: authenticated });

				return { isVerified, isEligibleToUse, captchaAction, requiresMFA };
			} catch (error) {
				throw error;
			}
		},
		async loadApplicant({ commit }, request) {
            try {
                const { applicantId, applicantToken, sessionToken } = await applicantAPI.loadApplicant(request);

                commit('setUserData', { applicantToken, applicantId });
                commit('setData', { objName: 'sessionToken', data: sessionToken });

				return { applicantId, applicantToken };
			} catch (error) {
				throw error;
			}
		},
		async getApplicant({ commit, state }) {
			try {
				const applicant = await applicantAPI.getApplicant(state.applicantToken);

				commit('setUserData', {
					applicantToken: applicant.token,
					firstName: applicant.firstName,
					lastName: applicant.lastName
				});

				return applicant;
			} catch (error) {
				throw error;
			}
		},
		async loadApplications({ commit, state }) {
			if (state.applications && state.applications.length > 0) {
				return state.applications;
			}

			try {
				const { applicationIds } = await applicationAPI.getApplicationIds(state.applicantToken);

				const applications = applicationIds.map(id => ({
					id,
					moduleName: `application_${id}`,
					isActive: false
				}));

				commit('setData', { objName: 'applications', data: applications });

				return _cloneDeep(applications);
			} catch (error) {
				throw error;
			}
		},
		updateApplication({ commit, state }, data) {
			commit('updateItem', { objName: 'applications', data: data });
		},
		setActiveApplication({ commit, state }, moduleName) {
			var applicationIndex = state.applications.findIndex(x => x.moduleName === moduleName);
			commit('setData', { objName: 'activeApplication', data: applicationIndex });
		},
		setActiveApplicationIsDeclined({ commit }, isDeclined) {
			commit('setData', { objName: 'activeApplicationIsDeclined', data: isDeclined });
		},
		setActiveApplicationProductTypeID({ commit }, productTypeID) {
			commit('setData', { objName: 'activeApplicationProductTypeID', data: productTypeID });
		}
	},
	getters: {
		hasApplicant: state => state.firstName && state.lastName && state.memberType,
		fullName: state => (state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : ''),
		isMember: state => state.memberType === MemberType.EXISTING,
		activeApplication: state => state.applications[state.activeApplication],
		welcomeMessage: state => state.welcomeMessage,
		isAuthenticated: state => state.authenticated,
		sessionToken: state => state.sessionToken,
		applicantToken: state => state.applicantToken
	}
};
