<template functional>
	<g>
		<path
			d="M17,7.7C16.9,7.6,16.9,7.6,17,7.7l-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.1L17.1,7c0.4-0.5,0.3-1.2-0.1-1.6
		c0,0-0.1,0-0.1-0.1l0,0c-0.1,0-0.1-0.1-0.2-0.1L17,4.8c0.2-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.7-0.4-0.9L17,3
		c-0.2-0.2-0.5-0.4-0.8-0.4c-0.2,0-0.4,0-0.5,0.1V1.5C15.6,0.7,15,0,14.1,0H7.7C6.9,0,6.2,0.7,6.2,1.5v3.3C5.9,4.9,5.7,5.1,5.6,5.4
		C5.4,6,5.3,6.5,5.2,6.9c-0.3,0.9-0.4,1.3-0.7,2c-0.3,0.7-0.3,1.6-0.3,2.2c0,0.3,0,0.7-0.1,0.8c0,0.1-0.3,0.5-0.6,0.9l-0.4-0.4
		c-0.1-0.1-0.4-0.1-0.5,0l-1.9,1.9c-0.1,0.1-0.1,0.4,0,0.5l3,3c0.1,0.1,0.4,0.1,0.5,0s0.1-0.4,0-0.5l-2.7-2.7l1.3-1.3L7,17.5
		C7,17.6,7,17.8,7.1,17.9C7.2,18,7.3,18,7.4,18c0.1,0,0.1,0,0.2-0.1C7.6,18,7.7,18,7.7,18c0.1,0,0.2,0,0.3-0.1
		c0.1-0.1,0.1-0.3,0.1-0.4c0.2-0.2,0.4-0.4,0.6-0.6c2.9,0.1,4.1-1.7,4.6-2.3h0.9c0.8,0,1.5-0.7,1.5-1.5v-0.4c0,0,0.1,0,0.1,0
		c0.2,0,0.4-0.1,0.6-0.2l0.8-0.9c0.2-0.2,0.3-0.5,0.2-0.8c0-0.3-0.2-0.6-0.4-0.8c0,0,0,0-0.1,0l0,0c-0.1,0-0.1-0.1-0.2-0.1l0.5-0.6
		C17.5,8.8,17.4,8.1,17,7.7z M14.8,10C14.8,10.1,14.9,10.1,14.8,10l0.1,0.1c0.1,0,0.1,0.1,0.2,0.1l-0.6,0.7
		c-0.1,0.1-0.2,0.3-0.2,0.6c0,0,0,0.1,0,0.1h-6C8.1,10.8,7.8,10,7.5,9.6C7.7,8.7,7.9,8.1,7.9,7C8,6.2,7.7,5.6,7.4,5.2
		C7.3,5,7.1,4.9,7,4.9V2.6h7.9v0.7l-0.5,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.3,0.1,0.7,0.4,1l0.1,0.1c0,0,0,0,0,0
		c0.1,0.1,0.3,0.2,0.4,0.3l-0.5,0.6c-0.3,0.4-0.2,1,0.3,1.4c0,0,0.1,0,0.1,0.1l0,0C15,7.8,15,7.9,15.1,7.9l-0.6,0.7
		C14.2,9,14.3,9.6,14.8,10z M15.7,7.3c0,0-0.2,0-0.4-0.2c0,0-0.1,0-0.1-0.1l0,0C15.1,6.9,15,6.8,15,6.8l0.8-0.9
		C15.9,5.8,16,5.8,16,5.8c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0.1l0,0c0.1,0.2,0.2,0.4,0,0.5L15.7,7.3z M16.1,3.3
		c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0,0l0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3l-0.9,0.8c0,0-0.2,0-0.5-0.2l-0.1-0.1
		c-0.2-0.2-0.2-0.4-0.2-0.4l0.9-0.9C15.9,3.4,16,3.3,16.1,3.3z M7.7,0.8h6.4c0.4,0,0.8,0.3,0.8,0.8v0.4H7V1.5C7,1.1,7.3,0.8,7.7,0.8
		z M8.5,16.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.2-0.5,0.4-0.7,0.7l-3.5-3.5c0.3-0.5,0.7-1.1,0.7-1.2c0.1-0.2,0.1-0.6,0.1-1.1
		c0-0.6,0-1.4,0.3-2C5.5,8.5,5.6,8,5.9,7.1C6,6.7,6.1,6.2,6.3,5.7c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0C7,5.9,7.2,6.4,7.2,6.9C7.1,8,7,8.6,6.7,9.5c0,0.1,0,0.2,0.1,0.3c0.5,0.7,1.2,2.2,0.9,4c-0.2,0-0.3,0.2-0.3,0.4
		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2,0,0.4,0.2,0.5c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.2
		c0.1-0.2,0.1-0.3,0.2-0.5h4C11.7,15.3,10.6,16.3,8.5,16.1z M14.1,13.9H8.4c0.1-0.5,0.1-1,0-1.5h6.4c0,0,0,0,0.1,0l0,0v0.7
		C14.9,13.6,14.6,13.9,14.1,13.9z M16.5,11.2L15.8,12c0,0-0.2,0-0.4-0.2c0,0-0.1,0-0.1-0.1l0,0c-0.1-0.1-0.2-0.2-0.2-0.3l0.7-0.9
		c0.1-0.2,0.4-0.2,0.6,0c0,0,0,0,0.1,0l0,0C16.6,10.8,16.6,11,16.5,11.2z M16.5,8.8l-0.7,0.8c-0.1,0.1-0.2,0-0.4-0.1
		c0,0-0.1,0-0.1-0.1l0,0c-0.2-0.2-0.2-0.3-0.2-0.3l0.8-0.9c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0.1l0,0
		C16.6,8.4,16.7,8.6,16.5,8.8z"
		/>
		<path
			d="M11.5,12.8h-1.1c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h1.1c0.2,0,0.4-0.2,0.4-0.4S11.7,12.8,11.5,12.8z"
		/>
		<path
			d="M2.6,14.4c-0.1,0.1-0.1,0.4,0,0.5L3,15.3c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1c0.1-0.1,0.1-0.4,0-0.5
		l-0.4-0.4C3,14.2,2.8,14.2,2.6,14.4z"
		/>
		<path
			d="M12.5,5.6V6h0.4V5.6c0.3,0,0.6-0.3,0.6-0.6V5c0-0.2-0.1-0.4-0.4-0.5l-0.6-0.2c-0.1,0-0.1-0.1-0.1-0.2V4
		c0-0.1,0.1-0.2,0.2-0.2h0.4C13,3.8,13,3.9,13,4v0.2h0.4V4c0-0.3-0.3-0.6-0.6-0.6V3.1h-0.4v0.3c-0.3,0-0.6,0.3-0.6,0.6v0.1
		c0,0.2,0.2,0.5,0.4,0.5l0.6,0.2C12.9,4.8,13,4.9,13,5V5c0,0.1-0.1,0.2-0.2,0.2h-0.4c-0.1,0-0.2-0.1-0.2-0.2V4.8h-0.4V5
		C11.9,5.3,12.2,5.6,12.5,5.6z"
		/>
		<path
			d="M11.6,6.8c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4c0,0.3,0.2,0.4,0.4,0.4C11.4,7.2,11.6,7,11.6,6.8z M11,6.8
		c0-0.1,0-0.2,0.2-0.2s0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.2,0.2C11.1,6.9,11,6.9,11,6.8z"
		/>
		<polygon
			points="12.4,9.2 12.7,9.2 12.7,7.9 13,7.9 13,8.1 12.9,8.1 12.9,8.4 13,8.4 13,8.6 12.9,8.6 12.9,8.9 13,8.9 
		13,9 12.9,9 12.9,9.3 13,9.3 13,9.6 13.3,9.6 13.3,7.6 12.4,7.6 	"
		/>
		<rect x="9" y="9.7" width="4.2" height="0.3" />
		<polygon points="8.9,6.8 9,7.1 11.2,6.2 13.3,7.1 13.4,6.8 11.2,5.8 	" />
		<rect x="9" y="7.2" width="4.2" height="0.3" />
		<path d="M10.6,9.2V8c0-0.2-0.1-0.3-0.3-0.3S9.9,7.8,9.9,8v1.2h0.3V8c0-0.1,0.1-0.1,0.1,0v1.2H10.6z" />
		<path d="M12,7.6c-0.2,0-0.3,0.1-0.3,0.3v1.2H12V8c0-0.1,0.1-0.1,0.1,0v1.2h0.3V8C12.4,7.8,12.2,7.6,12,7.6z" />
		<polygon
			points="9,9.6 9.3,9.6 9.3,9.3 9.4,9.3 9.4,9 9.3,9 9.3,8.9 9.4,8.9 9.4,8.6 9.3,8.6 9.3,8.4 9.4,8.4 9.4,8.1 
		9.3,8.1 9.3,7.9 9.6,7.9 9.6,9.2 9.8,9.2 9.8,7.6 9,7.6 	"
		/>
		<path d="M11.5,9.2V8c0-0.2-0.1-0.3-0.3-0.3S10.8,7.8,10.8,8v1.2h0.3V8c0-0.1,0.1-0.1,0.1,0v1.2H11.5z" />
		<rect x="9.5" y="9.3" width="3.3" height="0.3" />
	</g>
</template>




<script>
export default {
	name: 'MobileBanking'
};
</script>