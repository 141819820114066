import _cloneDeep from 'lodash.clonedeep';

export default {

    namespaced: true,
    state: {

        routeHistory: []

    },
    mutations: {
        
        resetState(state) { state.routeHistory = []; },
        addHistoryItem(state, { matched, ...route }) { state.routeHistory.push(_cloneDeep(route)); },
        removeLastRoute(state) { state.routeHistory.pop(); },
        replaceHistory(state, routes) { state.routeHistory = _cloneDeep(routes); }

    },
    actions: {

        forceNavigation({ commit }, incoming) {

            commit('resetState');
            commit('addHistoryItem', incoming);

            return false;

        },
        enforceRoute({ commit, dispatch, getters }, incoming) {

            const hasPrevious = !!getters.previous;

            if (hasPrevious && incoming.path === getters.previous.path) {

                if (incoming.meta.forceNavigation) { return dispatch('forceNavigation', incoming) };

                commit('removeLastRoute');

                return false;

            };

            if (incoming.meta.bypassEnforcer) {

                if (incoming.meta.forceNavigation) { return dispatch('forceNavigation', incoming) };
                if (!getters.current || incoming.path !== getters.current.path) { commit('addHistoryItem', incoming) };

                return false;

            };

            const hasCurrent = !!getters.current;
            const fromRoute = incoming.params.fromRoute;

            if (hasCurrent && incoming.path === getters.current.path) {

                return incoming.meta.forceNavigation ? dispatch('forceNavigation', incoming) : false;

            };

            const fromMatchesHistory = (hasCurrent && fromRoute === getters.current.path) || (hasPrevious && fromRoute === getters.previous.path);

            if (fromMatchesHistory) {

                if (incoming.meta.forceNavigation) { return dispatch('forceNavigation', incoming) };

                commit('addHistoryItem', incoming);

                return false;

            };

            return getters.redirectRoute;

        }

    },
    getters: {

        current: state => state.routeHistory[state.routeHistory.length - 1],
        previous: state => state.routeHistory[state.routeHistory.length - 2],
        redirectRoute: (state, getters) => getters.current || { name: 'Authentication' }

    }

};