export default {
	NONE: 0,
	APPROVED: 1,
	REVIEW: 2,
	DECLINED: 3,
	CANCELLED: 4,
	EXPIRED: 5,
	DUPLICATE: 6,
	COUNTER_OFFERED: 7,
	0: 'None',
	1: 'Approved',
	2: 'Review',
	3: 'Declined',
	4: 'Cancelled',
	5: 'Expired',
	6: 'Duplicate',
	7: 'Counter Offered'
};
