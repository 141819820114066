import Vue from 'vue';
import axios from 'axios';
import 'vue-toastification/dist/index.css';

import App from './App.vue';
import router from './router';
import store from './store';
import { constructUrl } from './utils';
import { version } from '../package.json';

Vue.prototype.$axios = axios;
Vue.prototype.$constructUrl = constructUrl;

Vue.config.productionTip = false;

new Vue({
	name: `Loan Center v${version}`,
	router,
	store,
	render: h => h(App)
}).$mount('#app');
