import { axios } from '@/utils';

const getMessage = async (applicationId, applicantToken) => {
    const {
        data: {
            welcomeMessage
        }
    } = await axios.get(`/api/Application/${applicationId}/Applicant/${applicantToken}/WelcomeMessage`);

    return welcomeMessage ;
};


export default {
    getMessage
};

export {
    getMessage
};