<template functional>
	<g>
		<path
			d="M15,14.12H30.7a.82.82,0,0,0,.82-.82V1.42A.82.82,0,0,1,32.93.84L53.7,21.61a.82.82,0,0,1,0,1.18L33.34,42a.82.82,0,0,1-1.38-.6V30.14a.82.82,0,0,0-.83-.82H15a.83.83,0,0,1-.82-.83V14.94A.82.82,0,0,1,15,14.12Z"
		/>
		<path
			d="M21.18.05V3.82H9.34S5,3.56,5,7.8V35.52s-.53,4.24,4.5,4.6H21.18v3.62H9.65S0,44,0,34.33V7.7S0,0,8.36,0,21.18.05,21.18.05Z"
		/>
	</g>
</template>





<script>
export default {
	name: 'LogoutIcon'
};
</script>