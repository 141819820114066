export default {
    NONE: 0,
    VERIFIED: 1,
    FAILED_VALIDATION: 2,
    SESSION_EXPIRED: 3,
    SESSION_PREVIOUSLY_COMPLETED: 4,
    0: 'None',
    1: 'Verified',
    2: 'FailedVerification',
    3: 'SessionExpired',
    4: 'SessionPreviouslyCompleted'
};