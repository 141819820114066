<template>
	<Portal to="secondary-modal-content">
		<p>
				{{ message }}
		</p>

		<section class="button-container">
			<StsButton
				fullwidth
				lowercase
				small
				type="plain"
				style="border: 0.25em solid var(--primary)"
				:title="cancelText"
				@click="$emit('close')"
			/>
		</section>
		<small>
				{{ secondaryMessage }}
		</small>
	</Portal>
</template>

<script>
	import { Portal } from 'portal-vue';
	import { mapActions } from 'vuex';

	import { StsButton, StsConfirm } from '@/components/SwitchThink';

	export default {
		name: 'DuplicateUploadModal',
		components: {
			Portal,
			StsButton,
			StsConfirm
		},
		props: {
			title: {
				type: String,
				required: true
			},
			message: {
				type: String,
				required: true
			},
			cancelText: {
				type: String,
				required: false,
				default: 'Cancel'
			},
			secondaryMessage:{
				type:String,
				required:false,
				default: ''
			},
			isSmall: {
				type: Boolean,
				required: false,
				default: false
			},
			preventClose: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		created() {
			this.triggerModal({
				isOpen: true,
				title: this.title,
				smallTitle: true,
				preventClose: this.preventClose
			});
		},
		beforeDestroy() {
			return this.triggerModal({ isOpen: false });
		},
		methods: {
			...mapActions('modal', { triggerModal: 'triggerSecondaryModal' })
		}
	};
</script>
<style lang="scss" scoped>
	.button-container {
		grid-template-columns: 1fr 1fr;
		margin: -1rem;
		margin: 2rem 1rem 1rem 1rem;
		column-gap: 1em;
	}
</style>
