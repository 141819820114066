<template>
	<div class="input-container">
		<header
			:class="[
				'input-header',
				{
					'is-small': isSmall,
					'sr-only': hideLabel
				}
			]"
		>
			<label :class="{ 'has-error': error }" :for="inputId" data-test-label>
				{{ label }}
				<span v-if="isOptional & !disabled" data-test-optional>(OPTIONAL)</span>
			</label>
		</header>

		<section class="input-wrapper">
			<input
				v-for="index in steps"
				:key="index"
				:ref="`step-${index}`"
				:class="{ 'is-small': isSmall, 'has-error': error }"
				:id="`${inputId}-${index}`"
				:name="`${label}-${index}`"
				:type="type"
				:placeholder="placeholder[index - 1]"
				:value="innerValue[index - 1]"
				:disabled="disabled"
				data-test-input
				:autocomplete="disableAutocomplete ? 'off' : 'on'"
				maxlength="1"
				@input="event => updateValue(event.target.value, index)"
			/>
		</section>

		<transition name="fade">
			<p class="has-error" v-if="error" data-test-error>
				{{ error }}
			</p>
		</transition>
	</div>
</template>




<script>
import { setStringIndex } from '@/utils';

export default {
	name: 'MultiStepInput',
	props: {
		value: {
			required: true
		},
		label: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		placeholder: {
			type: String,
			required: false
		},
		disabled: {
			type: Boolean,
			required: false
		},
		error: {
			type: String,
			required: false
		},
		isOptional: {
			type: Boolean,
			required: false
		},
		disableAutocomplete: {
			type: Boolean,
			required: false
		},
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		},
		id: {
			type: [String, Number],
			required: false
		},
		hideLabel: {
			type: Boolean,
			required: false
		},
		steps: {
			type: Number,
			required: true
		}
	},
	computed: {
		inputId() {
			return this.id || `${this.label.replace(/\s/g, '')}-${this._uid}`;
		},
		innerValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	},
	methods: {
		moveToNextInput(nextIndex) {
			this.$refs['step-' + nextIndex][0].focus();
		},
		updateValue(val, index) {
			const nextIndex = val ? index + 1 : index - 1;

			if (index) {
				this.innerValue = setStringIndex(this.innerValue, index, val);
			}
			if (nextIndex >= 1 && nextIndex <= this.steps) {
				this.moveToNextInput(nextIndex);
			}
		}
	}
};
</script>




<style lang="scss" scoped>
.input-container {
	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	.has-error {
		color: var(--danger);
	}

	.input-header {
		display: flex;
		gap: 0.5rem;
		align-items: baseline;

		label {
			display: block;
			color: var(--primary);
			font-size: 0.8rem;
			margin-bottom: 0.5rem;
			font-weight: 400;
			text-transform: uppercase;
		}
	}
	.input-wrapper {
		padding: 0.5rem 1rem;
		border: 1px solid rgba(245, 154, 35, 1);
		border-radius: 11px;
		display: flex;
		justify-content: space-between;

		input {
			box-sizing: border-box;
			width: 100%;
			border: none;
			border-bottom: 1.5px solid rgb(215, 215, 215);
			background-color: transparent;
			font-size: 1.25rem;
			padding: 0.25rem;
			margin: 0 0.25rem;
			text-align: center;

			&::placeholder {
				color: var(--lightGrey);
				font-style: oblique;
			}

			&.has-error {
				border-bottom: 1.5px solid var(--danger);
			}

			&:disabled {
				color: var(--lightGrey);
				cursor: not-allowed;
			}
		}
	}

	p {
		font-size: 0.85rem;
		margin-top: 0.25rem;
	}

	.is-small {
		width: auto;
		min-width: 12rem;
	}
}
</style>