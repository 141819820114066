import { axios } from "@/utils";

/**
 * @typedef {object} autoLoanCollateral Auto Collateral
 * @property {number} year Vehicle Year
 * @property {string} make Vehicle Make
 * @property {string} model Vehicle Model
 * @property {string} bodyStyle Vehicle Trim
 * @property {number} mileage Vehicle Mileage
 * @property {string} vin VIN (optional)
 */

/**
 * @typedef {object} autoLoanApplicationUpdateViewModelRequest Auto Loan Collateral Update Request
 * @property {number} applicationId Application ID
 * @property {number} taskId Task ID
 * @property {autoLoanCollateral} collateral Collateral
 * @property {number} collateralValuationType Collateral Valuation Type
 */

/**
 * @param {autoLoanApplicationUpdateViewModelRequest} request The request to create collateral for an auto loan.
 * @returns {Promise<boolean>} True if the collateral was created successfully.
 */
const createAutoCollateral = async (request) => {
    const { created } = await axios.put("/api/AutoCollateral", request);

    return created;
};

/**
 * @typedef {object} autoOptionViewModel List of options for a dropdown
 * @property {number} id Primary Key
 * @property {string} displayText Option text
 * @property {boolean} isPrequalification
 * @property {boolean} requiresCollateral
 * @property {boolean} requiresCollateralCondition
 * @property {boolean} requiresLienHolder
 * @property {boolean} requiresManualEntry
 * @property {boolean} requiresEmployer
 * @property {boolean} requiresMileage
 */

/**
 * @returns {Promise<autoLoanApplicationCollateralGetViewModelResponse>} Containing options required for form to load.
 */
const getAutoLoanApplicationCollateral = async (applicationId) => {
    const { data } = await axios.get(`/api/AutoCollateral/${applicationId}/`);

    return data;
};

/**
 * @typedef {object} boatLoanCollateral Boat Collateral
 * @property {number} boatType Watercraft Type
 * @property {number} year Year
 * @property {string} make Make
 * @property {string} model Vehicle Model
 * @property {number} engineTypeValue Engine Type
 * @property {string} hullId Hull ID (optional)
 * @property {number} trailerYear Trailer Year (optional)
 * @property {string} trailervin Trailer VIN (optional)
 * @property {number} trailerLength Trailer Length (optional)
 */

/**
 * @typedef {object} boatLoanApplicationUpdateViewModelRequest Boat Loan Collateral Update Request
 * @property {number} applicationId Application ID
 * @property {number} taskId Task ID
 * @property {boatLoanCollateral} collateral Collateral
 */

/**
 * @param {boatLoanApplicationUpdateViewModelRequest} request The request to create collateral for a boat loan.
 * @returns {Promise<boolean>} True if the collateral was created successfully.
 */
const createBoatCollateral = async (request) => {
    const { created } = await axios.put("/api/BoatCollateral", request);

    return created;
};

/**
 * @typedef {object} homeLoanCollateral Auto Collateral
 * @property {string} street Address Line 1
 * @property {string} city City
 * @property {string} state State
 * @property {string} postalCode Zip Code
 */

/**
 * @typedef {object} homeLoanApplicationUpdateViewModelRequest Home Loan Collateral Update Request
 * @property {number} applicationId Application ID
 * @property {number} taskId Task ID
 * @property {homeLoanCollateral} collateral Collateral
 */

/**
 * @param {homeLoanApplicationUpdateViewModelRequest} request The request to create collateral for a home loan.
 * @returns {Promise<boolean>} True if the collateral was created successfully.
 */
const createHomeCollateral = async (request) => {
    const { created } = await axios.put("/api/HomeCollateral", request);

    return created;
};

/**
 * @typedef {object} motorcycleLoanCollateral Motorcycle Collateral
 * @property {number} year Year
 * @property {string} make Make
 * @property {string} model Model
 * @property {number} mileage Mileage
 * @property {string} vin VIN (optional)
 */

/**
 * @typedef {object} motorcycleLoanApplicationUpdateViewModelRequest Motorcycle Loan Collateral Update Request
 * @property {number} applicationId Application ID
 * @property {number} taskId Task ID
 * @property {motorcycleLoanCollateral} collateral Collateral
 */

/**
 * @param {motorcycleLoanApplicationUpdateViewModelRequest} request The request to create collateral for a motorcycle loan.
 * @returns {Promise<boolean>} True if the collateral was created successfully.
 */
const createMotorcycleCollateral = async (request) => {
    const { created } = await axios.put("/api/MotorcycleCollateral", request);

    return created;
};
/**
 * @typedef {object} motorcycleOptionViewModel List of options for a dropdown
 * @property {number} id Primary Key
 * @property {string} displayText Option text
 * @property {boolean} isPrequalification
 * @property {boolean} requiresCollateral
 * @property {boolean} requiresCollateralCondition
 * @property {boolean} requiresLienHolder
 * @property {boolean} requiresManualEntry
 * @property {boolean} requiresEmployer
 * @property {boolean} requiresMileage
 */

/**
 * @returns {Promise<motorcycleLoanApplicationCollateralGetViewModelResponse>} Containing options required for form to load.
 */

const getMotorcycleLoanApplicationCollateral = async (applicationId) => {
    const { data } = await axios.get(
        `/api/MotorcycleCollateral/${applicationId}/`
    );

    return data;
};

/**
 * @typedef {object} rvLoanCollateral RV Collateral
 * @property {?number} year Year
 * @property {?string} make Make
 * @property {?string} model Model
 * @property {?string} vin VIN (optional)
 * @property {?number} mileage Mileage (optional)
 * @property {?number} vehicleType Camping Trailer [0], Motor Home [1], Travel Trailer/5th Wheel [2]
 * @property {?number} fuelType RV Fuel Type
 */

/**
 * @typedef {object} rvLoanApplicationUpdateViewModelRequest RV Loan Collateral Update Request
 * @property {number} applicationId Application ID
 * @property {number} taskId Task ID
 * @property {rvLoanCollateral} collateral Collateral
 */

/**
 * @typedef {object} rvOptionViewModel List of options for a dropdown
 * @property {number} id Primary Key
 * @property {string} displayText Option text
 * @property {boolean} isPrequalification
 * @property {boolean} requiresCollateral
 * @property {boolean} requiresCollateralCondition
 * @property {boolean} requiresLienHolder
 * @property {boolean} requiresManualEntry
 * @property {boolean} requiresEmployer
 * @property {boolean} requiresMileage
 */

/**
 * @typedef {object} rvLoanDetailConfigurationGetViewModelResponse Boat Loan Configuration Get Response
 * @property {rvOptionViewModel[]} rvTypes RV Type Options
 */

/**
 * @returns {Promise<rvLoanDetailConfigurationGetViewModelResponse>} Containing options required for form to load.
 */
const getRvCollateralOptions = async () => {
    const { data } = await axios.get("/api/RVCollateral/Configuration");

    return data;
};

/**
 * @typedef {object} rvCollateralViewModel
 * @property {string} make RV Make
 * @property {string} model RV Model
 * @property {string} vin RV VIN
 * @property {number} year RV Year
 * @property {number} vehicleType RV Type
 * @property {?number} mileage RV Mileage (Optional)
 * @property {?number} fuelType RV Fuel Type (Optional)
 */

/**
 * @typedef {object} rvLoanCollateralGetViewModelResponse
 * @property {rvCollateralViewModel} rvCollateral RV Collateral ViewModel
 */

/**
 * @returns {Promise<rvLoanCollateralGetViewModelResponse>} Containing options required for form to load.
 */

const getRVLoanApplicationCollateral = async (applicationId) => {
    const { data } = await axios.get(`/api/RVCollateral/${applicationId}/`);

    return data;
};

/**
 * @param {rvLoanApplicationUpdateViewModelRequest} request The request to create collateral for an RV loan.
 * @returns {Promise<boolean>} True if the collateral was created successfully.
 */
const createRVCollateral = async (request) => {
    const { created } = await axios.put("/api/RVCollateral", request);

    return created;
};

/**
 * @typedef {object} optionViewModel List of options for a dropdown
 * @property {number} id Primary Key
 * @property {string} displayText Option text
 * @property {boolean} isPrequalification
 * @property {boolean} requiresCollateral
 * @property {boolean} requiresCollateralCondition
 * @property {boolean} requiresLienHolder
 * @property {boolean} requiresManualEntry
 * @property {boolean} requiresEmployer
 */

/**
 * @typedef {object} boatLoanDetailConfigurationGetViewModelResponse Boat Loan Configuration Get Response
 * @property {optionViewModel[]} vehicleTypes Boat Type Options
 * @property {optionViewModel[]} engineTypes Engine Type Options
 */

/**
 * @returns {Promise<boatLoanDetailConfigurationGetViewModelResponse>} Containing options required for form to load.
 */
const getBoatCollateralOptions = async () => {
    const { data } = await axios.get("/api/BoatCollateral/Configuration");

    return data;
};

/**
 * @typedef {object} boatOptionViewModel List of options for a dropdown
 * @property {number} id Primary Key
 * @property {string} displayText Option text
 * @property {boolean} isPrequalification
 * @property {boolean} requiresCollateral
 * @property {boolean} requiresCollateralCondition
 * @property {boolean} requiresLienHolder
 * @property {boolean} requiresManualEntry
 * @property {boolean} requiresEmployer
 * @property {boolean} requiresMileage
 */

/**
 * @returns {Promise<boatLoanApplicationCollateralGetViewModelResponse>} Containing options required for form to load.
 */

const getBoatLoanApplicationCollateral = async (applicationId) => {
    const { data } = await axios.get(`/api/BoatCollateral/${applicationId}/`);

    return data;
};

/**
 * @typedef {object} motorcycleLoanDetailConfigurationGetViewModelResponse Motorcycle Collateral Configuration Get Response
 * @property {optionViewModel[]} motorcycleTypes Motorcycle Type Options
 * @property {optionViewModel[]} fuelTypes Fuel Type Options
 */

/**
 * @returns {Promise<motorcycleCollateralConfigurationGetViewModelResponse>} Containing options required for form to load.
 */
const getMotorcycleCollateralOptions = async () => {
    const { data } = await axios.get("/api/Configuration/MotorcycleCollateral");

    return data;
};

const getCollateralDescription = async (applicationId) => {
    const { data } = await axios.get(
        `/api/Application/${applicationId}/Collateral/Description`
    );

    return data.description;
};

export default {
    createAutoCollateral,
    getAutoLoanApplicationCollateral,
    createBoatCollateral,
    createHomeCollateral,
    createMotorcycleCollateral,
    getMotorcycleLoanApplicationCollateral,
    getMotorcycleCollateralOptions,
    createRVCollateral,
    getBoatCollateralOptions,
    getBoatLoanApplicationCollateral,
    getRVLoanApplicationCollateral,
    getCollateralDescription,
    getRvCollateralOptions,
};
