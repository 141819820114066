import { getCleanCaptchaImplementationDetails } from '@/utils'

export default (config) => {

    let captchaConfig = getCleanCaptchaImplementationDetails(config.implementation);

    if (config) {

        captchaConfig.siteKey = config.siteKey;
        captchaConfig.enabled = config.enabled;

    }

    return captchaConfig;
};