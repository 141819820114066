import { arrayMutations, objectMutations } from '../shared';

export default {

    namespaced: true,
    state: {
        forceMFAAuthentication: false,
        forceMFAVerification: false

    },
    mutations: {

        ...arrayMutations,
        ...objectMutations,
        resetState(state) {
            state.forceMFAAuthentication = false;
            state.forceMFAVerification = false;

        }

    },
    actions: {


    },
    getters: {


    }

};
