<template>
    <section class="status-container">
        <section class="status first">
            <section :class="['content', { active: getPositionActive(1) }]">
                <svg :class="[{ active: getPositionActive(1) }]" width="35px" height="35px">
                    <polygon class="c" points="22.65 18.6 17.09 13.04 19.87 10.26 22.65 13.04 30.3 5.39 33.08 8.17 22.65 18.6 22.65 18.6" />
                    <path class="b" d="M30.93,14.29v11.49H3.94V9.59c0-.75,.6-1.35,1.35-1.35h11.79" />
                    <path class="b" d="M32.95,25.78H1.92v1.35c0,1.49,1.21,2.7,2.7,2.7H30.25c1.49,0,2.7-1.21,2.7-2.7v-1.35h0Z" />
                    <line class="b" x1="16.09" y1="27.81" x2="19.46" y2="27.81" />
                    <line class="b" x1="6.31" y1="16.41" x2="14.59" y2="16.41" />
                    <line class="b" x1="6.31" y1="19.02" x2="14.59" y2="19.02" />
                    <line class="b" x1="6.31" y1="21.63" x2="11.72" y2="21.63" />
                </svg>
                <p>{{ getPositionValue(1) }}</p>
            </section>
            <span class="triangle">
                <span :class="['inner', { active: getPositionActive(1) }]"></span>
            </span>
        </section>
        <section class="status">
            <section :class="['content', { active: getPositionActive(2) }]">
                <svg :class="[{ active: getPositionActive(2) }]" width="35px" height="35px">
                    <path class="b" d="M24.88,17.6v-1.55s.86-.29,1.55-.52v-4.65l-1.55-.52v-1.55h4.13v6.71c.69,.23,1.55,.52,1.55,.52v1.55h-5.68Z" />
                    <path class="b" d="M25.92,5.73c0,.86,.69,1.55,1.55,1.55s1.55-.69,1.55-1.55-.69-1.55-1.55-1.55-1.55,.69-1.55,1.55h0Z" />
                    <g>
                        <line class="c" x1="19.52" y1="8.83" x2="19.52" y2="15.52" />
                        <line class="c" x1="22.87" y1="12.17" x2="16.18" y2="12.17" />
                    </g>
                    <path class="b" d="M30.99,20.19v6.18H4.01V10.18c0-.75,.6-1.35,1.35-1.35H15.17" />
                    <path class="b" d="M33.02,26.37H1.98v1.35c0,1.49,1.21,2.7,2.7,2.7H30.32c1.49,0,2.7-1.21,2.7-2.7v-1.35h0Z" />
                    <line class="b" x1="16.15" y1="28.4" x2="19.52" y2="28.4" />
                    <line class="b" x1="6.37" y1="16.99" x2="14.65" y2="16.99" />
                    <line class="b" x1="6.37" y1="19.61" x2="14.65" y2="19.61" />
                    <line class="b" x1="6.37" y1="22.22" x2="11.78" y2="22.22" />
                </svg>
                <p>{{ getPositionValue(2) }}</p>
            </section>
            <span class="triangle">
                <span :class="['inner', { active: getPositionActive(2) }]"></span>
            </span>
        </section>
        <section class="status">
            <section :class="['content', { active: getPositionActive(3) }]">
                <svg :class="[{ active: getPositionActive(3) }]" width="35px" height="35px">
                    <path class="c" d="M30.56,10.05l-.89-2.14-1.3,.43c-.36-.51-.8-.94-1.29-1.29l.43-1.3-2.14-.89-.61,1.23c-.59-.1-1.21-.1-1.82,0l-.61-1.23-2.14,.89,.43,1.3c-.51,.36-.94,.8-1.29,1.29l-1.3-.43-.89,2.14,1.23,.61c-.1,.59-.1,1.21,0,1.82l-1.23,.61,.89,2.14,1.3-.43c.36,.51,.8,.94,1.29,1.29l-.43,1.3,2.14,.89,.61-1.23c.59,.1,1.21,.1,1.82,0l.61,1.23,2.14-.89-.43-1.3c.51-.36,.94-.8,1.29-1.29l1.3,.43,.89-2.14-1.23-.61c.1-.59,.1-1.21,0-1.82l1.23-.61h0Z" />
                    <path class="c" d="M26.15,11.66c0,1.22-.99,2.21-2.21,2.21s-2.21-.99-2.21-2.21,.99-2.21,2.21-2.21,2.21,.99,2.21,2.21h0Z" />
                    <path class="b" d="M33.02,26.3H1.98v1.35c0,1.49,1.21,2.7,2.7,2.7H30.32c1.49,0,2.7-1.21,2.7-2.7v-1.35h0Z" />
                    <line class="b" x1="16.15" y1="28.32" x2="19.52" y2="28.32" />
                    <line class="b" x1="6.37" y1="16.92" x2="14.65" y2="16.92" />
                    <line class="b" x1="6.37" y1="19.53" x2="14.65" y2="19.53" />
                    <line class="b" x1="6.37" y1="22.15" x2="11.78" y2="22.15" />
                    <path class="b" d="M30.99,16.18v10.12H4.01V10.11c0-.75,.6-1.35,1.35-1.35H15.17" />
                </svg>
                <p>{{ getPositionValue(3) }}</p>
            </section>
            <span class="triangle">
                <span :class="['inner', { active: getPositionActive(3) }]"></span>
            </span>
        </section>
        <section class="status last">
            <section :class="['content', { active: getPositionActive(4) }]">
                <svg :class="[{ active: getPositionActive(4) }]"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     xmlns="http://www.w3.org/2000/svg"
                     version="1.1"
                     width="35px"
                     height="35px">
                    <g>
                        <path class="d" d="M20.42,13.66c1.51-1.07,5.82-3.11,6.79-2.13l-2.14-5.82c-2.7,0-5.28,1.07-6.79,2.13" />
                        <path class="d" d="M25.77,13.24c.39,1.07,5.4-.87,6.91-1.93l-2.14-5.82c-.92,.65-2.23,1.3-3.72,1.71" />
                        <path class="d" d="M27.22,11.52c.29,.47,.17,.72-.09,.9" />
                        <line class="d" x1="17.84" y1="6.64" x2="23.13" y2="21" />
                    </g>
                    <rect class="b" x="22.19" y="6.93" width="2.03" height="2.03" transform="translate(-1.31 8.51) rotate(-20.22)" />
                    <rect class="b" x="20.98" y="9.52" width="2.03" height="2.03" transform="translate(-2.28 8.25) rotate(-20.22)" />
                    <rect class="b" x="26.38" y="8.27" width="2.01" height="2.01" transform="translate(-1.52 10.04) rotate(-20.22)" />
                    <rect class="b" x="28.89" y="9.35" width="2.01" height="2.01" transform="translate(-1.74 10.97) rotate(-20.22)" />
                    <path class="c" d="M30.99,15.56v10.12H4.01V9.49c0-.75,.6-1.35,1.35-1.35H15.17" />
                    <path class="c" d="M33.02,25.68H1.98v1.35c0,1.49,1.21,2.7,2.7,2.7H30.32c1.49,0,2.7-1.21,2.7-2.7v-1.35h0Z" />
                    <line class="c" x1="16.15" y1="27.7" x2="19.52" y2="27.7" />
                    <line class="c" x1="6.37" y1="16.3" x2="14.65" y2="16.3" />
                    <line class="c" x1="6.37" y1="18.91" x2="14.65" y2="18.91" />
                    <line class="c" x1="6.37" y1="21.53" x2="11.78" y2="21.53" />
                </svg>
                <p>{{ getPositionValue(4) }}</p>
            </section>
            <span class="triangle">
                <span :class="['inner', { active: getPositionActive(4) }]"></span>
            </span>
        </section>
    </section>
</template>





<script>
    export default {
        name: 'LoanStatus',
        props: {
            model: {
                type: Array,
                validator: val => val && (val.length >= 1 || val.length <= 4),
                required: false,
                default: function () {
                    return [
                        {
                            position: 1,
                            value: 'Approved',
                            icon: '',
                            active: false
                        },
                        {
                            position: 2,
                            value: 'Info Needed',
                            icon: '',
                            active: false
                        },
                        {
                            position: 3,
                            value: 'Processing',
                            icon: '',
                            active: false
                        },
                        {
                            position: 4,
                            value: 'Finalizing',
                            icon: '',
                            active: false
                        }
                    ];
                }
            }
        },
        methods: {
            getConfigByPosition(position) {
                return this.model.find(x => x.position === position);
            },
            getPositionValue(position) {
                const config = this.getConfigByPosition(position);
                return config.value;
            },
            getPositionActive(position) {
                const config = this.getConfigByPosition(position);
                return config.active;
            }
        }
    };
</script>





<style lang="scss" scoped>
    @media only screen and (min-width: 641px) {
        .status-container {
            .status {
                &:not(.first) {
                    margin-left: -40px;

                    .content {
                        padding: 0.5rem 1rem 0.5rem calc(1rem + 40px);

                        svg {
                            .b {
                                stroke-miterlimit: 10;
                            }

                            .c {
                                stroke-linejoin: round;
                            }

                            .b, .c, .d {
                                fill: none;
                                stroke: var(--disabled-color);
                            }


                            .d {
                                stroke-linejoin: round;
                            }
                        }

                        svg.active {
                            .b, .c, .d {
                                stroke: var(--white);
                            }
                        }
                    }
                }

                p {
                    font-size: 0.59rem;
                }

                .triangle {
                    position: relative;
                    width: 0;
                    height: 0;
                    border-top: 40px solid transparent;
                    border-bottom: 40px solid transparent;
                    border-left: 40px solid var(--disabled);

                    .inner {
                        position: absolute;
                        top: -38px;
                        bottom: 0;
                        left: -40px;
                        width: 0;
                        height: 0;
                        border-top: 38px solid transparent;
                        border-bottom: 38px solid transparent;
                        border-left: 38px solid var(--inactive-bg);

                        &.active {
                            border-left: 38px solid var(--tertiary);
                        }
                    }
                }
            }
        }
    }

    .status-container {
        --inactive-bg: #f3f5fa;
        --disabled-color: #9fafc0;
        display: flex;
        justify-content: space-evenly;

        .status {
            flex: 1;
            display: flex;
            justify-content: center;

            &.first .content {
                border-left: 2px solid var(--disabled);
            }

            &.last {
                .content.active {
                    background-color: var(--success);
                }

                .triangle .inner.active {
                    border-left-color: var(--success);
                }
            }

            &:not(.first) {
                margin-left: -20px;

                .content {
                    padding: 0.5rem 0 0.5rem 20px;
                }
            }

            .content {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 0.5rem 1rem;
                background-color: var(--inactive-bg);
                border-top: 2px solid var(--disabled);
                border-bottom: 2px solid var(--disabled);

                &.active {
                    background-color: var(--tertiary);

                    p {
                        color: var(--white);
                    }
                }

                svg {
                    .b {
                        stroke-miterlimit: 10;
                    }

                    .c {
                        stroke-linejoin: round;
                    }

                    .b, .c, .d {
                        fill: none;
                        stroke: var(--disabled-color);
                    }

                    .d {
                        stroke-linejoin: round;
                    }
                }

                svg.active {
                    .b, .c, .d {
                        stroke: var(--white);
                    }
                }
            }

            p {
                margin: 0;
                text-align: center;
                font-weight: 400;
                font-size: 0.59rem;
                line-height: 1.2;
                color: var(--disabled-color);
            }

            .triangle {
                position: relative;
                width: 0;
                height: 0;
                border-top: 40px solid transparent;
                border-bottom: 40px solid transparent;
                border-left: 20px solid var(--disabled);

                .inner {
                    position: absolute;
                    top: -38px;
                    bottom: 0;
                    left: -20px;
                    width: 0;
                    height: 0;
                    border-top: 38px solid transparent;
                    border-bottom: 38px solid transparent;
                    border-left: 18px solid var(--inactive-bg);

                    &.active {
                        border-left: 18px solid var(--tertiary);
                    }
                }
            }
        }
    }
</style>