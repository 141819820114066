import { axios } from '@/utils';

const getDocumentsByApplication = async applicationId => {
	const {
		data: { documents }
	} = await axios.get(`/api/Application/${applicationId}/Documents`);

	return documents;
};

const syncDocumentsByApplication = async applicationId => {
	const {
		data: { hasDocuments }
	} = await axios.post('/api/Documents/Sync', { applicationId });

	return { hasDocuments };
};

const uploadDocuments = async (applicationId, stipulationId, applicantToken, data) => {

    let endpoint = `/api/Application/${applicationId}/Document`;

    if (stipulationId) {
        endpoint = endpoint + `/${stipulationId}`;
		
    }
	if(applicantToken){
		endpoint = endpoint + `/${applicantToken}`;
	}
    const {
		data: { posted, documentUploadResultType : resultType }
	} = await axios.put(endpoint, data);
	return { resultType, posted };
};

const uploadTaskDocuments = async (applicationId, taskId, data) => {

    let endpoint = `/api/Application/${applicationId}/Document`;

    if (taskId) {
        endpoint = endpoint + `/${taskId}`;
		
    }
    const {
		data: { posted, documentUploadResultType : resultType }
	} = await axios.put(endpoint, data);
	return { resultType, posted };
};

export default {
	getDocumentsByApplication,
	syncDocumentsByApplication,
	uploadDocuments,
	uploadTaskDocuments
};

export { getDocumentsByApplication, syncDocumentsByApplication, uploadDocuments, uploadTaskDocuments };
