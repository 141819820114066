import { CaptchaAction } from '@/constants';
import { axios } from '@/utils';

const handleAuthResult = ({
    applicantId = 0,
    applicantToken = 0,
    authenticationResult = 0,
    captchaAction = CaptchaAction.NONE,
    isVerified = false,
    isEligibleToUse = false,
    requiresMFA = true,
    authenticated = false,
    sessionToken = ''
}) => {
    switch (authenticationResult) {
        case 0:
            if (captchaAction === CaptchaAction.NONE) {
                throw 'There was an error verifying login credentials.';
            }
            break;
        case 1:
            // Success, let's return the applicant info
            break;
        case 2:
        case 3:
            throw 'The provided user name and password combination is incorrect.';
        case 4:
            throw 'The account provided is locked.';
        case 5:
            throw 'The account provided requires a password change.';
        default:
            if (captchaAction === CaptchaAction.NONE) {
                throw 'There was an error verifying login credentials.';
            }
    }

    return {
        applicantId,
        applicantToken,
        authenticationResult,
        captchaAction,
        isVerified,
        isEligibleToUse,
        requiresMFA,
        authenticated,
        sessionToken
    };
};

const authenticateApplicant = async request => {
    try {
        const { data } = await axios.post('/api/Authenticate', {
            ...request,
            loadUrl: window.location.href
        });

        return handleAuthResult(data);
    } catch (error) {
        throw error;
    }
};

const authenticateSSO = async request => {
    try {

        const { data } = await axios.post('/api/SSO', {
            ...request,
            loadUrl: window.location.href
        });

        return data;
    } catch (error) {

        throw error;

    }
};

const getApplicant = async applicantToken => {
    try {
        const {
            data: { applicant }
        } = await axios.get(`/api/Applicant/${applicantToken}`);

        return {
            ...applicant
        };
    } catch (error) {
        throw error;
    }
};

const verifyApplicant = async request => {
    try {
        const { data } = await axios.post('/api/Verify', {
            ...request,
            loadUrl: window.location.href
        });

        return data;
    } catch (error) {
        throw error;
    }
};

const loadApplicant = async request => {
    try {
        const {
            data: { applicantToken, hasLinkExpired, sessionToken }
        } = await axios.post('/api/Applicant/Load', request);

        return { applicantToken, hasLinkExpired, sessionToken };
    } catch (error) {
        throw error;
    }
};

const updateApplicant = async ({ applicationId, applicant }) => {
    try {
        const {
            data: { updated }
        } = await axios.post('/api/Applicant/Update', {
            applicationId,
            applicant
        });

        if (!updated) {
            throw 'There was an error updating the Co-Applicant record';
        }

        return applicant;
    } catch (error) {
        throw error;
    }
};

const logOut = async () => {
    try {

        await axios.put('/api/LogOut', {});

        return true;

    } catch (error) {
        throw error;
    }
};

export default {
    authenticateApplicant,
    getApplicant,
    verifyApplicant,
    updateApplicant,
    loadApplicant,
    authenticateSSO,
    logOut
};

export {
    authenticateApplicant,
    getApplicant,
    verifyApplicant,
    updateApplicant,
    loadApplicant,
    authenticateSSO,
    logOut
};
