<template>
	<ValidationProvider :rules="validationRules" :name="name || label" v-slot="{ errors }" slim>
		<StsSelect
			v-model="innerValue"
			:label="label"
			:options="options"
			:placeholder="placeholder"
			:placeholderValue="placeholderValue"
			:disabled="disabled"
			:error="errors[0]"
			:isOptional="isOptional"
			:disableAutocomplete="disableAutocomplete"
			:isSmall="isSmall"
			:enablePlaceholder="enablePlaceholder"
			:id="id"
		/>
	</ValidationProvider>
</template>


<script>
import { ValidationProvider } from 'vee-validate';

import { StsSelect } from '@/components/SwitchThink/form';

export default {
	name: 'VSelect',
	components: {
		ValidationProvider,
		StsSelect
	},
	props: {
		value: {
			required: true
		},
		label: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: false
		},
		placeholder: {
			type: String,
			required: false
		},
		placeholderValue: {
			required: false,
			default: null
		},
		options: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		},
		validationRules: {
			type: [String, Object],
			required: false
		},
		isOptional: {
			type: Boolean,
			required: false
		},
		disableAutocomplete: {
			type: Boolean,
			required: false
		},
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		},
		enablePlaceholder: {
			type: Boolean,
			required: false
		},
		id: {
			type: [String, Number],
			required: false
		}
	},
	computed: {
		innerValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
};
</script>