<template functional>

    <g>
        <path style="fill: #2391EC;" d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9
            c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
        <path style="fill: #203E77;" d="M11.3,14.9h-0.7V14l0.1-0.1l-0.1-0.1v-0.6l0.1-0.1l-0.1-0.1v-0.3C10.2,12.4,10,12,10,11.6c0-0.6,0.4-1.1,1-1.2
            v0l0,0V10c-0.1,0-0.2,0-0.2,0c-0.5,0-0.8-0.3-1.1-0.5C9.6,9.5,9.5,9.4,9.5,9.4C9.4,9.3,9.2,9.3,9,9.2C8.7,9.1,8.4,9,8.2,8.8
            c0,0-0.1,0-0.1-0.1C7.8,8.6,7.5,8.4,7.3,8.1C7.2,8.1,7,8,6.9,8v0.4H5.2V4.9h1.7v0.2h1.7c0.5,0,1.6,0.3,2.5,0.6c0.1,0,0.1,0,0.2,0.1
            c0.4,0.1,1.5,1.4,1.5,1.6c0.1,0.2,0.1,0.5-0.2,1.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.3-0.1,0.8-0.6,0.8
            c-0.1,0-0.3-0.1-0.4-0.2c0,0,0,0,0,0v0.4c0.4,0.1,0.8,0.3,0.9,0.7c0.1,0.3,0.1,0.6,0,1c-0.1,0.3-0.3,0.5-0.5,0.6v1.7L11.3,14.9z
            M11,14.5h0.1l0.3-0.3v-1.8l0.1-0.1c0.2-0.1,0.4-0.3,0.4-0.5c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.3-0.4-0.5-0.5v0.6H11v-0.6
            c-0.4,0.1-0.6,0.4-0.6,0.8c0,0.3,0.2,0.6,0.5,0.8l0.1,0.1v0.3l0.3,0.3L11,13.3v0.2l0.3,0.3L11,14.1V14.5z M11.6,9.3
            c0.1,0.3,0.2,0.4,0.3,0.4c0.1,0,0.2-0.1,0.2-0.5c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.2c0.1-0.3,0.3-1,0.2-1.2
            c-0.1-0.2-1.1-1.3-1.3-1.4c0,0-0.1,0-0.2-0.1C10.4,6,9.1,5.5,8.5,5.5H6.9v2.1c0.3,0,0.6,0.1,0.6,0.1l0.1,0l0,0.1
            C7.7,8,8.1,8.3,8.3,8.4c0.1,0,0.1,0.1,0.1,0.1c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.5,0.1,0.6,0.3c0.1,0.1,0.1,0.1,0.2,0.2
            c0.3,0.2,0.5,0.4,0.9,0.4c0.1,0,0.3,0,0.3-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.3-0.4-0.4l0,0c0,0-0.1,0-0.1-0.1
            C10.3,8.6,10,8.5,9.9,8.1C9.8,7.9,9.7,7.7,9.7,7.5l0,0c0.1-0.3,0.4-0.5,0.7-0.4c0.2,0,0.5,0.3,1,0.8c0,0,0.1,0.1,0.1,0.1L11.7,8
            l-0.1,0.1c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.3,0,0.4C11.6,9.2,11.6,9.2,11.6,9.3C11.6,9.2,11.6,9.3,11.6,9.3z M10.9,8.5
            c0.1,0,0.1,0.1,0.2,0.1c0-0.2,0.1-0.3,0.1-0.5c0,0,0,0,0,0c-0.5-0.4-0.7-0.7-0.8-0.7c-0.1,0-0.2,0-0.3,0.1c0,0.1,0,0.3,0.1,0.4
            c0.1,0.2,0.3,0.3,0.6,0.5C10.8,8.4,10.9,8.4,10.9,8.5z M5.6,8h0.8V5.3H5.6V8z M12.4,7.5L12.4,7.5L12.4,7.5z M8.4,14.2H7.7l-0.5-0.5
            l0.6-0.6l0.3-0.1v-0.2l0.7-0.7c-0.1-0.4,0-0.9,0.3-1.2c0.2-0.2,0.5-0.4,0.9-0.4l0,0.4c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0.2
            c-0.2,0.2-0.3,0.6-0.2,0.9l0,0.1L8.5,13v0.4L8,13.5l-0.2,0.2l0.1,0.1h0.4l1.3-1.3l0.1,0.1c0.2,0.1,0.5,0.1,0.7,0l0.2,0.4
            c-0.3,0.1-0.6,0.1-0.9,0L8.4,14.2z"/>
    </g>

</template>





<script>

    export default {

        name: 'Keys'

    }

</script>