import { axios } from '@/utils';

/**
 * @typedef {object} signingCreateViewModelRequest
 * @property {number} taskId Task ID
 */

/**
 * @param {number} taskId Task ID
 * @returns {Promise<boolean>} True if the signing was created successfully.
 */
const createSigning = async taskId => {
	await axios.post('/api/Signing', { taskId });

	return true;
};

export default { createSigning };
