<template functional>

    <path d="M14.8,7.6C15,7.5,15,7.3,15,7.1c0-0.2-0.1-0.4-0.2-0.5l-1.1-1.1c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2l-4.8,4.8
	L5.3,7.6C5.1,7.5,5,7.4,4.8,7.4S4.4,7.5,4.2,7.6L3.2,8.7C3,8.8,3,9,3,9.2C3,9.4,3,9.6,3.2,9.8L7.4,14c0.1,0.1,0.3,0.2,0.5,0.2
	c0.2,0,0.4-0.1,0.5-0.2L14.8,7.6z M16.8,4.5C17.6,5.9,18,7.4,18,9s-0.4,3.1-1.2,4.5s-1.9,2.5-3.3,3.3S10.6,18,9,18s-3.1-0.4-4.5-1.2
	S2,14.9,1.2,13.5S0,10.6,0,9s0.4-3.1,1.2-4.5S3.1,2,4.5,1.2S7.4,0,9,0s3.1,0.4,4.5,1.2S16,3.1,16.8,4.5z"/>

</template>





<script>

    export default {

        name: 'CheckIcon'

    }

</script>