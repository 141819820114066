import { render, staticRenderFns } from "./StsInput.vue?vue&type=template&id=3134199c&scoped=true&"
import script from "./StsInput.vue?vue&type=script&lang=js&"
export * from "./StsInput.vue?vue&type=script&lang=js&"
import style0 from "./StsInput.vue?vue&type=style&index=0&id=3134199c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3134199c",
  null
  
)

export default component.exports