<template functional>
	<g>
		<path
			fill="#05B2E6"
			d="M18,10.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.3,0.3-0.9,0.2-1.1-0.2c-0.1-0.2-0.2-0.2-0.3-0.1
	c-0.3,0.2-0.5,0.4-0.5,0.8c-0.1,1.1-0.5,2.1-1.3,2.9c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0.9-0.3,1.7-0.4,2.6c0,0.3-0.1,0.3-0.4,0.4
	c-0.3,0-0.7,0-1,0c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.3-0.3-0.7-0.5-1c-0.1-0.1-0.1-0.2-0.2-0.1c-1.5,0.4-3.1,0.3-4.6,0
	c-0.2,0-0.2,0-0.3,0.1c-0.2,0.3-0.3,0.7-0.5,1c-0.1,0.2-0.2,0.2-0.4,0.2c-0.3,0-0.7,0-1,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.7,0-1.4,0-2.1
	c0-0.1,0-0.2-0.2-0.3c-1-0.6-2-1.2-2.9-2c-0.6-0.5-0.9-1.3-1-2.1C0,10.2,0,9.9,0.1,9.6c0.1-0.3,0.3-0.5,0.6-0.5c0.3,0,0.6,0,0.9-0.1
	c0.1,0,0.2-0.1,0.2-0.2C2,8.4,2.3,8,2.7,7.7c0.1-0.1,0.1-0.2,0-0.3c-0.2-0.3-0.4-0.7-0.5-1C2,5.7,2.2,5.3,3,5.4
	c0.6,0.1,1.2,0.3,1.8,0.6c0.1,0.1,0.2,0.1,0.3,0c3-1.3,5.9-1.1,8.6,0.8c1,0.7,1.7,1.6,1.9,2.7c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
	c0.1,0,0.2-0.1,0.3-0.2c0.4-0.2,0.8-0.1,1,0.3c0.1,0.2,0.1,0.2,0.3,0.1c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.4-0.2,0.6,0.1
	C18,10.1,18,10.2,18,10.2z M2.8,6c0,0.1,0,0.2,0.1,0.3C3,6.7,3.2,7.1,3.4,7.4C3.6,7.7,3.5,7.8,3.3,8C2.9,8.3,2.5,8.7,2.3,9.2
	C2.2,9.4,2.1,9.4,2,9.5C1.6,9.6,1.2,9.7,0.8,9.7c-0.2,0-0.2,0.1-0.2,0.2c0,0.2,0,0.4,0,0.5c0.1,0.8,0.4,1.4,1.1,1.9
	c0.3,0.2,0.6,0.4,0.9,0.6c0.6,0.4,1.2,0.8,1.8,1.1c0.3,0.1,0.3,0.1,0.3,0.5c0,0.5,0,1.1,0,1.6c0,0.5,0,0.5,0.5,0.5
	c0.1,0,0.2,0,0.2-0.2c0.2-0.4,0.4-0.7,0.6-1.1c0.1-0.3,0.2-0.3,0.5-0.2c1.7,0.4,3.3,0.5,5,0c0.2-0.1,0.3,0,0.4,0.2
	c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.4,0.4,0.5,0.8,0.4c0.1,0,0.2,0,0.2-0.2c0.1-0.8,0.3-1.5,0.4-2.3c0-0.2,0.1-0.3,0.2-0.5
	c1.1-1.2,1.4-2.5,1.1-4.1c-0.2-1-0.9-1.8-1.8-2.4c-2.2-1.4-4.5-1.7-6.9-1C6,6.4,5.7,6.5,5.5,6.6C5.8,7,5.8,7,5.4,7.2c0,0,0,0,0,0
	c-0.1,0.1-0.1,0.1-0.2,0C5,6.9,4.6,6.7,4.3,6.5C3.8,6.3,3.3,6.1,2.8,6z"
		/>
		<path
			fill="#F58E2D"
			d="M8.9,0.8C9,0.8,9.1,0.8,9.2,0.8c1.8,0.4,2.6,2.6,1.5,4.1c-0.1,0.1-0.2,0.2-0.3,0c-0.3-0.3-0.3-0.3-0.1-0.6
	c0.4-0.7,0.4-1.5-0.1-2.2C9.7,1.5,8.9,1.3,8.1,1.4C7.4,1.6,6.8,2.3,6.7,3.1c-0.1,0.5,0.1,1,0.4,1.5c0.1,0.1,0.1,0.2,0,0.2
	c0,0,0,0-0.1,0.1C6.7,5.1,6.7,5.1,6.4,4.7c-1-1.6-0.1-3.7,1.8-3.9c0,0,0.1,0,0.1,0C8.5,0.8,8.7,0.8,8.9,0.8z"
		/>
		<path
			fill="#28457C"
			d="M8.6,12.7c-0.3,0-0.7,0-1,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.5,0-0.9,0-1.4c0-0.3,0.1-0.4,0.4-0.4
	c0.1,0,0.1,0,0.1-0.1c0-0.2,0-0.4,0-0.6c0.1-0.5,0.5-0.9,1-0.8c0.5,0,0.9,0.4,0.9,0.9c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.4,0.3,0.4
	c0.1,0,0.2,0.2,0.2,0.3c0,0.5,0,1,0,1.5c0,0.3-0.1,0.4-0.4,0.4C9.3,12.7,8.9,12.7,8.6,12.7z M8.6,12.4c0.3,0,0.6,0,1,0
	c0.1,0,0.2,0,0.2-0.1c0-0.4,0-0.9,0-1.3c0-0.1,0-0.1-0.1-0.1c-0.6,0-1.3,0-1.9,0c-0.1,0-0.1,0-0.1,0.1c0,0.4,0,0.9,0,1.3
	c0,0.1,0,0.1,0.2,0.1C8,12.4,8.3,12.4,8.6,12.4z M8.6,10.5c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.1-0.1c0-0.2,0-0.4,0-0.6
	C9.1,9.5,8.8,9.2,8.5,9.3C8.1,9.3,7.9,9.7,8,10.4c0,0.3,0.3,0.1,0.5,0.1C8.5,10.5,8.5,10.5,8.6,10.5z"
		/>
		<path
			fill="#06B2E6"
			d="M9.6,6.8c1.7,0.1,3.2,0.8,4.2,2.3c0.2,0.4,0.4,0.8,0.4,1.2c0,0.1,0,0.2,0,0.2c0,0.2-0.1,0.3-0.3,0.3
	c-0.2,0-0.3-0.1-0.3-0.3c0-0.8-0.5-1.4-1-1.9c-0.9-0.8-1.9-1.2-3.1-1.2c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.4-0.3
	C9.5,6.8,9.5,6.8,9.6,6.8z"
		/>
		<path
			fill="#16B7E7"
			d="M4.1,9.7c-0.4,0-0.4,0-0.4-0.4c0-0.2,0-0.3,0.2-0.3c0,0,0.1,0,0.1,0c0.5,0,0.4,0,0.4,0.4c0,0.1,0,0.2-0.2,0.2
	C4.2,9.7,4.2,9.7,4.1,9.7z"
		/>
	</g>
</template>





<script>
export default {
	name: 'PiggyBank'
};
</script>