import { collateralOptionsAPI } from "@/api";

export default {
    namespaced: true,
    state: {
        yearOptions: [],
        makeOptions: [],
        modelOptions: [],
        trimOptions: [],
    },
    mutations: {
        setData(state, { objName, data }) {
            state[objName] = data;
        },
        resetState(state) {
            state.yearOptions = [];
            state.makeOptions = [];
            state.modelOptions = [];
            state.trimOptions = [];
        },
    },
    actions: {
        async getYearOptionsAsync({ commit }, request) {
            const yearOptions = await collateralOptionsAPI.getYearOptionsAsync(
                request.collateralValuationType,
                request.rvCategoryId
            );

            commit("setData", {
                objName: "yearOptions",
                data: yearOptions,
            });

            return yearOptions;
        },
        async getMakeOptionsAsync({ commit }, request) {
            const makeOptions = await collateralOptionsAPI.getMakeOptionsAsync(
                request.collateralValuationType,
                request.year,
                request.rvCategoryId
            );

            commit("setData", {
                objName: "makeOptions",
                data: makeOptions,
            });

            return makeOptions;
        },
        async getModelOptionsAsync({ commit }, request) {
            const modelOptions =
                await collateralOptionsAPI.getModelOptionsAsync(
                    request.collateralValuationType,
                    request.year,
                    request.make,
                    request.rvCategoryId
                );

            commit("setData", {
                objName: "modelOptions",
                data: modelOptions,
            });

            return modelOptions;
        },
        async getTrimOptionsAsync({ commit }, request) {
            const trimOptions = await collateralOptionsAPI.getTrimOptionsAsync(
                request.collateralValuationType,
                request.year,
                request.make,
                request.model,
                request.condition
            );

            commit("setData", {
                objName: "trimOptions",
                data: trimOptions,
            });

            return trimOptions;
        },
        async getVinDetailsAsync({ commit }, request) {
            const vinDetails = await collateralOptionsAPI.getVinDetailsAsync(
                request.collateralValuationType,
                request.vin
            );

            commit("setData", {
                objName: "vinDetails",
                data: vinDetails,
            });

            return vinDetails;
        },
    },
};
