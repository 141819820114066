<template>
	<label :class="['input-container', { 'has-error': hasError }]" data-test-label>
		<input
			:id="id"
			:name="name"
			type="checkbox"
			v-model.trim="innerValue"
			:value="optionValue"
			:disabled="disabled"
			data-test-input
		/>

		<svg
			:class="['svg', { 'is-small': isSmall }]"
			fill="currentColor"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 50 50"
			style="enable-background: new 0 0 50 50"
			xml:space="preserve"
		>
			<g transform="matrix(1 0 0 1 -39 -867 )" class="check-outline">
				<path
					d="M82.9,873.1c-1.1-1.1-2.5-1.7-3.9-1.1H49.3c-1.7-0.6-2.8,0-3.9,1.1c-1.1,1.1-1.7,2.5-1.1,3.9v29.3c-0.6,1.7,0,2.8,1.1,3.9
                    c1.1,1.1,2.5,1.7,3.9,1.1h29.4c1.7,0.6,2.8,0,3.9-1.1s1.7-2.5,1.1-3.9v-29.3C84.6,875.6,84,874.2,82.9,873.1z M85.9,870
                    c1.9,1.9,3.1,4.4,3.1,7.2v29.3c0,2.8-1.1,5.2-3.1,7.2c-1.9,1.9-4.4,3-7.2,3H49.3c-2.8,0-5.3-1.1-7.2-3c-1.9-1.9-3.1-4.4-3.1-7.2
                    v-29.3c0-2.8,1.1-5.2,3.1-7.2c1.9-1.9,4.4-3,7.2-3h29.4C81.5,867,84,868.1,85.9,870z"
				/>
			</g>
			<path
				class="check"
				d="M20.8,38.4c0.6,0,1.1-0.3,1.4-0.6l20-19.9c0.3-0.3,0.6-0.8,0.6-1.4c0-0.6-0.3-1.1-0.6-1.4l-3.3-3.3
            c-0.3-0.3-0.8-0.6-1.4-0.6s-1.1,0-1.4,0.6L20.8,27.1l-6.9-6.9c-0.3-0.6-0.8-0.6-1.4-0.6s-1.1,0-1.4,0.6l-3.3,3.3
            c-0.6,0.3-0.6,0.8-0.6,1.4c0,0.6,0.3,1.1,0.6,1.4l11.7,11.6C19.7,38.4,20.3,38.4,20.8,38.4z M47.2,2.8C49.2,4.7,50,6.9,50,9.4v31.2
            c0,2.5-0.8,4.7-2.8,6.6c-1.9,1.9-4.2,2.8-6.7,2.8H9.4c-2.5,0-4.7-0.8-6.7-2.8S0,43.1,0,40.6V9.4c0-2.8,0.8-4.7,2.8-6.6S6.9,0,9.4,0
            h31.4C43.3,0,45.3,0.8,47.2,2.8z"
			/>
		</svg>

		<span v-html="label"></span>
	</label>
</template>




<script>
export default {
	name: 'Checkbox',
	props: {
		value: {
			required: true
		},
		optionValue: {
			required: false,
			default: true
		},
		label: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		},
		hasError: {
			type: Boolean,
			required: false
		},
		isSmall: {
			type: Boolean,
			required: false
		}
	},
	computed: {
		id() {
			return `${this.label.replace(/\s/g, '')}-${this._uid}`;
		},
		innerValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
};
</script>




<style lang="scss" scoped>
.input-container {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: var(--bodyCopy);
	margin-top: 1rem;

	&.no-padding {
		margin-top: 0;
	}

	&.has-error svg .check-outline {
		fill: var(--danger);
	}
	&.has-error span {
		color: var(--danger);
	}

	input {
		position: absolute;
		left: -9999px;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked + svg .check {
			opacity: 1;
		}

		&:active + svg,
		&:focus + svg {
			outline: 1.5px dashed var(--bodyCopy-50);
		}
	}

	.svg {
		box-sizing: border-box;
		width: 3.125rem;
		height: 3.125rem;

		.check-outline {
			fill: var(--disabled);
		}

		.check {
			fill: var(--success);
			opacity: 0;
			transition: opacity 0.25s;
		}

		&.is-small {
			width: 2rem;
			height: 2rem;
		}
	}

	span {
		padding-left: 1.5rem;
		flex: 1;
		font-size: 0.875rem;
	}

	&:hover {
		.check {
			opacity: 0.2;
		}
	}
}
</style>