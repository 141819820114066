<template>
	<Portal to="modal-content">
		<StsSessionExpired
			backTitle="Return to Desert Financial"
			backUrl="https://www.desertfinancial.com/personal"
			@restart="handleClick"
		/>
	</Portal>
</template>





<script>
import { Portal } from 'portal-vue';
import { mapActions } from 'vuex';

import { StsSessionExpired } from '@/components/SwitchThink';

export default {
	name: 'SessionExpired',
	components: {
		Portal,
		StsSessionExpired
	},
	async created() {

		try {
			await this.logOut();
		}
		catch (ex) {
			console.error(ex);
        }

		this.triggerModal({
			isOpen: true,
			title: 'Your session has expired.',
			smallTitle: true,
			preventClose: true
		});

		this.$route.params.sessionExpired = false;
	},
	async destroyed() {

		this.triggerModal({});

	},
	methods: {
		...mapActions('modal', ['triggerModal']),
        ...mapActions('applicant', ['logOut']),
		async handleClick() {

			this.triggerModal({});

		}
	}
};
</script>