<template>
	<aside :style="{ width: width + 'px', height: height + 'px' }">
		<BaseIcon name="Outline" :title="title" :width="width" :height="height" :color="outlineColor">
			<path
				d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9s3.8,8.4,8.4,8.4
	s8.4-3.8,8.4-8.4S13.6,0.6,9,0.6z" />
		</BaseIcon>
		<BaseIcon :name="name" :title="title" :width="width / 2" :height="height / 2" :color="color" class="inner-icon">
			<slot />
		</BaseIcon>
	</aside>
</template>



<script>
import { BaseIcon } from '@/components/SwitchThink/icons';

export default {
	name: 'OutlinedIcon',
	components: { BaseIcon },
		props: {
		name: {
			type: String,
			required: false
		},
		outlineColor: {
			type: String,
			required: false,
			default: '#2391EC'
		},
		color: {
			type: String,
			required: false,
			default: 'currentColor'
		},
		height: {
			type: Number,
			required: false,
			default: 100
		},
		width: {
			type: Number,
			required: false,
			default: 100
		},
        title: {
            type: String,
            required: false
        },
	}
};
</script>



<style lang="scss" scoped>
aside {
	position: relative;

	::v-deep .inner-icon {
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}
</style>