export default {
	NONE: 0,
	ACTIVE: 1,
	SATISFIED: 2,
	WAIVED: 3,
	SUBMITTED: 4,
	INCOMPLETE: 5,
	ARCHIVED: 6,
	0: 'None',
	1: 'Active',
	2: 'Satisfied',
	3: 'Waived',
	4: 'Submitted',
	5: 'Incomplete',
	6: 'Archived'
};
