<template functional>

    <path d="M18,15.8H0V15h1.9v-4.7L0.5,8.9c-0.1-0.1-0.1-0.4,0-0.5L3,5.8V1.5c0-0.2,0.2-0.4,0.4-0.4h2.3C5.8,1.1,6,1.3,6,1.5v1.3
		l2.7-2.7C8.9,0,9.1,0,9.3,0.1l8.3,8.3c0.1,0.1,0.1,0.4,0,0.5L16,10.4c-0.1,0.1-0.2,0.1-0.3,0.1V15H18V15.8z M8.3,15H15V9.9l-6-6
		l-6.4,6.4V15h1.9V9.8c0-0.2,0.2-0.4,0.4-0.4h3c0.2,0,0.4,0.2,0.4,0.4V15z M5.3,15h2.3v-4.9H5.3V15z M9,3c0.1,0,0.2,0,0.3,0.1
		l6.5,6.5l1-1L9,0.9L1.3,8.6l1,1l6.5-6.5C8.8,3,8.9,3,9,3z M3.8,1.9v3.2l1.5-1.5V1.9H3.8z M13.1,13.1H9.4C9.2,13.1,9,13,9,12.8v-3
		c0-0.2,0.2-0.4,0.4-0.4h3.8c0.2,0,0.4,0.2,0.4,0.4v3C13.5,13,13.3,13.1,13.1,13.1z M9.8,12.4h3v-2.3h-3V12.4z"/>

</template>





<script>

    export default {

        name: 'House'

    }

</script>