import { extend } from 'vee-validate';
import * as builtInRules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en';
import * as customRules from '@/validations';

export default function (rules) {

    for (const rule of rules) {

        if (rule.startsWith('custom_')) {

            extend(rule, customRules[rule]);

        } else {

            extend(rule, {
                ...builtInRules[rule],
                message: en.messages[rule]
            });

        };

    };

};