import {uiTextValuesAPI} from "@/api";

export default {
    namespaced: true,
    state: {
        uiTextValues: [],
    },
    mutations: {
        setUiTextValues(state, {uiTextValues}) {
            state.uiTextValues = uiTextValues;
        },
    },
    actions: {
        /**
         * @param state
         */
        resetState(state) {
            state.uiTextValues = [];
        },
        async getUiTextValue({commit, state}, request) {
            try {
                let uiTextValues = state.uiTextValues;

                // If our state is empty, let's populate it
                if (!uiTextValues || !uiTextValues.length) {
                    uiTextValues = await uiTextValuesAPI.getUiTextValues(
                        request.uiLanguageType
                    );

                    commit("setUiTextValues", {uiTextValues});
                }

                for (const uiTextValue of uiTextValues) {
                    if (
                        uiTextValue.uiTextComponentTypeId ===
                        request.uiTextComponentType
                    ) {
                        return uiTextValue.value;
                    }
                }
            } catch (error) {
                throw error;
            }
        },
    },
};
