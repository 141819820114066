export default {
  NONE: 0,
  AUTO: 1,
  HELOC: 2,
  AUTO_REFINANCE: 3,
  SIGNATURE: 4,
  PLOC: 5,
  MOTORCYCLE: 6,
  BOAT: 7,
  RV: 8,
  0: "None",
  1: "Auto",
  2: "HELOC",
  3: "Auto Refinance",
  4: "Signature",
  5: "PLOC",
  6: "Motorcycle",
  7: "Boat",
  8: "RV"
};
