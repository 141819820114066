<template functional>

    <path d="M11.9,18H1.2C0.6,18,0,17.4,0,16.8V9c0-0.7,0.6-1.2,1.2-1.2H2V4.5C2,2,4.1,0,6.5,0C9,0,11,2,11,4.5v3.3h0.8
	c0.7,0,1.2,0.6,1.2,1.2v7.8C13.1,17.4,12.5,18,11.9,18z M1.2,8.6C1,8.6,0.8,8.8,0.8,9v7.8c0,0.2,0.2,0.4,0.4,0.4h10.6
	c0.2,0,0.4-0.2,0.4-0.4V9c0-0.2-0.2-0.4-0.4-0.4H1.2z M2.9,7.8h7.4V4.5c0-2-1.7-3.7-3.7-3.7S2.9,2.5,2.9,4.5V7.8z M6.6,16
	c-0.7,0-1.2-0.6-1.2-1.2v-1.2c-0.4-0.3-0.6-0.6-0.8-1.1c-0.1-0.5-0.1-1,0.2-1.5c0.3-0.5,0.7-0.8,1.3-1c0,0,0,0,0,0
	c0.9-0.2,1.8,0.2,2.3,0.9c0.5,1,0.3,2.1-0.5,2.7v1.3C7.8,15.4,7.3,16,6.6,16z M6.3,10.7C6,10.7,5.7,11,5.5,11.2
	c-0.2,0.3-0.2,0.6-0.1,0.9c0.1,0.4,0.3,0.6,0.6,0.8c0.1,0.1,0.2,0.2,0.2,0.4v1.5c0,0.2,0.2,0.4,0.4,0.4C6.8,15.1,7,15,7,14.7v-1.5
	c0-0.1,0.1-0.3,0.2-0.4c0.6-0.3,0.8-1.1,0.5-1.7C7.4,10.8,6.8,10.5,6.3,10.7L6.3,10.7z"/>

</template>





<script>

    export default {

        name: 'LockIcon'

    }

</script>