<template>
	<header :style="{ 'background-image': `url(${image})` }" :class="{ 'can-overflow': overflow }">
		<img :src="logo" :alt="logoTitle" />

		<slot />
	</header>
</template>





<script>
export default {
	name: 'Hero',
	props: {
		overflow: {
			type: Boolean,
			required: false
		},
		image: {
			type: String,
			required: true
		},
		logo: {
			type: String,
			required: true
		},
		logoTitle: {
			type: String,
			required: true
		}
	}
};
</script>





<style lang="scss" scoped>
header {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 205px;
	background: var(--darkGrey);
	text-align: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	img {
		flex: 1;
		margin: auto 0;
		width: auto;
		max-width: 60%;
		height: auto;
		max-height: 8rem;
	}

	h1 {
		//display: none;
		font-size: 1.875rem;
		line-height: 2.5rem;
		font-weight: 400;
		text-align: center;
		color: var(--headerCopy);
		text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
		margin-top: 0;
		margin-bottom: 1rem;
	}
}

@media only screen and (max-width: 850px) {
	header.can-overflow {
		display: block;
		position: absolute;
		top: 0;
		height: 270px;
		padding-top: 50px;

		img {
			margin-top: 2rem;
			height: 4.5rem;
		}
	}
}

@media only screen and (min-width: 850px) {
	header {
		box-sizing: border-box;
		padding: 1rem;
		position: relative;
		display: block;
		height: 100%;

		img {
			margin: 2rem 0;
			max-width: 90%;
		}
	}
}
</style>