<template>
	<section>
		<p>{{ message }}</p>

		<section class="button-container">
			<StsButton
				:title="cancelText"
				type="plain"
				small
				fullwidth
				@click="$emit('close')"
				style="border-top: 2px solid var(--primary)"
			/>
			<StsButton :title="acceptText" fullwidth @click="$emit('accept')" :small="isSmall" />
		</section>
	</section>
</template>




<script>
import { StsButton } from '@/components/SwitchThink';

export default {
	name: 'StsConfirm',
	components: { StsButton },
	props: {
		message: {
			type: String,
			required: true
		},
		acceptText: {
			type: String,
			required: false,
			default: 'Confirm'
		},
		cancelText: {
			type: String,
			required: false,
			default: 'Cancel'
		},
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		}
	}
};
</script>




<style lang="scss" scoped>
.button-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: -1rem;
	margin-top: 1rem;
}
</style>