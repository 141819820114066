import applicant from "./applicant";
import branding from "./branding";
import challenge from "./challenge";
import collateralOptions from "./collateralOptions.js";
import config from "./config";
import error from "./error";
import modal from "./modal";
import pageOptions from "./pageOptions";
import routeEnforcer from "./routeEnforcer";
import sessionTimer from "./sessionTimer";
import uiTextValues from "./uiTextValues.js";

export {
    applicant,
    branding,
    config,
    error,
    modal,
    pageOptions,
    routeEnforcer,
    sessionTimer,
    challenge,
    collateralOptions,
    uiTextValues,
};

export default {
    applicant,
    branding,
    config,
    error,
    modal,
    pageOptions,
    routeEnforcer,
    sessionTimer,
    challenge,
    collateralOptions,
    uiTextValues,
};
