import { Wormhole } from 'portal-vue';

export default {

    namespaced: true,
	state: {

		isOpen: false,
		title: null,
		smallTitle: false,
		preventClose: false,
		secondaryIsOpen: false,
		secondaryTitle: null,
		secondarySmallTitle: false,
		secondaryPreventClose: false

	},
	mutations: {
		
		setData(state, { isOpen, title, smallTitle, preventClose }) {

			state.isOpen = isOpen;
			state.title = title ? title : null;
			state.smallTitle = smallTitle ? smallTitle : false;
			state.preventClose = preventClose ? preventClose : false;

		},
		setSecondaryData(state, { isOpen, title, smallTitle, preventClose }) {

			state.secondaryIsOpen = isOpen;
			state.secondaryTitle = title ? title : null;
			state.secondarySmallTitle = smallTitle ? smallTitle : false;
			state.secondaryPreventClose = preventClose ? preventClose : false;

		},

	},
	actions: {

		triggerModal({ commit }, { isOpen, ...rest }) {

			// Destroy the Portal content for the modal before closing it,
			// so we don't have to manually do it somewhere else:
			if (!isOpen) {

				Wormhole.close({ to: 'modal-content' }, true);
				Wormhole.close({ to: 'modal-footer' }, true);

			};
			
			commit('setData', { ...rest, isOpen });

		},
		triggerSecondaryModal({ commit }, { isOpen, ...rest }) {
			
			if (!isOpen) {

				Wormhole.close({ to: 'secondary-modal-content' }, true);
				Wormhole.close({ to: 'secondary-modal-footer' }, true);

			};
			
			commit('setSecondaryData', { ...rest, isOpen });

		}

	},
	getters: {

		hasPortal: () => ({
			content: Wormhole.hasContentFor('modal-content'),
			footer: Wormhole.hasContentFor('modal-footer')
		}),
		hasSecondaryPortal: () => ({
			content: Wormhole.hasContentFor('secondary-modal-content'),
			footer: Wormhole.hasContentFor('secondary-modal-footer')
		})

	}

};