import { getDateString } from '@/utils/dates';

export default {
    validate: value => {

        const maxYear = 2400;
        const newDate = typeof value !== "object" ? new Date(value) : value;
        const isValid = newDate instanceof Date && !isNaN(newDate) && getDateString(value) === value;
        const lessThanMaxYear = newDate.getFullYear() < maxYear;

        if (!isValid) { return 'The {_field_} field must be a valid date' };
        if (!lessThanMaxYear) { return `The {_field_} field must be a date before the year ${maxYear}` };
        
        return true;

    }
};