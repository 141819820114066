<template>
	<ValidationProvider :rules="validationRules" :name="name" v-slot="{ errors }" slim>
		<section>
			<div
				v-for="(option, index) in options"
				:key="option.label"
				:class="[
					'check-group',
					{
						'no-margin': index + 1 === options.length,
						'is-small': isSmall
					}
				]"
			>
				<StsCheckbox
					v-model="innerValue"
					:optionValue="option.value"
					:label="option.label"
					:name="name"
					:disabled="disabled || option.disabled"
					:hasError="errors.length > 0"
					:isSmall="isSmall"
				/>

				<slot v-if="$slots.default && innerValue === option.value" />
			</div>
			<transition name="fade">
				<p class="has-error" v-if="errors.length > 0" data-test-error>
					{{ errors[0] }}
				</p>
			</transition>
		</section>
	</ValidationProvider>
</template>




<script>
import { ValidationProvider } from 'vee-validate';

import { StsCheckbox } from '@/components/SwitchThink/form';

export default {
	name: 'VCheckGroup',
	components: {
		ValidationProvider,
		StsCheckbox
	},
	props: {
		value: {
			required: true
		},
		name: {
			type: String,
			required: true
		},
		options: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		},
		validationRules: {
			type: [String, Object],
			required: false
		}
	},
	computed: {
		innerValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
};
</script>




<style lang="scss" scoped>
.check-group {
	margin-bottom: 3rem;

	&.is-small {
		margin-bottom: 1.5rem;
	}

	&.no-margin {
		margin-bottom: 0;
	}
}

p.has-error {
	border-top: 1px solid var(--danger-25);
	font-size: 0.85rem;
	margin-top: 0.5rem;
	padding-top: 0.5rem;
	color: var(--danger);
}
</style>