<template functional>

    <g>
        <path style="fill:#2391EC;" d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9
            c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
        <path style="fill:#203E77;" d="M10.9,13.8h-6V6.6l1.2-1.2h4.9V13.8z M5.3,13.4h5.2V5.9H6.2L5.3,6.8V13.4z"/>
        <polygon style="fill:#203E77;" points="11.8,12.7 11.3,12.7 11.3,5.1 6.8,5.1 6.8,4.6 11.8,4.6 	"/>
        <polygon style="fill:#203E77;" points="12.6,11.9 12.2,11.9 12.2,4.2 7.6,4.2 7.6,3.8 12.6,3.8 	"/>
        <polygon style="fill:#203E77;" points="6.8,7.3 5.5,7.3 5.5,6.9 6.4,6.9 6.4,6.1 6.8,6.1 	"/>
        <path style="fill:#203E77;" d="M7.7,11.7c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8C9.5,10.9,8.7,11.7,7.7,11.7z
            M7.7,8.6C7,8.6,6.4,9.2,6.4,9.9s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S8.5,8.6,7.7,8.6z"/>
        <rect x="9.2" y="10.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.3007 9.9634)" style="fill:#203E77;" width="0.4" height="1.5"/>
        <polygon style="fill:#203E77;" points="7.6,10.8 6.8,10 7.1,9.7 7.6,10.1 8.3,9.1 8.6,9.3 	"/>
    </g>

</template>





<script>

    export default {

        name: 'StatusIcon'

    }

</script>