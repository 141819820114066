<template>
	<ValidationProvider
		:custom-messages="customMessages"
		:rules="validationRules"
		:name="name || label"
		:vid="validationId"
		v-slot="{ errors }"
		slim
	>
		<StsTextArea
			v-model="innerValue"
			:label="label"
			:placeholder="placeholder"
			:disabled="disabled"
			:error="errors[0]"
			:isOptional="isOptional"
			:disableAutocomplete="disableAutocomplete"
			:isSmall="isSmall"
			@change="$emit('change', $event)"
			:id="id"
			:rows="rows"
			:tooltip="tooltip"
		/>
	</ValidationProvider>
</template>

<script>
	import { ValidationProvider } from 'vee-validate';

	import { StsTextArea } from '@/components/SwitchThink/form';

	export default {
		name: 'VTextArea',
		components: {
			ValidationProvider,
			StsTextArea
		},
		props: {
			value: {
				required: true
			},
			label: {
				type: String,
				required: true
			},
			name: {
				type: String,
				required: false
			},
			placeholder: {
				type: String,
				required: false
			},
			disabled: {
				type: Boolean,
				required: false
			},
			validationRules: {
				type: [String, Object],
				required: false
			},
			isOptional: {
				type: Boolean,
				required: false
			},
			disableAutocomplete: {
				type: Boolean,
				required: false
			},
			validationId: {
				type: String,
				required: false
			},
			isSmall: {
				type: Boolean,
				required: false,
				default: false
			},
			id: {
				type: [String, Number],
				required: false
			},
			rows: {
				type: Number,
				required: false
			},
			tooltip: {
				type: Object,
				required: false
			},
			customMessages: {
				type: Object,
				required: false
			}
		},
		computed: {
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				}
			}
		}
	};
</script>
