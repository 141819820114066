export default {
	data() {
		return {
			isDesktop: false,
			isMobile: false
		};
	},
	created() {
		this.watchDeviceSize();
	},
	methods: {
		writeDeviceSize(matches) {
			if (matches) {
				this.isDesktop = false;
				this.isMobile = true;
			} else {
				this.isDesktop = true;
				this.isMobile = false;
			}
		},
		watchDeviceSize() {
			if (window.matchMedia) {
				const mediaQuery = window.matchMedia('(max-width: 849px)');

				this.writeDeviceSize(mediaQuery.matches);

				mediaQuery.addEventListener('change', event => {
					this.writeDeviceSize(event.matches);
				});
			}
		}
	}
};
