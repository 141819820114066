<template functional>
	<path
		d="M6.3,16.2c-0.8,0-1.4-0.7-1.4-1.5V8.3c0-0.8,0.6-1.4,1.4-1.5h10.2c0.8,0,1.4,0.7,1.4,1.5v6.4c0,0.8-0.6,1.4-1.4,1.5
	C16.6,16.2,6.3,16.2,6.3,16.2z M5.6,11.3v3.4c0,0.4,0.4,0.8,0.8,0.8h10.2c0.4,0,0.8-0.4,0.8-0.8v-3.4H5.6z M5.6,10.6h11.7V9.4H5.6
	V10.6z M5.6,8.7h11.7V8.3c0-0.4-0.3-0.8-0.8-0.8H6.3c-0.4,0-0.8,0.4-0.8,0.8V8.7z M3.7,14.3c-0.6,0-1.2-0.4-1.4-0.9l-2.2-6
	C-0.2,6.5,0.2,5.7,1,5.4l9.6-3.5c0.4-0.1,0.8-0.1,1.1,0c0.4,0.2,0.6,0.5,0.8,0.8l1.3,3.5L13,6.5l-1.3-3.5c-0.1-0.2-0.2-0.4-0.4-0.5
	c-0.2-0.1-0.4-0.1-0.6,0L1.2,6.1c-0.4,0.1-0.6,0.6-0.5,1l2.2,6c0.1,0.4,0.6,0.6,1,0.5l0.4-0.1L4.6,14l-0.4,0.1
	C4,14.3,3.9,14.3,3.7,14.3z M9,12.8H6.4v-0.7H9V12.8z"
	/>
</template>





<script>
export default {
	name: 'HollowCardIcon'
};
</script>