export default [
	{
		path: '/',
		name: 'Authentication',
		component: () => import(/* webpackChunkName: "Authentication" */ '@/views/Authentication'),
		meta: {
			backButton: {
				route: 'https://www.desertfinancial.com/personal',
				title: 'Return to the Desert Financial Homepage'
			},
			overflow: true,
			resetState: true,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'logout',
		name: 'LoggedOut',
		component: () => import(/* webpackChunkName: "LoggedOut" */ '@/views/LoggedOut'),
		meta: {
			overflow: false,
			resetState: true,
			isDisplayOnly: true,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: false
		}
	},
	{
		path: 'loan/inactive',
		name: 'InactiveLoan',
		component: () => import(/* webpackChunkName: "InactiveLoan" */ '@/views/InactiveLoan'),
		meta: {
			overflow: true,
			resetState: true,
			isDisplayOnly: true,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: false
		}
	},
	{
		path: 'loan/declined',
		name: 'DeclinedLoan',
		component: () => import(/* webpackChunkName: "DeclinedLoan" */ '@/views/DeclinedLoan'),
		meta: {
			overflow: true,
			resetState: true,
			isDisplayOnly: true,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: false
		}
	}
];
