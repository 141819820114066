export default {
	NONE: 0,
	LOANTERMS: 1,
	AMOUNT: 2,
	TERM: 3,
	RATE: 4,
	MONTHLYPAYMENT: 5,
	0: 'None',
	1: 'Loan Terms',
	2: 'Amount',
	3: 'Term',
	4: 'Rate',
	5: 'MonthlyPayment',
};
