<template>

    <section>

        <slot/>

    </section>
    
</template>





<script>

    export default {

        name: 'FormWizard',
        props: {

            activeTab: {
                type: Number,
                required: true
            }

        },
        data() {
            return {
                
                tabs: []
                
            }
        },
        async created() {
            
            this.tabs = await this.$children;

            this.tabs[this.activeTab].isActive = true;

        },
        watch: {
            
            activeTab(index) {

                for (const tabIndex in this.tabs) {

                    tabIndex == index
                        ? this.tabs[tabIndex].isActive = true
                        : this.tabs[tabIndex].isActive = false;

                }

            }

        }

    };

</script>