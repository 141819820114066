import { axios } from '@/utils';

/**
 * @typedef {object} epoaRequiredFlagUpdateViewModelRequest
 * @property {number} taskId Task ID
 */

/**
 * @param {epoaRequiredFlagUpdateViewModelRequest} request The update EPOA Required Flag request.
 * @returns {Promise<boolean>} True if the EPOA Required Flag was updated successfully.
 */
const updateEpoaRequiredFlag = async request => {
    const { updated } = await axios.put('/api/AdditionalFlags/EpoaRequired', request);

    return updated;
};

export default { updateEpoaRequiredFlag };