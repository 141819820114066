<template>
	<StsButton :title="title" type="plain" buttonType="button" @click="$emit('click')" fullwidth>
		<p class="button-container">
			<span>{{ title }}</span>
			<BaseIcon height="3rem" width="3rem" name="Add">
				<Add />
			</BaseIcon>
		</p>
	</StsButton>
</template>




<script>
import { BaseIcon, Add } from '@/components/SwitchThink/icons';
import StsButton from './StsButton';

export default {
	name: 'AddButton',
	components: {
		BaseIcon,
		Add,
		StsButton
	},
	props: {
		title: {
			type: String,
			required: true
		}
	}
};
</script>





<style lang="scss" scoped>
.button-container {
	width: 100%;
	display: flex;
	align-items: center;
	text-align: left;
	padding: 0 0.9375rem;

	span {
		flex: 1;
	}
}
</style>