import { render, staticRenderFns } from "./StsBrowserMessage.vue?vue&type=template&id=4bb2ad39&scoped=true&functional=true&"
import script from "./StsBrowserMessage.vue?vue&type=script&lang=js&"
export * from "./StsBrowserMessage.vue?vue&type=script&lang=js&"
import style0 from "./StsBrowserMessage.vue?vue&type=style&index=0&id=4bb2ad39&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "4bb2ad39",
  null
  
)

export default component.exports