export default {
	NONE: 0,
	AMOUNT_TITLE: 1,
	TERM_TITLE: 2,
	RATE_TITLE: 3,
    MONTHLY_PAYMENT_TITLE: 4,
    AMOUNT: 5,
    TERM: 6,
    RATE: 7,
    MONTHLY_PAYMENT: 8,
    ESTIMATED_LOAN_TERMS: 9,
	0: 'None',
	1: 'Amount Title',
	2: 'Term Title',
	3: 'Rate Title',
    4: 'Monthly Payment Title',
    5: 'Amount',
    6: 'Term',
    7: 'Rate',
    8: 'Monthly Payment',
    9: 'Estimated Loan Terms'
};
