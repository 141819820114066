<template>
    <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        tag="form"
        @submit.prevent="handleSubmit()"
    >
        <StsCard v-if="isValuationEnabled" style="margin-bottom: 1.5rem">
            <StsVInput
                v-if="showVin"
                v-model="innerDetails.vehicleIdentificationNumber"
                :disabled="submitting"
                label="VIN"
                type="text"
                validationRules="required"
                forceCapitalization
                @input.native="enableVinSearch"
                @keydown.native="validateInput"
            >
                <template #input-control>
                    <StsButton
                        :disabled="!vinSearchAllowed"
                        title="Search"
                        @click="searchVinAsync"
                    />
                </template>
            </StsVInput>

            <p v-if="vinSearchError" class="has-error">
                {{ vinSearchError }}
            </p>

            <StsVSelect
                v-if="showYears"
                v-model.number="innerDetails.year"
                :disabled="disableYears"
                :options="options.years"
                label="Year"
                placeholder="Please select the year"
                validationRules="required"
                @input="handleInput(CollateralOptionType.YEAR)"
            />

            <StsVSelect
                v-if="showMakes"
                v-model="innerDetails.make"
                :disabled="disableMakes"
                :options="options.makes"
                label="Make"
                placeholder="Please select the make"
                validationRules="required"
                @input="handleInput(CollateralOptionType.MAKE)"
            />

            <StsVSelect
                v-if="showModels"
                v-model="innerDetails.model"
                :options="options.models"
                :disabled="disableModels"
                label="Model"
                placeholder="Please select the model"
                validationRules="required"
                @input="handleInput(CollateralOptionType.MODEL)"
            />

            <StsVSelect
                v-if="showTrims"
                v-model="innerDetails.trim"
                :disabled="disableTrims"
                :options="options.trims"
                :placeholder="placeholderTrims"
                label="Body Style"
                validationRules="required"
            />

            <StsVInput
                v-if="showMileage"
                v-model.number="innerDetails.mileage"
                label="Exact Mileage"
                mask="######"
                type="tel"
                validationRules="required"
            />

            <StsVSelect
                v-if="showVehicleTypes"
                v-model="innerDetails.vehicleType"
                :disabled="disableVehicleTypes"
                :options="vehicleTypes"
                label="TYPE"
                validationRules="required"
            />

            <StsVInput
                v-if="showHullId"
                v-model="innerDetails.hullId"
                :disabled="disableHullId"
                label="HULL ID"
                type="text"
                validationRules="required"
                forceCapitalization
            />

            <BaseIcon
                v-if="populatingOptions"
                height="1rem"
                name="populatingOptionsClock"
                viewBox="0 0 50 50"
                width="1rem"
            >
                <Clock />
            </BaseIcon>
        </StsCard>

        <StsCard v-if="!isValuationEnabled" style="margin-bottom: 1.5rem">
            <StsVInput
                v-if="isAutoCollateral"
                v-model="innerDetails.vehicleIdentificationNumber"
                :disabled="submitting"
                label="VIN"
                type="text"
                validationRules="required"
                forceCapitalization
            />

            <StsVInput
                v-model="innerDetails.year"
                :disabled="submitting"
                label="YEAR"
                mask="####"
                type="tel"
                validationRules="required|digits:4|min_value:1900"
            />

            <StsVInput
                v-model="innerDetails.make"
                :disabled="submitting"
                label="MAKE"
                type="text"
                validationRules="required"
            />

            <StsVInput
                v-model="innerDetails.model"
                :disabled="submitting"
                label="MODEL"
                type="text"
                validationRules="required"
            />

            <StsVInput
                v-if="isAutoCollateral"
                v-model="innerDetails.trim"
                :disabled="submitting"
                label="BODY STYLE"
                type="text"
                validationRules="required"
            />

            <StsVInput
                v-if="isAutoCollateral"
                v-model="innerDetails.mileage"
                :disabled="submitting"
                label="EXACT MILEAGE"
                type="tel"
                mask="######"
                validationRules="required"
            />

            <StsVSelect
                v-if="isMarineCollateral"
                v-model="innerDetails.vehicleType"
                :disabled="submitting"
                :options="vehicleTypes"
                label="TYPE"
                validationRules="required"
            />

            <StsVInput
                v-if="isMarineCollateral"
                v-model="innerDetails.hullId"
                :disabled="submitting"
                label="HULL ID"
                type="text"
                validationRules="required"
                forceCapitalization
            />
        </StsCard>
    </ValidationObserver>
</template>

<script>
    import { collateralAPI } from "@/api";
    import { StsButton, StsCard } from "@/components/SwitchThink";
    import { StsVInput, StsVSelect } from "@/components/SwitchThink/form";
    import { BaseIcon, Clock } from "@/components/SwitchThink/icons";
    import {
        CollateralOptionType,
        CollateralValuationType,
        ProductType,
    } from "@/constants";
    import { collateralValuation, showSpinner } from "@/mixins";
    import { collateralOptions } from "@/store/modules";
    import { ValidationObserver } from "vee-validate";
    import { mapActions } from "vuex";

    export default {
        name: "VehicleDetails",
        components: {
            StsCard,
            StsVSelect,
            Clock,
            BaseIcon,
            ValidationObserver,
            StsButton,
            StsVInput,
        },
        mixins: [collateralValuation, showSpinner],
        props: {
            collateral: {
                type: Object,
                required: true,
            },
            collateralValuationType: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                innerDetails: {
                    hullId: null,
                    make: null,
                    mileage: null,
                    model: null,
                    trim: null,
                    vehicleIdentificationNumber: null,
                    vehicleType: null,
                    year: null,
                },
                options: {
                    years: [],
                    makes: [],
                    models: [],
                    trims: [],
                },
                vinSearchResults: {
                    year: 0,
                    make: null,
                    model: null,
                    trims: [],
                },
                vehicleTypes: [],
                populatingOptions: false,
                vinSearchEnabled: false,
                vinSearchError: null,
                vinSearchSuccess: false,
                CollateralOptionType,
            };
        },
        computed: {
            allOptionsSelected() {
                if (!this.isValuationEnabled) {
                    return true;
                }

                if (!this.showYears || !this.showMakes || !this.showModels) {
                    return false;
                }

                switch (this.collateralValuationType) {
                    case CollateralValuationType.AUTO:
                        return [
                            !!this.innerDetails.year,
                            !!this.innerDetails.make,
                            !!this.innerDetails.model,
                            !!this.innerDetails.trim,
                        ].every((bool) => bool);
                    case CollateralValuationType.MARINE:
                        return [
                            !!this.innerDetails.year,
                            !!this.innerDetails.make,
                            !!this.innerDetails.model,
                        ].every((bool) => bool);
                }
            },
            disableHullId() {
                return this.submitting;
            },
            disableMakes() {
                return this.submitting || this.isAutoCollateral;
            },
            disableModels() {
                return this.submitting || this.isAutoCollateral;
            },
            disableTrims() {
                // We only want to disable the trims dropdown if we only got back one trim from the vendor
                return (
                    !this.isAutoCollateral ||
                    this.vinSearchResults.trims.length === 1
                );
            },
            disableVehicleTypes() {
                return this.submitting;
            },
            disableYears() {
                return this.submitting || this.isAutoCollateral;
            },
            isAutoCollateral() {
                return (
                    this.collateralValuationType ===
                    CollateralValuationType.AUTO
                );
            },
            isMarineCollateral() {
                return (
                    this.collateralValuationType ===
                    CollateralValuationType.MARINE
                );
            },
            isValuationEnabled() {
                switch (this.collateralValuationType) {
                    case CollateralValuationType.AUTO:
                        return this.valuationEnabled(ProductType.AUTO);
                    case CollateralValuationType.MARINE:
                        return this.valuationEnabled(ProductType.BOAT);
                    default:
                        return false;
                }
            },
            placeholderTrims() {
                const modelIsPopulated = !!this.innerDetails.model;
                return modelIsPopulated ? "Please select the body style" : "";
            },
            showHullId() {
                return this.isMarineCollateral && this.allOptionsSelected;
            },
            showMakes() {
                return this.options.makes.length && this.showYears;
            },
            showMileage() {
                return this.isAutoCollateral && this.allOptionsSelected;
            },
            showModels() {
                return this.options.models.length && this.showMakes;
            },
            showTrims() {
                return (
                    this.isAutoCollateral &&
                    this.options.trims.length &&
                    this.showModels
                );
            },
            showVehicleTypes() {
                return this.allOptionsSelected && this.isMarineCollateral;
            },
            showYears() {
                return (
                    this.options.years.length &&
                    (!this.isAutoCollateral || this.vinSearchSuccess)
                );
            },
            showVin() {
                return this.isAutoCollateral;
            },
            vinSearchAllowed() {
                return (
                    this.vinSearchEnabled &&
                    this.validateVin(
                        this.innerDetails.vehicleIdentificationNumber
                    )
                );
            },
        },
        watch: {
            "innerDetails.vehicleIdentificationNumber"(newValue) {
                if (newValue) {
                    this.$nextTick(() => {
                        this.innerDetails.vehicleIdentificationNumber =
                            this.getValidVinInput(newValue);
                    });
                }

                this.onVehicleDetailsChange();
            },
            allOptionsSelected: {
                immediate: true,
                handler(newValue) {
                    this.$emit("all-options-selected-change", newValue);

                }
            },
            innerDetails: {
                handler() {
                    this.onVehicleDetailsChange();
                },
                deep: true,
            },
            vinSearchSuccess(newValue) {
                this.$emit("vin-search-success-change", newValue);
            },
        },
        async created() {
            try {
                this.showSpinner({ loading: true });

                this.innerDetails = {
                    hullId: this.collateral.hullId,
                    make: this.collateral.make,
                    mileage: this.collateral.mileage,
                    model: this.collateral.model,
                    trim: this.collateral.trim,
                    vehicleIdentificationNumber:
                        this.collateral.vehicleIdentificationNumber,
                    vehicleType: this.collateral.vehicleType,
                    year: this.collateral.year,
                };

                if (!this.$store.hasModule("collateralOptions")) {
                    this.$store.registerModule(
                        "collateralOptions",
                        collateralOptions
                    );
                }

                await this.initializeOptionsAsync();

                this.showSpinner({ loading: false });
            } catch (error) {
                this.showSpinner({ loading: false });

                this.toast.error(error);
            }
        },
        methods: {
            ...mapActions("collateralOptions", [
                "getYearOptionsAsync",
                "getMakeOptionsAsync",
                "getModelOptionsAsync",
                "getTrimOptionsAsync",
                "getVinDetailsAsync",
            ]),
            onVehicleDetailsChange() {
                this.$emit("inner-details-change", this.innerDetails);
            },
            async initializeOptionsAsync() {
                try {
                    if (this.isMarineCollateral) {
                        const config =
                            await collateralAPI.getBoatCollateralOptions();
                            
                        this.vehicleTypes = config.vehicleTypes.map((type) => ({
                            label: type.displayText,
                            value: type.id,
                        }));
                    }

                    if (!this.isValuationEnabled) {
                        return;
                    }

                    if (this.isAutoCollateral) {
                        return await this.initializeAutoOptionsAsync();
                    } else if (this.isMarineCollateral) {
                        return await this.initializeMarineOptionsAsync();
                    }
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async initializeAutoOptionsAsync() {
                try {
                    if (
                        this.showVin &&
                        this.innerDetails.vehicleIdentificationNumber
                    ) {
                        await this.searchVinAsync();
                    }

                    if (this.vinSearchSuccess && !this.options.trims.length) {
                        // If we didn't get back any trims from the VIN search then we need to manually populate the values
                        await this.populateTrimOptionsAsync(
                            this.innerDetails.year,
                            this.innerDetails.make,
                            this.innerDetails.model
                        );
                    }
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async initializeMarineOptionsAsync() {
                try {
                    await this.populateYearOptionsAsync();

                    if (this.innerDetails.year) {
                        await this.populateMakeOptionsAsync(
                            this.innerDetails.year
                        );
                    }

                    if (this.innerDetails.make) {
                        await this.populateModelOptionsAsync(
                            this.innerDetails.year,
                            this.innerDetails.make
                        );
                    }
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            clearVin() {
                this.innerDetails.vehicleIdentificationNumber = null;
            },
            enableVinSearch() {
                // Reset the search variables
                this.vinSearchResults.year = 0;
                this.vinSearchResults.make = null;
                this.vinSearchResults.model = null;
                this.vinSearchResults.trims = [];
                this.vinSearchEnabled = true;
                this.vinSearchError = null;
                this.vinSearchSuccess = false;
            },
            async populateYearOptionsAsync() {
                try {
                    this.populatingOptions = true;

                    // Populating the years means we have to invalidate every option afterwards
                    // since they all require the year
                    this.options.years = [];
                    this.options.makes = [];
                    this.options.models = [];
                    this.options.trims = [];

                    const request = {
                        collateralValuationType: this.collateralValuationType,
                    };

                    const response = await this.getYearOptionsAsync(request);

                    this.options.years = response.options.map((yearOption) => ({
                        label: yearOption.value,
                        value: yearOption.value,
                    }));

                    this.populatingOptions = false;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async populateMakeOptionsAsync(year) {
                try {
                    this.populatingOptions = true;

                    // The options for makes will change, which invalidates the next options
                    // which require a make
                    this.options.makes = [];
                    this.options.models = [];
                    this.options.trims = [];

                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        year,
                    };

                    const response = await this.getMakeOptionsAsync(request);

                    this.options.makes = response.options.map((makeOption) => ({
                        label: makeOption.value,
                        value: makeOption.value,
                        condition: makeOption.collateralCondition,
                    }));

                    this.innerDetails.make = this.matchDropdownListFormatting(
                        this.innerDetails.make,
                        this.options.makes
                    );

                    this.populatingOptions = false;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async populateModelOptionsAsync(year, make) {
                try {
                    this.populatingOptions = true;

                    // The options for models will change, which invalidates the next options
                    // which require a model
                    this.options.models = [];
                    this.options.trims = [];

                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        year,
                        make,
                    };

                    const response = await this.getModelOptionsAsync(request);

                    this.options.models = response.options.map(
                        (modelOption) => ({
                            label: modelOption.value,
                            value: modelOption.value,
                            condition: modelOption.collateralCondition,
                        })
                    );

                    this.innerDetails.model = this.matchDropdownListFormatting(
                        this.innerDetails.model,
                        this.options.models
                    );

                    this.populatingOptions = false;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async populateTrimOptionsAsync(year, make, model) {
                try {
                    this.populatingOptions = true;

                    // Clear out existing options
                    this.options.trims = [];
                    const currentModel = this.options.models.filter(
                        (x) => x.value === model
                    );
                    const condition = currentModel[0].condition || null;

                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        year,
                        make,
                        model,
                        condition,
                    };

                    const response = await this.getTrimOptionsAsync(request);

                    this.options.trims = response.options.map((trimOption) => ({
                        label: trimOption.value,
                        value: trimOption.value,
                        condition: trimOption.collateralCondition,
                    }));

                    this.innerDetails.trim = this.matchDropdownListFormatting(
                        this.innerDetails.trim,
                        this.options.trims
                    );

                    this.populatingOptions = false;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            /**
             * Matches a given value with the same formatting as the provided dropdown list.
             * Needed because the value we have from one source may not exactly match the formatting of the values
             * used in the dropdowns, which are valid values that come from the vendor.
             *
             * @param {string} value Value to look for
             * @param {object} dropdownList Dropdown list
             * @returns {string} Value if exists, otherwise original value
             */
            matchDropdownListFormatting(value, dropdownList) {
                if (value) {
                    // Perform a case-insensitive search
                    const matchFound = dropdownList.find(
                        (option) =>
                            option.value.toUpperCase() === value.toUpperCase()
                    );

                    if (matchFound) {
                        return matchFound.value;
                    }
                }

                return value;
            },
            async resetMakesOptionsAsync() {
                try {
                    this.innerDetails.make = null;
                    await this.populateMakeOptionsAsync(this.innerDetails.year);
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async resetModelOptionsAsync() {
                try {
                    this.innerDetails.model = null;

                    await this.populateModelOptionsAsync(
                        this.innerDetails.year,
                        this.innerDetails.make
                    );
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async resetTrimOptionsAsync() {
                try {
                    this.innerDetails.trim = null;

                    await this.populateTrimOptionsAsync(
                        this.innerDetails.year,
                        this.innerDetails.make,
                        this.innerDetails.model
                    );
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async searchVinAsync() {
                try {
                    this.showSpinner({ loading: true });

                    // Disable the VIN search so the user doesn't try to do it again without changing anything
                    this.vinSearchEnabled = false;

                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        vin: this.innerDetails.vehicleIdentificationNumber,
                    };

                    const results = await this.getVinDetailsAsync(request);
                    this.vinSearchResults.year = results.year;
                    this.vinSearchResults.make = results.make;
                    this.vinSearchResults.model = results.model;
                    this.vinSearchResults.trims = results.trims ?? [];

                    // If we did not get any results, let the applicant know and stop spinning
                    if (!this.vinSearchResults.year) {
                        this.vinSearchSuccess = false;
                        this.vinSearchError =
                            "We are unable to find your vehicle using the VIN entered.  Please check the VIN and try again.";
                        return this.showSpinner({ loading: false });
                    }

                    const year = this.vinSearchResults.year;
                    const make = this.vinSearchResults.make;
                    const model = this.vinSearchResults.model;

                    this.options.years = [
                        {
                            label: year,
                            value: year,
                        },
                    ];
                    this.options.makes = [
                        {
                            label: make,
                            value: make,
                        },
                    ];
                    this.options.models = [
                        {
                            label: model,
                            value: model,
                        },
                    ];
                    this.options.trims = [];

                    this.innerDetails.year = year;
                    this.innerDetails.make = make;
                    this.innerDetails.model = model;

                    for (const item of this.vinSearchResults.trims) {
                        this.options.trims.push({
                            condition: null,
                            label: item,
                            value: item,
                        });
                    }

                    if (
                        this.vinSearchResults.trims &&
                        this.vinSearchResults.trims.length === 1
                    ) {
                        this.innerDetails.trim = this.vinSearchResults.trims[0];
                    } else {
                        // Clear out any existing trim selection from before the VIN search
                        this.innerDetails.trim = null;
                    }

                    this.vinSearchSuccess = true;
                    return this.showSpinner({ loading: false });
                } catch (error) {
                    this.showSpinner({ loading: false });

                    return this.toast.error(error);
                }
            },
            async validateInput(e) {
                try {
                    this.vinSearchError = this.validateVinKeydown(e);
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async handleInput(optionType) {
                try {
                    switch (optionType) {
                        case CollateralOptionType.YEAR:
                            if (!this.disableMakes) {
                                await this.resetMakesOptionsAsync();
                            }
                            break;

                        case CollateralOptionType.MAKE:
                            if (!this.disableModels) {
                                await this.resetModelOptionsAsync();
                            }
                            break;

                        case CollateralOptionType.MODEL:
                            if (!this.disableTrims) {
                                await this.resetTrimOptionsAsync();
                            }
                            break;

                        default:
                            return;
                    }
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            async handleSubmit() {
                try {
                    const isValid = await this.$refs.observer.validate();

                    if (!isValid) {
                        return this.toast.error("Form is invalid.");
                    }

                    if (!this.vinSearchSuccess && this.isValuationEnabled) {
                        this.clearVin();
                    }

                    return isValid;
                } catch (error) {
                    this.showSpinner({ submitting: false });

                    return this.toast.error(error);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .has-error {
        color: var(--danger);
        margin-bottom: 1rem;
    }
</style>
