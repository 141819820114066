export default (e, excludedCharacters) => {
    let isValid = true;

    if (e.key) {
        const key = e.key;
        const isSingleCharacter = key.length === 1;
        if (isSingleCharacter && key.match(excludedCharacters)) {
            e.preventDefault();
            isValid = false;
        }
    }

    return isValid;
};
