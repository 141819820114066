<template functional>

    <g>
        <polygon points="5.3,6.4 3.5,6.4 2.9,5.7 2.4,6.3 3.2,7.1 5.3,7.1 	"/>
        <path d="M17.3,2.3h-2.1L15,2.5L14.2,1c-0.3-0.6-0.9-1-1.5-1H9H5.3C4.6,0,4,0.4,3.8,1L3,2.5L2.8,2.3H0.8C0.3,2.3,0,2.6,0,3v0.8
            c0,0.4,0.3,0.7,0.7,0.7l0.9,0.2L1.6,4.9c-0.3,0.5-0.4,1-0.4,1.4V9c0,0.4,0.1,0.7,0.4,1v1.3c0,0.4,0.3,0.8,0.8,0.8h2.6
            c0.4,0,0.8-0.3,0.8-0.8v-0.4H4.9v0.4H2.3v-0.8c0.1,0,0.2,0.1,0.4,0.1H9h6.4c0.1,0,0.3,0,0.4-0.1v0.8h-2.6v-0.4h-0.8v0.4
            c0,0.4,0.3,0.8,0.8,0.8h2.6c0.4,0,0.8-0.3,0.8-0.8V10c0.2-0.3,0.4-0.6,0.4-1V6.3c0-0.5-0.1-1-0.4-1.4l-0.1-0.1l0.9-0.2
            c0.4,0,0.7-0.3,0.7-0.7V3C18,2.6,17.7,2.3,17.3,2.3z M4.5,1.3C4.6,1,4.9,0.8,5.3,0.8H9h3.8c0.4,0,0.7,0.3,0.8,0.5l1,1.9l-0.1,0.2
            l-0.9,0.3H9H4.6L3.6,3.4L3.5,3.2L4.5,1.3z M17.3,3.8l-2.1,0.5l0.7,1c0.2,0.4,0.3,0.7,0.3,1V9c0,0.4-0.3,0.8-0.8,0.8H9H2.6
            C2.2,9.8,1.9,9.4,1.9,9V6.3c0-0.3,0.1-0.7,0.3-1l0.7-1l-2-0.5l-0.1,0V3h1.7l0.7,1.1l1.3,0.4H9h4.6l1.3-0.4L15.6,3h1.7V3.8z"/>
        <polygon points="14.5,6.4 12.8,6.4 12.8,7.1 14.8,7.1 15.6,6.3 15.1,5.7 	"/>
        <rect x="5.3" y="8.3" width="7.5" height="0.8"/>
    </g>

</template>





<script>

    export default {

        name: 'CarIcon'

    }

</script>