<template>
	<ValidationProvider :rules="validationRules" :name="name || label" :vid="validationId" v-slot="{ errors }" slim>
		<StsMultiStepInput
			v-model="innerValue"
			:label="label"
			:type="type"
			:placeholder="placeholder"
			:disabled="disabled"
			:error="errors[0]"
			:isOptional="isOptional"
			:disableAutocomplete="disableAutocomplete"
			:isSmall="isSmall"
			:id="id"
			:hideLabel="hideLabel"
			:steps="steps"
		/>
	</ValidationProvider>
</template>




<script>
import { ValidationProvider } from 'vee-validate';

import { StsMultiStepInput } from '@/components/SwitchThink/form';

export default {
	name: 'VMultiStepInput',
	components: {
		ValidationProvider,
		StsMultiStepInput
	},
	props: {
		value: {
			type: String,
			required: false,
			default: ''
		},
		label: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		isOptional: {
			type: Boolean,
			required: false,
			default: false
		},
		disableAutocomplete: {
			type: Boolean,
			required: false
		},
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		},
		id: {
			type: [String, Number],
			required: false
		},
		hideLabel: {
			type: Boolean,
			required: false,
			default: false
		},
		steps: {
			type: Number,
			required: true
		},
		name: {
			type: String,
			required: false,
			default: ''
		},
		validationRules: {
			type: [String, Object],
			required: false,
			default: ''
		},
		validationId: {
			type: String,
			required: false,
			default: ''
		}
	},
	computed: {
		innerValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
};
</script>