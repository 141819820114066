<template>
	<div :class="['display-block', { 'is-column': isColumn }]">
		<p class="title">
			<span class="title-underline">{{ title }}</span>
			<sup class="superscript" v-if="superscript">{{superscript}}</sup>
		</p>
		<p v-if="formatter">{{ formattedDescription }}</p>
		<p v-else>{{ description }}</p>
	</div>
</template>





<script>
import { formatMoney, formatNumber, formatPhoneNumber, formatRate } from '@/utils';

export default {
	name: 'ListDisplay',
	props: {
		title: {
			type: String,
			required: true
		},
		description: {
			required: true,
			validator: value => value === null || typeof value === 'string' || typeof value === 'number'
		},
		formatter: {
			type: String,
			validator: value =>
				!value || value === 'phone' || value === 'money' || value === 'number' || value === 'rate'
		},
		isColumn: {
			type: Boolean,
			required: false,
			default: false
        },
        superscript: {
            type: String,
            required: false,
            default: false
        }
	},
	computed: {
		formattedDescription() {
			if (this.formatter === 'phone') {
				return formatPhoneNumber(this.description);
			}
			if (this.formatter === 'money') {
				return formatMoney(this.description);
			}
			if (this.formatter === 'number') {
				return formatNumber(this.description);
			}
			if (this.formatter === 'rate') {
				return formatRate(this.description);
			}

			return this.description;
		}
	}
};
</script>





<style lang="scss" scoped>
.display-block:not(:last-child) {
	margin-bottom: 1rem;
}
.display-block .title {
	margin-bottom: 0;
}

.blank-input {
	font-size: 1rem;
	background: none;
	outline: 0;
	border: 0;
	padding: 0;
	margin: 0;
}

@media screen and (min-width: 850px) {
	.display-block:not(:last-child) {
		margin-bottom: 0;
	}

	.display-block p {
		text-align: center;
		color: #003a79;
		margin-bottom: 0;
		font-size: 0.85rem;
		font-weight: 700;
	}

	.display-block .title {
		color: #333333;
		font-weight: 400;
	}

    .title-underline {
        text-decoration: underline;
    }
}
</style>