import { axios } from "@/utils";

/**
 * @typedef {object} uiTextValue
 * @property {number} uiTextComponentTypeId
 * @property {string} value
 */

/**
 * @param {number} uiLanguageTypeId The id for the UI language.
 * @returns {Promise<uiTextValue[]>} The text values for the application.
 */
const getUiTextValues = async (uiLanguageTypeId) => {
    const {
        data: { uiTextValues },
    } = await axios.get(`/api/UiTextValues/${uiLanguageTypeId}`);

    return uiTextValues;
};

export default { getUiTextValues };
