<template>
	<Portal to="secondary-modal-content">
		<StsConfirm
			:message="message"
			:cancelText="cancelText"
			:acceptText="acceptText"
			:isSmall="isSmall"
			@close="$emit('close')"
			@accept="$emit('accept')"
		/>
	</Portal>
</template>




<script>
import { Portal } from 'portal-vue';
import { mapActions } from 'vuex';

import { StsButton, StsConfirm } from '@/components/SwitchThink';

export default {
	name: 'StsConfirmModal',
	components: {
		Portal,
		StsButton,
		StsConfirm
	},
	props: {
		title: {
			type: String,
			required: true
		},
		message: {
			type: String,
			required: true
		},
		acceptText: {
			type: String,
			required: false,
			default: 'Confirm'
		},
		cancelText: {
			type: String,
			required: false,
			default: 'Cancel'
		},
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		},
		preventClose: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	created() {
		this.triggerModal({
			isOpen: true,
			title: this.title,
			smallTitle: true,
			preventClose: this.preventClose
		});
	},
	beforeDestroy() {
		return this.triggerModal({ isOpen: false });
	},
	methods: {
		...mapActions('modal', { triggerModal: 'triggerSecondaryModal' })
	}
};
</script>