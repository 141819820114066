<template>
	<nav>
		<section class="stretch-section">
			<div class="back-container" v-if="backRoute">
				<a v-if="linkType === 'external'" class="back-button" :href="backRoute" :title="backTitle">
					<BaseIcon :name="backTitle" height="40" width="40">
						<ActionArrow />
					</BaseIcon>
				</a>

				<router-link
					v-else
					class="back-button"
					:to="linkType === 'internal' ? { name: backRoute } : backRoute"
					:title="backTitle"
				>
					<BaseIcon :name="backTitle" height="40" width="40">
						<ActionArrow />
					</BaseIcon>
				</router-link>
			</div>
		</section>

		<PortalTarget name="nav-content" />

		<StsButtonWrapper v-if="!hideHelp" class="help-wrapper" title="Contact Us" @click="$emit('contact')">
			<BaseIcon name="Contact" height="40" width="40" title="Contact Us">
				<Phone />
			</BaseIcon>
		</StsButtonWrapper>

		<StsButtonWrapper v-if="authenticated" class="help-wrapper" title="Logoff" @click="$emit('logout')">
			<BaseIcon name="LogoutButton" height="40" width="40" viewBox="0 0 53.94 43.74" title="Logoff">
				<Logout />
			</BaseIcon>
		</StsButtonWrapper>
	</nav>
</template>





<script>
import { PortalTarget } from 'portal-vue';

import { StsButtonWrapper } from '@/components/SwitchThink';
import { ActionArrow, BaseIcon, Phone, Logout } from '@/components/SwitchThink/icons';

export default {
	name: 'Nav',
	components: {
		PortalTarget,
		StsButtonWrapper,
		ActionArrow,
		BaseIcon,
		Phone,
		Logout
	},
	props: {
		backRoute: {
			type: [Object, String],
			required: false
		},
		backTitle: {
			type: String,
			required: false
		},
		hideHelp: {
			type: Boolean,
			required: false
		},
		authenticated: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		linkType() {
			if (typeof this.backRoute === 'object') {
				return 'object';
			}
			if (this.backRoute.includes('http')) {
				return 'external';
			}
			return 'internal';
		}
	}
};
</script>





<style lang="scss" scoped>
nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	background-color: #fff;
	display: flex;
	gap: 1rem;
	border-bottom: 2px solid var(--tertiary);
	padding: 0 0.5rem;

	.stretch-section,
	::v-deep .help-wrapper {
		padding: 0.25rem 0;
		color: var(--primary);
	}

	section {
		flex: 1;
	}

	.back-container {
		height: 100%;
		width: 100%;

		a,
		::v-deep button {
			color: var(--primary);
		}

		svg {
			height: 100%;
			width: auto;
		}
	}
}
</style>