export default {
	NONE: 0,
    TCI_SELLER_INFORMATION: 1,
    TCI_PROOF_OF_INSURANCE: 2,
    TCI_LIEN_HOLDER_INFORMATION: 3,
    NO_ACTIVE_STIPULATIONS: 4,
    BOAT_COLLATERAL: 5,
    AUTO_COLLATERAL: 6,
    RV_COLLATERAL: 7,
    MOTORCYCLE_COLLATERAL: 8,
	0: 'None',
    1: 'TCISellerInformation',
    2: 'TCIProofOfInsurance',
    3: 'TCILienholderInformation',
    4: 'NoActiveStipulations',
    5: 'BoatCollateral',
    6: 'AutoCollateral',
    7: 'RVCollateral',
    8: 'MotorcycleCollateral'
};
