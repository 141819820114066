import { axios } from '@/utils';

const getStipulations = async (applicationId, applicantToken) => {
  try {
      const { data } = await axios.get(`/api/Application/${applicationId}/ApplicantToken/${applicantToken}/Stipulations`);

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * @typedef {object} stipulation Stipulation
 * @property {string} stipulationId Vendor Stipulation Id
 * @property {string} stipulationToken Stipulation Token
 * @property {string} title Stipulation Title
 * @property {int} type Stipulation Type
 * @property {int} status Stipulation Status
 */

/**
 * @typedef {object} stipulationUpdateViewModelRequest
 * @property {number} applicationId Application ID
 * @property {stipulation} stipulation Stipulation
 */

/**
 * @param {stipulationUpdateViewModelRequest} request The request to update a stipulation.
 * @returns {Promise<boolean>} True if the stipulation was created successfully.
 */
const updateStipulation = async request => {
  const { data } = await axios.post("/api/Application/Stipulation", request);

    return data;
};

export default {
  getStipulations,
  updateStipulation
};

export { getStipulations, updateStipulation };
