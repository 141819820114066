export default {
    params: ['type'],
    validate(value, { type }) {

        const licenseError = "The {_field_} field must contain a valid license";
        
        switch(type) {

            case 'AZ':
                const az = /^[a-zA-Z]\d{8}$/g; // Starts with a letter and ends in 8 digits
                return az.test(value) || licenseError;

            case 'CA':
                const ca = /^[a-zA-Z]\d{7}$/g; // Starts with a letter and ends in 7 digits
                return ca.test(value) || licenseError;
                
            case 'TX':
                const tx = /^\d{8}$/g; // Must be 8 digits
                return tx.test(value) || licenseError;
                
            default:
                return true;

        };

    }
};