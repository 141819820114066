import store from '@/store';

export default async incomingRoute => {

    try {
      
        const redirect = await store.dispatch('routeEnforcer/enforceRoute', incomingRoute);

        return redirect ? redirect : null;

    } catch (error) {
        
        throw error;

    };

};