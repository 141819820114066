<template>
	<section class="session-expired">
		<StsButton
			title="Return to Login"
			lowercase
			fullwidth
			small
			style="margin-bottom: 1rem"
			@click="$emit('restart')"
		/>

		<StsButtonLink tag="a" :title="backTitle" type="hollow" lowercase fullwidth small :url="backUrl" target="" />
	</section>
</template>





<script>
import { StsButton, StsButtonLink } from '@/components/SwitchThink';

export default {
	name: 'SessionExpired',
	components: {
		StsButton,
		StsButtonLink
	},
	props: {
		backTitle: {
			type: String,
			required: true
		},
		backUrl: {
			type: String,
			required: true
		}
	}
};
</script>





<style lang="scss" scoped>
.session-expired {
	display: flex;
	flex-direction: column;

	p {
		color: var(--primary);
		font-weight: 300;
		transition: color 0.5s;
	}
}
</style>