<template>
	<section class="section-header">
		<p class="title" :style="{ 'margin-right': tooltip ? '0.5rem' : 0 }">{{ title }}</p>
		<StsTooltip v-if="tooltip" v-bind="tooltip" />
		<svg>
			<line x1="0" y1="0" x2="1000" y2="0" stroke="black" stroke-width="1" />
		</svg>
	</section>
</template>




<script>
export default {
	name: 'SectionHeader',
	components: {
		StsTooltip: () => import('@/components/SwitchThink').then(({ StsTooltip }) => StsTooltip)
	},
	props: {
		title: {
			type: String,
			required: true
		},
		tooltip: {
			type: Object,
			required: false,
			validator: ({ title, description }) => title && description,
			default: null
		}
	}
};
</script>




<style lang="scss" scoped>
.section-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1rem;

	.title {
		font-weight: 300;
		font-size: 1.25rem;
		font-style: normal;
		color: #003a79;
	}

	svg {
		margin-left: 0.5rem;
		height: 1px;
		width: 100%;
		flex: 1;
	}
}
</style>