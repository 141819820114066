<template functional>

    <g>
        <g>
            <path d="M16.3,14.4c0,0.3-0.3,0.7-0.6,0.7h0H1.5c-0.4,0-0.7-0.3-0.7-0.7V8.3c0-0.4,0.3-0.7,0.7-0.7h9V7l-9,0
                c-0.8,0-1.4,0.6-1.4,1.4v6.1c0,0.8,0.6,1.4,1.4,1.4h14.1c0.7,0,1.3-0.6,1.3-1.3v0V11h-0.7V14.4z"/>
            <rect x="4.9" y="12.5" width="3.2" height="0.7"/>
            <rect x="1.7" y="13.8" width="6.6" height="0.7"/>
            <path d="M17.3,5.5L17,5.3l0.6-0.8c0.3-0.3,0.3-0.7,0.3-1.1c-0.1-0.3-0.3-0.6-0.7-0.8c-0.8-0.5-1.4-0.4-1.9,0.3
                L10,10.7c-0.5,0.7-0.7,2.8-0.8,3l-0.1,0.7l0.6-0.3c0.2-0.1,2.1-1.1,2.5-1.8l4.4-6.4L16.9,6C17,6.2,17,6.4,16.9,6.5l0,0l-2.3,3.4
                l0.6,0.4l2.3-3.4C17.9,6.4,17.7,5.8,17.3,5.5L17.3,5.5z M15.9,3.3C16,3,16.2,2.9,16.3,2.9c0.2,0,0.3,0.1,0.5,0.2
                c0.2,0.2,0.3,0.3,0.4,0.4c0,0.2-0.1,0.4-0.2,0.5l-0.6,0.9l-1.1-0.8L15.9,3.3z M11.6,11.9c-0.2,0.3-1,0.8-1.7,1.2
                c0.1-0.8,0.3-1.7,0.6-2l4.4-6.4L16,5.5L11.6,11.9z"/>
            <path d="M1.7,11.2v0.3c0,0.6,0.5,1,1,1v0.7h0.7v-0.7c0.6,0,1-0.5,1-1v-0.1c0-0.4-0.3-0.8-0.7-0.9l-1.1-0.3
                c-0.1,0-0.2-0.1-0.2-0.3V9.8c0-0.2,0.2-0.3,0.3-0.3h0.7c0.2,0,0.3,0.2,0.3,0.3v0.3h0.7V9.8c0-0.6-0.5-1-1-1V8.1H2.8v0.7
                c-0.6,0-1,0.5-1,1v0.1c0,0.4,0.3,0.8,0.7,0.9l1.1,0.3c0.1,0,0.2,0.1,0.2,0.3v0.1c0,0.2-0.2,0.3-0.3,0.3H2.8
                c-0.2,0-0.3-0.2-0.3-0.3v-0.3L1.7,11.2L1.7,11.2z"/>
        </g>
    </g>

</template>





<script>

    export default {

        name: 'CheckingIcon'

    }

</script>