<template functional>

    <g>
        <polygon points="17.7,5.1 9,1.1 0.3,5.1 0,4.6 9,0.5 18,4.6 	"/>
        <rect x="0.1" y="6.1" width="17.7" height="0.6"/>
        <polygon points="1.2,15.7 0.6,15.7 0.6,7.8 3.2,7.8 3.2,14.1 2.6,14.1 2.6,8.4 1.2,8.4 	"/>
        <rect x="2.1" y="15.1" width="8.1" height="0.6"/>
        <rect x="0.5" y="16.7" width="9.7" height="0.6"/>
        <path d="M9,5.5C8.2,5.5,7.5,4.9,7.5,4S8.2,2.5,9,2.5c0.8,0,1.5,0.7,1.5,1.5S9.8,5.5,9,5.5z M9,3.1
            C8.5,3.1,8.1,3.5,8.1,4S8.5,4.9,9,4.9c0.5,0,0.9-0.4,0.9-0.9S9.5,3.1,9,3.1z"/>
        <path d="M6.5,14.1H5.9V8.9c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v5.2H4.3V8.9c0-0.6,0.5-1.1,1.1-1.1
            s1.1,0.5,1.1,1.1V14.1z"/>
        <path d="M10.1,14.1H9.5V8.9c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v5.2H7.9V8.9c0-0.6,0.5-1.1,1.1-1.1
            c0.6,0,1.1,0.5,1.1,1.1V14.1z"/>
        <path d="M14.1,15.9h-0.4c-0.9,0-1.6-0.7-1.6-1.6v-0.6h0.6v0.6c0,0.5,0.4,1,1,1H15c0.5,0,1-0.4,1-1V14
            c0-0.4-0.3-0.8-0.7-0.9l-2.1-0.7c-0.6-0.2-1.1-0.8-1.1-1.5v-0.4c0-0.9,0.7-1.6,1.6-1.6c0,0,0.4,0,0.4,0V7.3h0.6c0,0,0,1.6,0,1.6
            h0.3c0.9,0,1.6,0.7,1.6,1.6v0.6h-0.6v-0.6c0-0.5-0.4-1-1-1h-1.3c-0.5,0-1,0.4-1,1v0.4c0,0.4,0.3,0.8,0.7,0.9l2.1,0.7
            c0.6,0.2,1.1,0.8,1.1,1.5v0.4c0,0.9-0.7,1.6-1.6,1.6c0,0-0.3,0-0.3,0v1.6h-0.6C14.1,17.5,14.1,15.9,14.1,15.9z"/>
    </g>

</template>





<script>

    export default {

        name: 'SavingsIcon'

    }

</script>