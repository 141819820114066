<template functional>

    <g>
        <path style="fill: #70B603;" d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,1C4.6,1,1,4.6,1,9c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8
            C17,4.6,13.4,1,9,1z"/>
        <g>
            <ellipse style="fill: #70B603;" cx="15.1" cy="2.5" rx="1.9" ry="1.8"/>
            <path style="fill: #FFFFFF;" d="M15.1,4.4c-1.1,0-2-0.9-2-1.9s0.9-1.9,2-1.9s2,0.9,2,1.9S16.2,4.4,15.1,4.4z M15.1,0.7c-1,0-1.8,0.8-1.8,1.7
                s0.8,1.7,1.8,1.7c1,0,1.8-0.8,1.8-1.7S16.1,0.7,15.1,0.7z"/>
        </g>
        <path style="fill: #FFFFFF;" d="M16.3,1.9c0.1,0.1,0.2,0.2,0.1,0.4c0,0-0.1,0.1-0.1,0.1c-0.4,0.4-0.9,0.8-1.3,1.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0
            c-0.2-0.3-0.5-0.5-0.7-0.8C14,2.6,14,2.6,14,2.5c0-0.1,0.1-0.2,0.2-0.2c0,0,0.2-0.2,0.3-0.1c0.2,0.2,0.3,0.3,0.5,0.5c0,0,0,0,0,0
            s0,0,0.1,0c0.2-0.2,0.6-0.5,0.9-0.8C16,1.7,16.2,1.7,16.3,1.9C16.3,1.8,16.3,1.9,16.3,1.9z"/>
        <path style="fill: #203E77;" d="M11.2,14.5h-0.7v-0.9l0.1-0.1l-0.1-0.1v-0.6l0.1-0.1l-0.1-0.1v-0.2c-0.4-0.2-0.6-0.6-0.6-1
            c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.4-0.3,0.6-0.3V10c-0.1,0-0.1,0-0.2,0c-0.5,0-0.8-0.3-1.1-0.5c-0.1,0-0.1-0.1-0.2-0.1
            C9.4,9.3,9.2,9.2,9,9.1C8.7,9.1,8.4,9,8.2,8.8c0,0-0.1,0-0.1-0.1C7.9,8.6,7.5,8.3,7.4,8.1C7.3,8.1,7.1,8,7,8v0.4H5.4V5H7v0.2h1.6
            c0.5,0,1.5,0.3,2.4,0.6c0.1,0,0.1,0,0.2,0.1c0.4,0.1,1.4,1.4,1.5,1.5c0.1,0.2,0.1,0.5-0.2,1.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
            c0,0.3-0.1,0.8-0.6,0.8c-0.1,0-0.2-0.1-0.4-0.2c0,0,0,0,0,0v0.4c0.4,0.1,0.7,0.3,0.9,0.7c0.1,0.3,0.1,0.6,0,0.9
            c-0.1,0.3-0.3,0.5-0.5,0.6V14L11.2,14.5z M10.9,14.1H11l0.3-0.3v-1.7l0.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0-0.6
            c-0.1-0.2-0.3-0.4-0.5-0.4v0.6h-0.4v-0.6c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.3,0.2,0.6,0.5,0.7l0.1,0.1v0.3
            l0.3,0.3L10.9,13v0.2l0.3,0.3l-0.3,0.3V14.1z M11.4,9.2c0.1,0.2,0.2,0.4,0.3,0.4c0.1,0,0.1-0.1,0.2-0.4c0-0.1,0-0.2,0-0.3
            c0,0,0-0.1,0-0.2c0.2-0.7,0.2-1.1,0.2-1.2c-0.1-0.2-1-1.2-1.2-1.3c0,0-0.1,0-0.2-0.1C10.4,6.1,9,5.6,8.6,5.6H7v2
            c0.3,0,0.5,0.1,0.5,0.1l0.1,0l0,0.1C7.8,8,8.1,8.3,8.3,8.4c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.4,0.2,0.6,0.3C9.3,8.8,9.5,8.9,9.7,9
            c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.2,0.5,0.4,0.8,0.4c0.1,0,0.3,0,0.3-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1C11,9,10.8,8.9,10.7,8.8
            l0,0c0,0-0.1,0-0.1-0.1C10.3,8.6,10,8.5,9.8,8.1C9.7,7.9,9.7,7.7,9.7,7.5l0,0C9.8,7.2,10.1,7,10.4,7c0.2,0,0.5,0.3,1,0.7
            c0.1,0,0.1,0.1,0.1,0.1L11.6,8l-0.1,0.1c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.2,0,0.3C11.4,9.1,11.4,9.1,11.4,9.2
            C11.4,9.2,11.4,9.2,11.4,9.2z M10.8,8.4c0.1,0,0.1,0.1,0.2,0.1c0-0.2,0.1-0.3,0.1-0.4c0,0,0,0,0,0c-0.4-0.4-0.7-0.6-0.8-0.6
            c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.3,0.5,0.4C10.7,8.4,10.8,8.4,10.8,8.4z M5.8,8h0.8V5.4H5.8V8z M12.2,7.6
            L12.2,7.6L12.2,7.6z M8.4,13.9H7.8l-0.5-0.5l0.6-0.6l0.3-0.1v-0.2L8.8,12c0-0.1,0-0.2,0-0.3c0-0.7,0.5-1.2,1.2-1.2l0,0.4
            c-0.4,0-0.8,0.4-0.8,0.8c0,0.1,0,0.2,0.1,0.3l0,0.1l-0.7,0.7v0.4l-0.5,0.1l-0.2,0.2l0.1,0.1h0.4l1.2-1.2l0.1,0
            c0.2,0.1,0.4,0.1,0.6,0l0.1,0.4c-0.3,0.1-0.5,0.1-0.8,0L8.4,13.9z"/>
    </g>

</template>





<script>

    export default {

        name: 'KeysSelected'

    }

</script>