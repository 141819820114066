export default {

    namespaced: true,
    state: {

        currentHeader: null

    },
    mutations: {

        setData(state, { objName, data }) { state[objName] = data; }

    }

};