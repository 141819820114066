<template functional>
	<h1
		v-bind="data.attrs"
		:class="[data.class, data.staticClass, { small: props.isSmall }]"
		:style="[data.style, data.staticStyle]"
	>
		<slot />
	</h1>
</template>





<script>
export default {
	name: 'Title',
	props: {
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		}
	}
};
</script>





<style scoped>
h1 {
	font-size: 2rem;
	line-height: 1;
	font-weight: 400;
	text-align: center;
	color: var(--headerCopy);
	text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
	margin-top: 0;
	margin-bottom: 1rem;
}

.small {
	font-size: 1.25rem;
}

@media only screen and (min-width: 641px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}
	.small {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

@media only screen and (min-width: 1526px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}
	.small {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

@media only screen and (min-width: 1921px) {
	h1 {
		font-size: 2.75rem;
		line-height: 3.25rem;
	}
	.small {
		font-size: 2rem;
		line-height: 2.5rem;
	}
}

@media only screen and (min-width: 2048px) {
	h1 {
		font-size: 3rem;
		line-height: 3.5rem;
	}
	.small {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}
}
</style>