<template functional>

    <g>
        <path class="st0" d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9
            c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
        <path class="st1" d="M15.3,12.8h-0.7l0-3.2h-0.4V8.7h-1v0.4h0.6v0.4h-1V10h1.5l0,2.8H9.3l0-2.8c0-0.1,0-0.1-0.1-0.1
            c0,0-0.1-0.1-0.2-0.1l-1.7,0c-0.1,0-0.2,0.1-0.2,0.2l0,2.8H4.5v-2.6L8,6.6l3.6,3.6c0,0,0.1,0.1,0.1,0.1s0.1,0,0.1-0.1l0.8-0.8
            c0.1-0.1,0.1-0.2,0-0.3L8.2,4.5c-0.1-0.1-0.2-0.1-0.3,0L6.4,6V5.3c0-0.1-0.1-0.2-0.2-0.2H4.9c-0.1,0-0.2,0.1-0.2,0.2v2.4L3.3,9.1
            c-0.1,0.1-0.1,0.2,0,0.3l0.8,0.8v2.6H3.5v0.4h3.9h1.7h5.1v0l0.2,0h0.9V12.8z M5.1,5.5h0.8v1L5.1,7.3V5.5z M8,5l4.3,4.3l-0.5,0.5
            L8.2,6.2c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1L4.3,9.8L3.7,9.2L8,5z M7.6,12.8l0-2.6l1.2,0l0,2.6H7.6z M11.1,11.9v-0.6h-0.4
            v0.6h-0.4v-1h1.2v1H11.1z M13,11.9v-0.6h-0.4v0.6h-0.4v-1h1.2v1H13z M5.9,11.9v-0.6H5.5v0.6H5.1v-1h1.2v1H5.9z"/>
    </g>

</template>




<script>

    export default {

        name: 'ManufacturedIcon'
        
    };

</script>




<style scoped>

	.st0{fill:#2391EC;}
	.st1{fill:#203E77;}

</style>