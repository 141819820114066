<template>
	<footer class="wizard-footer">
		<StsButtonWrapper :disabled="activeTab === 0" class="footer-button" @click="$emit('previous')">
			<BaseIcon v-show="activeTab !== 0" name="Previous" height="50" width="50">
				<ActionArrow />
			</BaseIcon>
		</StsButtonWrapper>

		<section class="breadcrumbs">
			<span
				v-for="tab in tabLength"
				:key="tab"
				:class="{
					previous: tab < activeTab + 1,
					current: tab === activeTab + 1
				}"
			></span>
		</section>

		<StsButtonWrapper :disabled="disableNext" class="footer-button flip" @click="$emit('next')">
			<BaseIcon v-show="tabLength !== activeTab + 1" name="Next" height="50" width="50">
				<ActionArrow />
			</BaseIcon>
		</StsButtonWrapper>
	</footer>
</template>





<script>
import { ActionArrow, BaseIcon } from '@/components/SwitchThink/icons';
import { StsButtonWrapper } from '@/components/SwitchThink';

export default {
	name: 'WizardFooter',
	components: {
		ActionArrow,
		BaseIcon,
		StsButtonWrapper
	},
	props: {
		tabLength: {
			type: Number,
			required: true
		},
		activeTab: {
			type: Number,
			required: true
		},
		nextDisabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		disableNext() {
			return this.nextDisabled || this.tabLength === this.activeTab + 1;
		}
	}
};
</script>





<style lang="scss" scoped>
.wizard-footer {
	display: flex;
	margin-top: 1.5rem;
	color: var(--primary);
	align-items: center;
	text-align: center;

	.breadcrumbs {
		flex: 1;

		span {
			height: 0.6875rem;
			width: 0.6875rem;
			background: var(--lightGrey-50);
			display: inline-block;
			border-radius: 100%;
			margin-right: 0.25rem;

			&:last-child {
				margin-right: 0;
			}

			&.previous {
				background: var(--darkGrey);
			}

			&.current {
				background: var(--secondary);
			}
		}
	}

	::v-deep .footer-button {
		color: var(--primary);
		height: 3.125rem;
		width: 3.125rem;

		&:disabled {
			color: var(--lightGrey-50);
			cursor: default;
		}

		&.flip {
			transform: rotate(180deg);
		}
	}
}
</style>