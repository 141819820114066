import { axios } from '@/utils';

/**
 * @typedef {object} insurance Insurance
 * @property {string} insuranceAgency Insurance Agency
 * @property {string} policyNumber Policy Number
 * @property {string} agentName Agent Name
 */

/**
 * @typedef {object} insuranceCreateViewModelRequest
 * @property {number} taskId Task ID
 * @property {insurance} insurance Insurance
 */

/**
 * @param {insuranceCreateViewModelRequest} request The request to create a new proof of insurance.
 * @returns {Promise<boolean>} True if the proof of insurance was created successfully.
 */
const createInsurance = async request => {
	const { created } = await axios.post('/api/Insurance', request);

	return created;
};

/**
 * @returns {Promise<insuranceGetViewModelResponse>} Containing options required for form to load.
 */

const getInsurance = async (applicationId) => {
    const {
        data
    } = await axios.get(`/api/Insurance/${applicationId}/`);

    return data;
}

export default { createInsurance, getInsurance};
