<template functional>
	<div id="ncua-link-container">
		<a class="ncua-link" href="https://www.ncua.gov/" title="Visit the NCUA Website" target="_blank">
			Federally Insured by NCUA
		</a>
	</div>
</template>

<script>
export default {
	name: 'NCUALink'
};
</script>

<style lang="scss" scoped>
#ncua-link-container {
	text-align: center;

	.ncua-link {
		display: inline-block;
		padding: 0.5rem;
		font-size: 1.5rem;
		color: var(--primary);
		text-decoration: none;
		text-align: center;
		font-weight: 300;


		&:after {
			display: block;
			content: '';
			border-bottom: solid 1px var(--primary);
			transform: scaleX(0);
			transition: transform 250ms ease-in-out;
		}

		&:hover:after {
			transform: scaleX(1);
		}
	}
}
</style>