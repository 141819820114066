import { axios } from '@/utils';

const getApplicationDetails = async applicationId => {
    const {
        data: {
            application
        }
    } = await axios.get(`/api/Application/${applicationId}`);

    return application ;
};

const getApplicationIds = async applicantId => {
    const {
        data: {
            applicationIds
        }
    } = await axios.get(`/api/Applicant/${applicantId}/Applications`);

    return { applicationIds };
};

const getApplicationStatus = async applicationId => {
    const {
        data 
    } = await axios.get(`/api/Application/${applicationId}/Status`);

    return data;
};

const getLoanDetailOverrides = async applicationId => {
    const {
        data: {
            overrides
        }
    } = await axios.get(`/api/Application/${applicationId}/LoanDetailOverrides`);

    console.log(overrides);
    return overrides;
};

const getApplicationDisclosures = async applicationId => {
    const {
        data: {
            disclosures
        }
    } = await axios.get(`/api/Application/${applicationId}/Disclosures`);
    disclosures.sort((x, y) => x.orderNumber - y.orderNumber);
    return disclosures;
};

export default {
    getApplicationDetails,
    getApplicationIds,
    getApplicationStatus,
    getLoanDetailOverrides,
    getApplicationDisclosures
};

export {
    getApplicationDetails,
    getApplicationIds,
    getApplicationStatus,
    getLoanDetailOverrides,
    getApplicationDisclosures
};