<template functional>
	<path
		d="M13.1,15.8c0,0-0.1,0-0.1,0l-8,0c-1,0-2-0.9-2.2-1.8l-0.2-0.5h2.3V9.4L0.4,4.9c-0.5-0.5-0.5-1.3-0.1-1.8L0.4,3
        c0.5-0.4,1.3-0.4,1.8,0.2L4.9,6V1.9C4.9,0.9,5.8,0,6.8,0h9.3c0.1,0,0.1,0,0.2,0l0,0c1,0.1,1.7,0.9,1.7,1.9v0.8h-2.3V1.9h1.5
        c0-0.6-0.5-1.1-1.1-1.2c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.2-0.4,0.5-0.4,0.9v11.9c0.1,1-0.7,1.9-1.7,1.9C13.1,15.8,13.1,15.8,13.1,15.8
        z M12.8,15H13c0.1,0,0.1,0,0.1,0c0.6,0,1-0.5,1-1.1l0-12l0,0c0-0.4,0.2-0.8,0.4-1.1H6.8c-0.6,0-1.2,0.5-1.2,1.2v4.8l2.6,2.6
        c0.5,0.6,0.8,1.4,0.9,2.2l0,0.4l-0.4,0c-0.8,0-1.6-0.4-2.2-0.9l-0.9-0.9v3.3h5.8l0.1,0.3C11.7,14.4,12.3,15,12.8,15z M3.7,14.2
        C4.1,14.7,4.6,15,5,15h6.4c-0.2-0.2-0.3-0.5-0.4-0.8H3.7z M2.3,5.7l4.9,4.9c0.3,0.3,0.8,0.5,1.2,0.6C8.2,10.7,8,10.2,7.7,9.9L2.9,5
        L2.3,5.7z M0.9,3.6C0.7,3.8,0.7,4.2,0.9,4.3l0.8,0.8l0.6-0.6L1.6,3.7C1.4,3.5,1.1,3.5,0.9,3.6z M13,11.9h-2.3v-0.8H13V11.9z
        M10.3,11.9H9.5v-0.8h0.8V11.9z M12.2,6.9H6.8V6.2h5.4V6.9z M13,5.4H6.8V4.6H13V5.4z M13,3.9H6.8V3.1H13V3.9z"
	/>
</template>





<script>
export default {
	name: 'ApplicationIcon'
};
</script>