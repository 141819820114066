<template functional>

    <g>
        <path style="fill:#2391EC;" d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9
            c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
        <path style="fill:#203E77;" d="M12,10.2c-0.1,0-0.2,0-0.3-0.1c-0.4-0.4-1.2-1.1-1.7-1.7C9.8,8.2,9.7,8,9.6,7.9C9.5,7.8,8.9,7.9,8.6,8
            C8.4,8,8.2,8.1,8,8.1C7.5,8.1,7,7.8,6.8,7.5C6.7,7.3,6.7,7.1,6.8,7C7,6.7,9.2,6,9.4,6.1c0.1,0,0.2,0.1,0.4,0.2
            c0.2,0.1,0.7,0.3,0.9,0.3c0.3-0.2,0.5-0.4,0.8-0.6l0.3,0.3c-0.3,0.2-0.6,0.5-0.8,0.7c0,0-0.1,0-0.1,0c-0.2,0-0.5-0.1-1.1-0.3
            C9.5,6.6,9.4,6.5,9.3,6.5c0,0,0,0,0,0C9.1,6.5,7.4,7,7.2,7.2c0,0,0.1,0.1,0.2,0.2C7.5,7.6,7.7,7.7,8,7.7c0.2,0,0.3-0.1,0.5-0.1
            c0.6-0.1,1.1-0.2,1.3,0c0.1,0.1,0.3,0.3,0.5,0.5c0.5,0.6,1.3,1.3,1.7,1.7c0.2,0.1,0.9-0.7,1.5-1.7l0.3,0.2
            C13.7,8.6,12.7,10.1,12,10.2C12,10.2,12,10.2,12,10.2z"/>
        <rect x="8.6" y="11.3" transform="matrix(0.7125 -0.7017 0.7017 0.7125 -5.7429 9.569)" style="fill:#203E77;" width="0.4" height="1"/>
        <rect x="9.5" y="10.3" transform="matrix(0.733 -0.6802 0.6802 0.733 -4.9896 9.5482)" style="fill:#203E77;" width="0.4" height="1.7"/>
        <rect x="10.4" y="9.4" transform="matrix(0.7193 -0.6947 0.6947 0.7193 -4.2944 10.2746)" style="fill:#203E77;" width="0.4" height="2"/>
        <path style="fill:#203E77;" d="M7.1,12.5L7.1,12.5c-0.2,0-0.3-0.1-0.4-0.2h0l0,0l-2.1-2.1c-0.2-0.2-0.2-0.6,0-0.9L4.9,9c0,0,0,0,0,0
            C5.2,8.7,5.6,8.8,5.8,9C6,9.1,6,9.3,6,9.5c0,0,0,0,0,0c0.2,0,0.3,0.1,0.4,0.2c0,0,0,0,0,0c0.1,0.1,0.2,0.3,0.2,0.4
            c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0.1c0.3,0.2,0.4,0.6,0.2,0.9c0,0,0,0,0,0l-0.4,0.4
            C7.4,12.4,7.2,12.5,7.1,12.5z M5.3,9.2c0,0-0.1,0-0.1,0L4.9,9.6c-0.1,0.1-0.1,0.2,0,0.3L6.9,12h0C7,12,7,12,7.1,12l0,0
            c0.1,0,0.1,0,0.1-0.1l0.3-0.3c0.1-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.2,0-0.3,0c-0.1-0.1-0.1-0.2,0-0.3
            c0,0,0.1-0.1,0-0.2c0-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.2,0.1-0.3,0c-0.1-0.1-0.1-0.2,0-0.3
            c0.1-0.1,0.1-0.2,0-0.3c0,0,0,0,0,0C6.1,9.9,6,9.9,5.9,9.9C5.8,10,5.7,10,5.6,9.9c-0.1-0.1-0.1-0.2,0-0.3c0,0,0.1-0.1,0-0.2
            c0-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0C5.5,9.3,5.4,9.2,5.3,9.2z"/>
        <path style="fill:#203E77;" d="M8.5,12.8c-0.2,0-0.4-0.1-0.6-0.3l-0.4-0.4l0.3-0.3l0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.2,0.5-0.4
            c0.1-0.1,0.2-0.1,0.3-0.1c0.3,0.1,0.7,0,0.8-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0.1,0.4,0,0.5,0c0.2-0.1,0.2-0.3,0.2-0.3
            c0-0.1,0-0.1,0-0.2c0,0,0.1-0.1,0.2-0.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.2-0.1-0.3L12,9.8
            c0.1,0.2,0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.2-0.3,0.3-0.5,0.3c0,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.5,0.2-0.7,0.1
            c-0.3,0.3-0.7,0.5-1.1,0.4C9,12.6,8.8,12.8,8.5,12.8C8.5,12.8,8.5,12.8,8.5,12.8z"/>
        <path style="fill:#203E77;" d="M4.7,9.4C4.6,9.3,4.5,9.1,4.4,8.9L4,8.5l0.3-0.3l0.4,0.5c0,0,0,0,0,0.1C4.9,8.9,4.9,9,5.1,9.2L4.7,9.4z"/>
        <rect x="6.4" y="6.2" transform="matrix(0.7072 -0.7071 0.7071 0.7072 -2.8054 6.6519)" style="fill:#203E77;" width="0.4" height="1.1"/>
        <rect x="4.9" y="9.9" transform="matrix(0.696 -0.718 0.718 0.696 -5.6122 6.962)" style="fill:#203E77;" width="0.9" height="0.4"/>
        <rect x="4.9" y="9.9" transform="matrix(0.696 -0.718 0.718 0.696 -5.6122 6.962)" style="fill:#203E77;" width="0.9" height="0.4"/>
        <rect x="5.6" y="10.6" transform="matrix(0.696 -0.718 0.718 0.696 -5.8861 7.6381)" style="fill:#203E77;" width="0.9" height="0.4"/>
        <rect x="6.3" y="11.2" transform="matrix(0.6945 -0.7195 0.7195 0.6945 -6.1672 8.3413)" style="fill:#203E77;" width="0.9" height="0.4"/>
    </g>

</template>





<script>

    export default {

        name: 'HandshakeIcon'

    }

</script>