<template functional>

    <path d="M7.1,18c-0.3,0-2.2-0.2-3.5-0.5c-1.7-0.4-2.9-1.1-3.2-1.4C-0.1,15.5,0,14.6,0,13.8c0-0.6,0-1.2,0.1-1.7
	c0.1-0.5,0.2-1.1,0.3-1.6c0.2-0.6,0.4-1,0.6-1.4c0.3-0.4,0.6-0.7,1-1c0.4-0.2,0.9-0.4,1.5-0.4C4.6,8.5,5.7,9,7.1,9s2.5-0.5,3.5-1.4
	c0.6,0,1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1,1c0.3,0.4,0.5,0.9,0.6,1.4c0.2,0.6,0.3,1.1,0.3,1.6c0.1,0.5,0.1,1.1,0.1,1.7
	c0,0.8,0.1,1.8-0.4,2.3c-0.3,0.3-1.5,0.9-3.2,1.4C9.3,17.8,7.3,18,7.1,18z M7.1,8.3c-1.2,0-2.2-0.4-3-1.2S2.8,5.3,2.8,4.2
	S3.2,2,4.1,1.2S5.9,0,7.1,0s2.2,0.4,3,1.2C10.9,2,11.3,3,11.3,4.2s-0.4,2.1-1.2,2.9C9.2,7.9,8.2,8.3,7.1,8.3z"/>

</template>





<script>

    export default {

        name: 'UserIcon'

    }

</script>