import { axios } from '@/utils';

/**
 * @typedef {object} lien
 * @property {string} name Lienholder Name
 * @property {string} phoneNumber Lienholder Phone Number
 * @property {string} accountNumber Lienholder Account Number
 */

/**
 * @typedef {object} lienCreateViewModelRequest
 * @property {number} taskId Task ID
 * @property {lien} lien Lienholder
 */

/**
 * @param {lienCreateViewModelRequest} request The create lien request.
 * @returns {Promise<boolean>} True if the lien was created successfully.
 */
const createLien = async request => {
	const { created } = await axios.post('/api/Lien', request);

	return created;
};

/**
 * @returns {Promise<lienGetViewModelResponse>} Containing options required for form to load.
 */

const getLien = async (applicationId) => {
    const {
        data
    } = await axios.get(`/api/Lien/${applicationId}/`);

    return data;
}

export default { createLien, getLien };
