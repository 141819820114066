<template>
    <section class="declined-loan">
        <StsCard style="padding: 0.5rem 0.75rem; margin: 0px 1rem">
            <h1 v-text="declinedApplicationMessage"></h1>
        </StsCard>

        <p>
            If you have additional questions, please contact your loan
            specialist.
        </p>
    </section>
</template>

<script>
    import { StsCard } from "@/components/SwitchThink";
    import { UiLanguageType, UiTextComponentType } from "@/constants";
    import { mapActions } from "vuex";

    export default {
        name: "DeclinedMessage",
        components: {
            StsCard,
        },
        data() {
            return {
                declinedApplicationMessage: "",
            };
        },
        async created() {
            try {
                const request = {
                    uiLanguageType: UiLanguageType.ENGLISH,
                    uiTextComponentType:
                        UiTextComponentType.DECLINED_APP_MESSAGE,
                };

                this.declinedApplicationMessage = await this.getUiTextValue(
                    request
                );
            } catch (error) {
                throw error;
            }
        },
        methods: {
            ...mapActions("uiTextValues", ["getUiTextValue"]),
        },
    };
</script>

<style lang="scss" scoped>
    .declined-loan {
        padding-top: 2.5rem;

        h1 {
            text-align: center;
            font-size: 1.25rem;
            color: var(--primary);
            font-weight: 300;
            margin-left: 1rem;
        }

        p {
            font-size: 0.91rem;
            font-weight: 300;
            color: var(--primary);
            margin: 1.25rem;
            text-align: center;

            strong {
                font-weight: 700;
            }

            span {
                font-weight: 400;
                color: var(--secondary);
            }
        }

        .tile-container {
            display: flex;
            justify-content: center;

            .tile,
            ::v-deep .tile {
                width: calc(100% / 3 - 1em);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 0.5rem;
                border-radius: 8px;
                border: 2px solid var(--disabled);
                color: var(--primary);
                font-weight: 300;
                font-size: 0.9rem;
                transition: opacity 1s;
                text-decoration: none;

                &:hover {
                    opacity: 0.7;
                }

                span {
                    margin-top: 0.5rem;
                }
            }
        }
    }
</style>
