<template functional>

    <path class="st0" d="M13.2,7.1v6l0,0l-2.5,1.6c0,0,0-6,0-6L5,4.8l2.2-1.4L13.2,7.1z M5.3,5.7L3,7.2l0.4,0.3c0.1,0.1,0.1,0.1,0.1,0.1
	c0,0,0,0.1-0.1,0.1L3,8l3,1.8l0.2-0.1c0.3-0.2,0.6-0.2,0.9,0l0.2,0.1l2.2-1.4L10.2,9l-3.9,2.5l-6-3.8l4.2-2.6L5.3,5.7z M10.2,14.4
	l-3.9,2.4l-5.9-4c-0.1-0.1-0.3-0.1-0.4,0.1C0,13,0,13.2,0.1,13.3l6,4.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l3.7-2.4L10.2,14.4z
	 M10.2,12.5l-3.9,2.4l-5.9-4c-0.1-0.1-0.3-0.1-0.4,0.1C0,11.1,0,11.3,0.1,11.4l6,4.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0
	l3.7-2.3L10.2,12.5z M10.2,10.6L6.3,13L0.5,9C0.3,8.9,0.1,8.9,0.1,9.1C0,9.2,0,9.4,0.1,9.5l6,4.2c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0,0.1,0,0.2,0l3.7-2.3L10.2,10.6z M13.8,9l4-2.6C18,6.4,18,6.2,18,6c-0.1-0.1-0.3-0.2-0.4-0.1l-3.7,2.4V9z M13.8,12.8l4-2.5
	C18,10.2,18,10,18,9.8c-0.1-0.1-0.3-0.2-0.4-0.1l-3.7,2.3V12.8z M13.8,10.9l4-2.5C18,8.3,18,8.1,18,7.9c-0.1-0.1-0.3-0.2-0.4-0.1
	l-3.7,2.3V10.9z M12.3,5.8L14,4.8c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3L12,2.9c-0.4,0.2-0.8,0.2-1.2,0l-1.6,1l0,0L12.3,5.8
	L12.3,5.8z M11.8,4.7h-0.3V3.9h0.3V4.7z M4,7.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3L6,9.1c0.4-0.2,0.8-0.2,1.2,0L8.8,8l0,0
	l-2.9-2L4,7.3z M6.5,8.1H6.2V7.3h0.3V8.1z M12.9,6.2L12.9,6.2L15,4.8l-0.4-0.3c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1,0.1-0.1L15,4.1
	l-3-1.9l-0.2,0.1c-0.3,0.2-0.6,0.2-0.9,0l-0.2-0.1L8.7,3.6L7.8,3l3.9-2.5l6,3.8l-3.9,2.5L12.9,6.2z"/>

</template>





<script>

    export default {

        name: 'MoneyIcon'

    }

</script>