import { applicantAPI } from "@/api";

const state = {
    applicantId: 0,
    applicantToken: "",
    firstName: "",
    lastName: "",
    welcomeMessage: "",
    sessionToken: '',
    authenticated: false
};

function resetState(state) {

    state.applicantId = 0;
    state.applicantToken = "";
    state.firstName = "";
    state.lastName = "";
    state.welcomeMessage = "";
    //state.sessionToken = '';
    state.authenticated = false;

};

const mutations = {
    setApplicant(state, { firstName, lastName }) {

        state.firstName = firstName;
        state.lastName = lastName;

    }

};

const actions = {
    async loadApplicant({ commit }, request) {

        const applicant = await applicantAPI.loadApplicant(request);
        commit('setData', { objName: 'applicantToken', data: applicant.applicantToken });

    },
    async getApplicant({ commit, state }) {

        const applicantToken = state.applicantToken;

        try {

            const applicant = await applicantAPI.getApplicant(applicantToken);

            commit("setApplicant", applicant);

            return applicant;

        } catch (error) {

            throw error;

        };

    }

};

export {
    state,
    resetState,
    mutations,
    actions

    };

export default {
    state,
    resetState,
    mutations,
    actions

};