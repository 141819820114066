// import { axios } from '@/utils';
import {
    colors,
    images
} from '@/defaultData';

const getConfig = async () => {

    try {

        // TODO: Uncomment when branding service is up and running:
        // const {
        //     data: {
        //         colors,
        //         images,
        //         name
        //     }
        // } = await axios.get('/api/Branding');

        createCSSVariables(colors);

        return {
            colors,
            images,
            name: 'Desert Financial Credit Union'
        };

    } catch (error) {

        throw error;

    };
        
};

const createCSSVariables = colors => {

    const colorArray = Object.entries(colors);

    for (const color of colorArray) {

        document.documentElement.style.setProperty(`--${ color[0] }`, `rgba(${ color[1] }, 1)`);
        document.documentElement.style.setProperty(`--${ color[0] }-25`, `rgba(${ color[1] }, 0.25)`);
        document.documentElement.style.setProperty(`--${ color[0] }-50`, `rgba(${ color[1] }, 0.50)`);
        document.documentElement.style.setProperty(`--${ color[0] }-75`, `rgba(${ color[1] }, 0.75)`);

    };
    
    return colorArray;

};

export default {

    getConfig,
    createCSSVariables

};

export {

    getConfig,
    createCSSVariables

};