export default {

    primary: '0, 58, 121',
    secondary: '0, 179, 231',
    tertiary: '247, 141, 40',
    danger: '227, 28, 61',
    success: '46, 133, 64',
    headerCopy: '255, 255, 255',
    bodyCopy: '88, 88, 91',
    lightGrey: '147, 149, 151',
    darkGrey: '64, 64, 65',
    white: '255, 255, 255',
    black: '0, 0, 0',
    background: '239, 240, 242',
    disabled: '215, 215, 215'

};