import { render, staticRenderFns } from "./StsSelect.vue?vue&type=template&id=630fec99&scoped=true&"
import script from "./StsSelect.vue?vue&type=script&lang=js&"
export * from "./StsSelect.vue?vue&type=script&lang=js&"
import style0 from "./StsSelect.vue?vue&type=style&index=0&id=630fec99&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630fec99",
  null
  
)

export default component.exports