<template functional>
	<g>
		<path
			fill="#70B603"
			d="M13.2,2.2C12,1.4,10.5,1,9,1C4.6,1,1,4.6,1,9s3.6,8,8,8s8-3.6,8-8c0-1.7-0.5-3.3-1.5-4.6v0
		c0.4-0.1,0.7-0.2,0.9-0.4l0,0C17.4,5.3,18,7.1,18,9c0,5-4,9-9,9s-9-4-9-9s4-9,9-9c1.7,0,3.2,0.5,4.6,1.2l0,0
		C13.4,1.5,13.2,1.8,13.2,2.2L13.2,2.2z M15.2,0.7c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7
		S14.2,0.7,15.2,0.7z M15.2,3.5l1.3-1.3c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0L15,2.9l-0.6-0.6c-0.1-0.1-0.3-0.1-0.4,0
		s-0.1,0.3,0,0.4l0.8,0.8c0,0,0.1,0.1,0.2,0.1C15,3.5,15.1,3.5,15.2,3.5z"
		/>
		<path
			fill="#ADB4BA"
			d="M9,3.7c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S7.3,3.7,9,3.7z M3.6,14.9c-0.1-1-0.1-2.8,0.4-4
		c0.1,0,0.5-1.7,2.3-1.6c0,0,1.4,1.2,2.8,1.1c0,0,1.1,0.1,2.5-1.2c0,0,1.9,0,2.4,2.3c0,0,0.4,1.3,0.3,3.5l0,0c-1.4,1.2-3.2,2-5.3,2
		C6.9,17,5.1,16.2,3.6,14.9L3.6,14.9z"
		/>
	</g>
</template>





<script>
export default {
	name: 'SingleOwnerSelected'
};
</script>