export default [
	{
		path: 'error/:statusType',
		name: 'Error',
		component: () => import(/* webpackChunkName: "Error" */ '@/views/Error'),
		meta: {
			backButton: {
				route: 'Authentication',
				title: 'Return to the homepage'
			},
			overflow: true,
			isDisplayOnly: true,
			resetState: false,
			bypassEnforcer: true,
			showFooter: true
		}
	}
];
