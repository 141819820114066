<template functional>
	<g>
		<path
			fill="#003C7B"
			d="M10.7,16.8c0,0,0.1,0,0.1,0c3-0.7,5.4-3.1,6-6.2l0,0c-0.7-1.7-2.3-1.7-2.3-1.7c-1.4,1.4-2.5,1.2-2.5,1.2
		c-1.3,0.2-2.7-1-2.7-1C7.6,9,7.2,10.7,7.2,10.7c-0.8,1.6-0.4,4.7-0.4,4.7C8,16.2,9.6,16.6,10.7,16.8L10.7,16.8z M12.1,3.5
		c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S10.4,3.5,12.1,3.5z"
		/>
		<path
			fill="#ADB4BA"
			d="M10.8,16.8c0.1-1.9-0.2-3-0.2-3c-0.4-1.9-2-1.9-2-1.9c-1.1,1.1-2.1,1-2.1,1C5.4,13,4.1,12,4.1,12
		c-1.5-0.1-1.8,1.3-1.8,1.3s0,0,0,0l0,0C3.7,15.5,6.2,17,9,17c0.6,0,1.2-0.1,1.7-0.2 M6.4,7.4c1.4,0,2.5,1.1,2.5,2.5
		s-1.1,2.5-2.5,2.5S4,11.3,4,9.9S5.1,7.4,6.4,7.4z"
		/>
		<path
			fill="#70B603"
			d="M13.2,2.2C12,1.4,10.5,1,9,1C4.6,1,1,4.6,1,9s3.6,8,8,8s8-3.6,8-8c0-1.7-0.5-3.3-1.5-4.6v0
		c0.4-0.1,0.7-0.2,0.9-0.4l0,0C17.4,5.3,18,7.1,18,9c0,5-4,9-9,9s-9-4-9-9s4-9,9-9c1.7,0,3.2,0.5,4.6,1.2l0,0
		C13.4,1.5,13.2,1.8,13.2,2.2L13.2,2.2z M15.2,0.7c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7
		S14.2,0.7,15.2,0.7z M15.2,3.5l1.3-1.3c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0L15,2.9l-0.6-0.6c-0.1-0.1-0.3-0.1-0.4,0
		s-0.1,0.3,0,0.4l0.8,0.8c0,0,0.1,0.1,0.2,0.1C15,3.5,15.1,3.5,15.2,3.5z"
		/>
	</g>
</template>





<script>
export default {
	name: 'JointOwnerSelected'
};
</script>