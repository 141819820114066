export default {
    NONE: 0,
    SELLER_INFORMATION: 1,
    PROOF_OF_INSURANCE: 2,
    LIENHOLDER_INFORMATION: 3,
    SCHEDULE_CLOSING: 4,
    BOAT_COLLATERAL: 5,
    AUTO_COLLATERAL: 6,
    RV_COLLATERAL: 7,
    MOTORCYCLE_COLLATERAL: 8,
    NOTE: 9,
    EPOA_REQUIRED: 10,
    0: 'None',
    1: 'SellerInformation',
    2: 'ProofOfInsurance',
    3: 'LienholderInformation',
    4: 'ScheduleClosing',
    5: 'BoatCollateral',
    6: 'AutoCollateral',
    7: 'RVCollateral',
    8: 'MotorcycleCollateral',
    9: 'Note',
    10: 'EpoaRequired'
};
