export default {

    addItem(state, { objName, data }) {

        state[objName].push(data);

    },
    deleteItem(state, { objName, id }) {

        const index = state[objName].findIndex(address => address.id === id);

        state[objName].splice(index, 1);

    },
    updateItem(state, { objName, data }) {

        const index = state[objName].findIndex(address => address.id === data.id);

        state[objName][index] = data;

    }

};