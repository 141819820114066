import { collateralAPI } from "@/api";
import { CollateralValuationType, ProductType } from "@/constants";
import { validateKeydown } from "@/utils";
import { mapState } from "vuex";

const VinSearchExcludedCharacters = /[iIoOqQ]/g;

export default {
    computed: {
        ...mapState({
            collateralValuationAvailability: (state) =>
                state.collateralValuationAvailability,
        }),
    },
    methods: {
        getValidVinInput(input) {
            return input.replace(VinSearchExcludedCharacters, "");
        },
        async submitCollateral(request) {
            try {
                switch (request.collateralValuationType) {
                    case CollateralValuationType.AUTO:
                        return await collateralAPI.createAutoCollateral(
                            request
                        );
                    case CollateralValuationType.MARINE:
                        return await collateralAPI.createBoatCollateral(
                            request
                        );
                    case CollateralValuationType.MOTORCYCLE:
                        return await collateralAPI.createMotorcycleCollateral(
                            request
                        );
                    case CollateralValuationType.RV:
                        return await collateralAPI.createRVCollateral(request);
                    default:
                        break;
                }
            } catch (error) {
                return this.$toast.error(error);
            }
        },
        validateVin(vin) {
            const minVinLength = 16;

            return vin && vin.length >= minVinLength;
        },
        validateVinKeydown(e) {
            let errorMessage = null;

            if (!validateKeydown(e, VinSearchExcludedCharacters)) {
                const key = e.key;
                errorMessage = `The letter ${key.toUpperCase()} is an invalid VIN character.`;
            }

            return errorMessage;
        },
        valuationEnabled(productTypeId) {
            switch (productTypeId) {
                case ProductType.AUTO:
                    return !this.collateralValuationAvailability
                        .isCollateralValuationAutoDisabled;
                case ProductType.AUTOREFI:
                    return !this.collateralValuationAvailability
                        .isCollateralValuationAutoRefiDisabled;
                case ProductType.BOAT:
                    return !this.collateralValuationAvailability
                        .isCollateralValuationMarineDisabled;
                case ProductType.MOTORCYCLE:
                    return !this.collateralValuationAvailability
                        .isCollateralValuationMotorcycleDisabled;
                case ProductType.RV:
                    return !this.collateralValuationAvailability
                        .isCollateralValuationRvDisabled;
                default:
                    return false;
            }
        },
    },
};
