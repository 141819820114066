import axios from "@/utils/axios";

const getYearOptionsAsync = async (collateralValuationTypeId, rvCategoryId) => {
    try {
        const {
            data: { options },
        } = await axios.post("/api/CollateralOptions/Years", {
            collateralValuationTypeId,
            rvCategoryId,
        });

        return options;
    } catch (error) {
        throw error;
    }
};

const getMakeOptionsAsync = async (
    collateralValuationTypeId,
    year,
    rvCategoryId
) => {
    try {
        const {
            data: { options },
        } = await axios.post("/api/CollateralOptions/Makes", {
            collateralValuationTypeId,
            year,
            rvCategoryId,
        });

        return options;
    } catch (error) {
        throw error;
    }
};

const getModelOptionsAsync = async (
    collateralValuationTypeId,
    year,
    make,
    rvCategoryId
) => {
    try {
        const {
            data: { options },
        } = await axios.post("/api/CollateralOptions/Models", {
            collateralValuationTypeId,
            year,
            make,
            rvCategoryId,
        });

        return options;
    } catch (error) {
        throw error;
    }
};

const getTrimOptionsAsync = async (
    collateralValuationTypeId,
    year,
    make,
    model,
    condition
) => {
    try {
        const {
            data: { options },
        } = await axios.post("/api/CollateralOptions/Trims", {
            collateralValuationTypeId,
            year,
            make,
            model,
            condition,
        });

        return options;
    } catch (error) {
        throw error;
    }
};

const getVinDetailsAsync = async (collateralValuationTypeId, vin) => {
    const {
        data: { vinDetails },
    } = await axios.post("/api/CollateralOptions/Vin", {
        collateralValuationTypeId,
        vin,
    });

    return vinDetails;
};

export default {
    getYearOptionsAsync,
    getMakeOptionsAsync,
    getModelOptionsAsync,
    getTrimOptionsAsync,
    getVinDetailsAsync,
};
