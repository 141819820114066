<template>
	<label class="input-container" data-test-label>
		<input
			:id="id"
			:name="name"
			type="radio"
			v-model.trim="innerValue"
			:value="optionValue"
			:disabled="disabled"
			data-test-input
		/>

		<div class="image-container">
			<img v-if="image" :src="image" :alt="label" />
			<div v-else class="no-image">
				<p>{{ fallbackText }}</p>
			</div>
			<div v-if="optionValue === value" :class="['overlay', { 'has-image': image }]">
				<BaseIcon height="2rem" width="2rem" name="Check" class="selected">
					<Check />
				</BaseIcon>
			</div>
		</div>
	</label>
</template>




<script>
import { BaseIcon, Check } from '@/components/SwitchThink/icons';

export default {
	name: 'ImageRadio',
	components: {
		BaseIcon,
		Check
	},
	props: {
		value: {
			required: true
		},
		optionValue: {
			required: true
		},
		fallbackText: {
			required: false,
			default: 'NO CARD'
		},
		label: {
			type: String,
			required: true
		},
		image: {
			type: String,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		}
	},
	computed: {
		id() {
			return `${this.label.replace(/\s/g, '')}-${this._uid}`;
		},
		innerValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
};
</script>




<style lang="scss" scoped>
.input-container {
	display: flex;
	justify-content: center;
	cursor: pointer;
	color: var(--bodyCopy);
	transition: opacity 0.5s;

	&.no-padding {
		margin-bottom: 0;
	}

	&:hover {
		opacity: 0.7;
	}

	input {
		position: absolute;
		left: -9999px;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.image-container {
		position: relative;
		width: 100%;
		max-width: 175px;

		img {
			width: 100%;
			height: auto;
		}

		.no-image {
			position: relative;
			display: flex;
			border-radius: 3px;
			width: 100%;
			height: auto;
			padding-bottom: 63.33%;
			background-color: var(--disabled);
			align-items: center;
			justify-content: center;
			font-size: 13px;
			font-weight: 400;
			color: var(--darkGrey);

			p {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.overlay {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: 3px;
			background-color: rgba(0, 0, 0, 0.3);
			color: rgb(149, 242, 4);
			display: flex;
			align-items: center;
			justify-content: center;

			&.has-image {
				bottom: 6px;
			}
		}
	}
}
</style>