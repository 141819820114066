import { createToastInterface } from 'vue-toastification';

import { axios, toastConfig } from '@/utils';
import { StsSessionWarning } from '@/components/SwitchThink';

const toast = createToastInterface(toastConfig);

export default {
	namespaced: true,
	state: {
		config: {
			warningInterval: null,
			timeoutWarningMessage: '',
			expiredInterval: null
		},
		warningTimer: null,
		showWarning: false,
		expiredTimer: null,
		sessionExpired: false,
		warningToastId: null
	},
	mutations: {
		setData(state, { objName, data }) {
			state[objName] = data;
		},
		resetTimers(state) {
			clearTimeout(state.warningTimer);
			state.warningTimer = null;
			state.showWarning = false;

			clearTimeout(state.expiredTimer);
			state.expiredTimer = null;
			state.sessionExpired = false;

			toast.dismiss(state.warningToastId);
			state.warningToastId = null;
		}
	},
	actions: {
		async getConfig({ commit, getters, state }) {
			if (getters.hasConfig) {
				return state.config;
			}

			try {
				const {
					data: { timeoutWarningDisplayTime, timeoutWarningMessage, timerIntervalInMinutes }
				} = await axios.get('/api/Configuration/Timer');

				commit('setData', {
					objName: 'config',
					data: {
						warningInterval: timeoutWarningDisplayTime * 60000,
						timeoutWarningMessage,
						expiredInterval: (timerIntervalInMinutes - timeoutWarningDisplayTime) * 60000
					}
				});

				return state.config;
			} catch (error) {
				throw new Error(error);
			}
		},
		startExpiredTimer({ commit }, intervalTime = 5000) {
			commit('setData', {
				objName: 'expiredTimer',
				data: setTimeout(() => {
					commit('setData', { objName: 'sessionExpired', data: true });
					commit('setData', { objName: 'showWarning', data: false });
				}, intervalTime)
			});
		},
		async reset({ commit, dispatch }) {
			try {
				const { warningInterval, expiredInterval, timeoutWarningMessage } = await dispatch('getConfig');

				await axios.get('/api/Renew');

				commit('resetTimers');
				commit('setData', {
					objName: 'warningTimer',
					data: setTimeout(() => {
						commit('setData', { objName: 'showWarning', data: true });
						commit('setData', {
							objName: 'warningToastId',
							data: toast.info(
								{
									component: StsSessionWarning,
									props: {
										message: timeoutWarningMessage,
										renewAction: () => dispatch('reset')
									}
								},
								{
									timeout: expiredInterval,
									position: 'bottom-right',
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									closeButton: false,
									icon: true
								}
							)
						});

						dispatch('startExpiredTimer', expiredInterval);
					}, warningInterval)
				});

				return {
					warningInterval,
					expiredInterval
				};
			} catch (error) {
				throw new Error(error);
			}
		}
	},
	getters: {
		hasConfig: state =>
			state.config.warningInterval && state.config.timeoutWarningMessage && state.config.expiredInterval
	}
};
