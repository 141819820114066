import { axios } from '@/utils';

const getChallengeDestinations = async applicantToken => {
	try {
		const {
			data: { challengeDestinations }
		} = await axios.get(`/api/Applicant/${applicantToken}/ChallengeDestinations`);

		return challengeDestinations;
	} catch (error) {
		throw error;
	}
};

const createChallenge = async request => {
	try {
		const {
			data: { challengeId }
		} = await axios.post('/api/Challenge', request);

		return challengeId;
	} catch (error) {
		throw error;
	}
};

const createChallengeAction = async request => {
	try {
		const {
			data: { result, authenticated }
        } = await axios.post('/api/ChallengeAction', request);

        return {result, authenticated};
	} catch (error) {
		throw error;
	}
};

export default {
	createChallenge,
	createChallengeAction,
	getChallengeDestinations
};

export { createChallenge, createChallengeAction, getChallengeDestinations };
