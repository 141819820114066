<template>
    <ValidationProvider
        :rules="validationRules"
        :name="name || label"
        :vid="validationId"
        v-slot="{ errors }"
        slim
    >
        <StsInput
            v-model="innerValue"
            :label="label"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :error="errors[0]"
            :dateConfig="dateConfig"
            :mask="mask"
            :maskOutput="maskOutput"
            :isOptional="isOptional"
            :disableAutocomplete="disableAutocomplete"
            :isSmall="isSmall"
            @change="$emit('change', $event)"
            :id="id"
            :tooltip="tooltip"
            :hideLabel="hideLabel"
            :forceCapitalization="forceCapitalization"
        />

        <slot name="input-control" />
    </ValidationProvider>
</template>

<script>
    import { StsInput } from "@/components/SwitchThink/form";
    import { ValidationProvider } from "vee-validate";

    export default {
        name: "VInput",
        components: {
            ValidationProvider,
            StsInput,
        },
        props: {
            value: {
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: false,
            },
            type: {
                type: String,
                required: false,
                default: "text",
            },
            placeholder: {
                type: String,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            validationRules: {
                type: [String, Object],
                required: false,
            },
            dateConfig: {
                type: Object,
                required: false,
            },
            mask: {
                type: [String, Array],
                required: false,
            },
            maskOutput: {
                type: Boolean,
                required: false,
            },
            isOptional: {
                type: Boolean,
                required: false,
            },
            disableAutocomplete: {
                type: Boolean,
                required: false,
            },
            validationId: {
                type: String,
                required: false,
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false,
            },
            id: {
                type: [String, Number],
                required: false,
            },
            tooltip: {
                type: Object,
                required: false,
            },
            hideLabel: {
                type: Boolean,
                required: false,
            },
            forceCapitalization: {
                type: Boolean,
                required: false,
            },
        },
        computed: {
            innerValue: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit("input", val);
                },
            },
        },
    };
</script>
