<template functional>

    <g>
        <path class="st0" d="M11.2,4.1c0-2.1-1.6-3.7-3.7-3.7S3.7,1.9,3.7,4.1v1.7c0,2.1,1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7V4.1z M10.3,5.7
            c0,1.7-1.2,2.9-2.9,2.9S4.6,7.4,4.6,5.7V4.1c0-1.7,1.2-2.9,2.9-2.9s2.9,1.2,2.9,2.9V5.7z"/>
        <path class="st0" d="M0,14v3.3h0.8V14c0-2.1,4.4-3.3,6.6-3.3c0.6,0,1.5,0.1,2.4,0.3l0.2-0.8c-1-0.2-1.9-0.3-2.6-0.3
            C4.9,9.8,0,11.1,0,14z"/>
        <path class="st0" d="M18,16.6v-4.3c0-0.5-0.4-0.9-0.9-0.9h-0.2V9.8c0-1.5-1.2-2.8-2.8-2.8s-2.8,1.2-2.8,2.8v1.7H11
            c-0.5,0-0.9,0.4-0.9,0.9v4.5c0,0.5,0.4,0.9,0.9,0.9h6.1c0.5,0,0.9-0.4,0.9-0.9C18,16.7,18,16.7,18,16.6z M12.1,9.8
            c0-1.1,0.9-1.9,1.9-1.9S16,8.7,16,9.8v1.7h-3.9V9.8z M17.1,16.8H11c0,0,0,0,0,0v-4.5c0,0,0,0,0,0h6.1c0,0,0,0,0,0V16.8
            C17.1,16.8,17.1,16.8,17.1,16.8C17.1,16.8,17.1,16.8,17.1,16.8z"/>
        <path class="st0" d="M13.8,12.7c-0.5,0.1-0.9,0.5-1,1c-0.1,0.5,0,1,0.4,1.4v0.6c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V15
            c0.3-0.3,0.5-0.6,0.5-1c0-0.4-0.1-0.8-0.5-1.1C14.6,12.7,14.2,12.6,13.8,12.7z M14.3,14.4c-0.1,0.1-0.2,0.2-0.2,0.4v0.8
            c0,0-0.1,0-0.1,0v-0.8c0-0.2-0.1-0.3-0.2-0.4c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.2,0.2-0.3,0.4-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0
            c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.4C14.6,14.2,14.5,14.3,14.3,14.4z"/>
    </g>

</template>





<script>

    export default {

        name: 'PasswordIcon'

    }

</script>