<template functional>

    <g>
        <path style="fill:#2391EC;" d="M9,17.6c-4.7,0-8.6-3.9-8.6-8.6S4.3,0.4,9,0.4c4.7,0,8.6,3.9,8.6,8.6S13.7,17.6,9,17.6z M9,1C4.6,1,1,4.6,1,9
            c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8C17,4.6,13.4,1,9,1z"/>
        <path style="fill:#70B603;" d="M9,18c-5,0-9-4-9-9s4-9,9-9c5,0,9,4,9,9S14,18,9,18z M9,1C4.6,1,1,4.6,1,9c0,4.4,3.6,8,8,8s8-3.6,8-8
            C17,4.6,13.4,1,9,1z"/>
        <g>
            <ellipse style="fill:#70B603;" cx="15.1" cy="2.5" rx="1.9" ry="1.8"/>
            <path style="fill:#FFFFFF;" d="M15.1,4.4c-1.1,0-2-0.9-2-1.9s0.9-1.9,2-1.9c1.1,0,2,0.9,2,1.9S16.2,4.4,15.1,4.4z M15.1,0.7
                c-1,0-1.8,0.8-1.8,1.7s0.8,1.7,1.8,1.7S17,3.4,17,2.5S16.1,0.7,15.1,0.7z"/>
        </g>
        <path style="fill:#FFFFFF;" d="M16.3,1.9c0.1,0.1,0.2,0.2,0.1,0.4c0,0-0.1,0.1-0.1,0.1c-0.4,0.4-0.9,0.8-1.3,1.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0
            c-0.2-0.3-0.5-0.5-0.7-0.8C14,2.6,14,2.6,14,2.5c0,0,0.1-0.2,0.2-0.2c0,0,0.2-0.2,0.3-0.1c0.1,0.1,0.3,0.3,0.5,0.5c0,0,0,0,0,0
            s0,0,0.1,0c0.2-0.2,0.6-0.5,0.9-0.8C16,1.8,16.2,1.7,16.3,1.9z"/>
        <path style="fill:#203E77;" d="M5.8,11c-0.3,0-0.6-0.1-0.8-0.3l0.3-0.3c0.5,0.5,1.8,0,3-1l0.3,0.3C7.5,10.6,6.5,11,5.8,11z"/>
        <path style="fill:#203E77;" d="M9.5,8.9L9.2,8.6c1.2-1.4,1.8-2.8,1.3-3.3L10.8,5C11.5,5.7,10.9,7.3,9.5,8.9z"/>
        <path style="fill:#203E77;" d="M7.2,12.9c-0.6,0-1.1-0.1-1.6-0.5l0.2-0.3C7,13,9,12.4,10.1,11.4l0.5-0.5l0.5,0.5L11.6,9L9.3,9.5l0.7,0.7
            l-0.4,0.4C8.8,11.4,7.7,12,6.8,12c-0.4,0-0.7-0.1-0.9-0.3L5,10.7C4.2,10,4.9,8.1,6.5,6.5C8.2,4.9,10,4.2,10.8,5l0.9,0.9
            c0.5,0.5,0.4,1.5-0.3,2.6l-0.3-0.2c0.6-0.9,0.7-1.7,0.3-2.1l-0.9-0.9c-0.5-0.5-2.1,0-3.7,1.6C5.3,8.4,4.8,10,5.3,10.5l0.9,0.9
            c0.1,0.1,0.3,0.2,0.6,0.2c0.6,0,1.7-0.4,2.5-1.2l0.1-0.1L8.5,9.3l3.7-0.8l-0.8,3.7l-0.8-0.8l-0.3,0.3C9.6,12.4,8.4,12.9,7.2,12.9z"
            />
        <path style="fill:#203E77;" d="M7.2,8.6l-0.4,0c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1,0-0.3,0.1-0.4l0.2-0.2L7.1,8L6.9,8.2c0,0,0,0,0,0l0.3,0
            c0.2,0,0.4-0.1,0.5-0.2l0,0c0,0,0,0,0,0L7.4,7.8C7.3,7.7,7.2,7.6,7.2,7.5c0-0.1,0-0.2,0.2-0.4l0,0C7.6,6.9,8,6.7,8.3,6.7l0.4,0
            c0,0,0,0,0,0C8.8,6.8,8.9,6.8,9,7C9,7.1,9,7.3,8.8,7.4L8.7,7.6L8.4,7.3l0.2-0.2c0,0,0,0,0,0l-0.3,0c-0.1,0-0.4,0.1-0.5,0.2l0,0
            c0,0,0,0,0,0L8,7.6c0.1,0.1,0.2,0.2,0.2,0.2c0,0.2-0.1,0.3-0.1,0.4l0,0.1C7.8,8.4,7.5,8.6,7.2,8.6z"/>
        <rect x="6.4" y="8.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.111 7.3234)" style="fill:#203E77;" width="0.7" height="0.4"/>
        <rect x="8.3" y="6.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1982 8.1042)" style="fill:#203E77;" width="0.7" height="0.4"/>
    </g>

</template>





<script>

    export default {

        name: 'NewSelected'

    }

</script>