<template>
	<div id="app">
		<transition name="fade">
			<StsOverlay v-if="isLoading">
				<BaseIcon height="6rem" width="6rem" name="Loader">
					<Clock />
				</BaseIcon>
			</StsOverlay>
		</transition>

		<div v-if="!isDisplayOnly ? defaultsLoaded : true">
			<StsBrowserMessage v-if="badBrowser" />

			<transition name="fade">
				<StsModal
					v-if="modal.isOpen"
					:title="modal.title"
					:smallTitle="modal.smallTitle"
					:preventClose="modal.preventClose"
					@close="closeModal"
				>
					<template>
						<ContactModal v-if="showContact" />

						<div v-if="showLogout">
							
							<section class="button-container">
								<StsButton
									fullwidth
									lowercase
									small
									type="plain"
									style="border: 0.25em solid var(--primary)"
									title="No, keep me logged in"
									@click="closeModal"
								/>

								<StsButton fullwidth lowercase small title="Yes, log me out" @click="logout()" />
							</section>
						</div>

						<PortalTarget v-if="hasPortal.content" name="modal-content" />
					</template>

					<template slot="footer">
						<PortalTarget v-if="hasPortal.footer" name="modal-footer" />
					</template>
				</StsModal>
			</transition>

			<transition name="fade">
				<StsModal
					v-if="modal.secondaryIsOpen"
					:title="modal.secondaryTitle"
					:smallTitle="modal.secondarySmallTitle"
					:preventClose="modal.secondaryPreventClose"
					@close="triggerSecondaryModal({ isOpen: false })"
				>
					<template>
						<PortalTarget v-if="hasSecondaryPortal.content" name="secondary-modal-content" />
					</template>

					<template slot="footer">
						<PortalTarget v-if="hasSecondaryPortal.footer" name="secondary-modal-footer" />
					</template>
				</StsModal>
			</transition>

			<StsNav
				v-if="!$route.meta.hideNav"
				:hideHelp="$route.meta.hideHelp"
				@contact="handleContact"
				:authenticated="isAuthenticated"
				@logout="handleLogout"
			/>

			<div class="wrapper" v-if="!badBrowser">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
	import { PortalTarget } from 'portal-vue';
	import { mapActions, mapGetters, mapState } from 'vuex';

	import { BaseIcon, Clock, Phone } from '@/components/SwitchThink/icons';
	import { StsIconCallout, StsModal, StsNav, StsOverlay, StsButton } from '@/components/SwitchThink';

	import { ContactModal } from '@/components';
	import axios from 'axios';

	export default {
		name: 'App',
		components: {
			PortalTarget,
			BaseIcon,
			Clock,
			Phone,
			ContactModal,
			StsBrowserMessage: () => import('@/components/SwitchThink/StsBrowserMessage'),
			StsIconCallout,
			StsModal,
			StsNav,
			StsOverlay,
			StsButton
		},
		data() {
			return {
				showContact: false,
				showLogout: false
			};
		},
		computed: {
			...mapState({
				isLoading: state => state.isLoading,
				defaultsLoaded: state => state.defaultsLoaded,
				modal: state => state.modal,
				badBrowser: state => state.unsupportedBrowser,
				showSessionExpired: state => state.sessionTimer.sessionExpired
			}),
			...mapGetters('modal', ['hasPortal', 'hasSecondaryPortal']),
			...mapGetters('routeEnforcer', ['previous']),
			...mapGetters('applicant', ['isAuthenticated']),
			isDisplayOnly() {
				return this.$route.meta.isDisplayOnly;
			}
		},
		watch: {
			showSessionExpired(newVal, oldVal) {
				if (newVal !== oldVal && newVal) {
					return this.$router.push({
						name: 'Authentication',
						query: { devBypass: this.$route.query.devBypass },
						params: {
							productType: this.$route.params.productType,
							sessionExpired: true
						}
					});
				}
			}
		},
		methods: {
			...mapActions('modal', ['triggerModal', 'triggerSecondaryModal']),
			handleContact() {
				this.showContact = true;
				this.triggerModal({ isOpen: true, title: 'Have a question?' });
			},
			closeModal() {
				this.showContact = false;
				this.showLogout = false;

				this.triggerModal({ isOpen: false });
			},
			handleLogout() {
				this.showLogout = true;
				this.triggerModal({ isOpen: true, title: 'Are you sure?' });
			},
			logout() {
				this.$router.push(this.$constructUrl('LoggedOut'));
				return this.closeModal();
			}
		}
	};
</script>

<style lang="scss">
	@import './assets/globalStyles.css';
	@import './assets/animations.css';
</style>

<style lang="scss" scoped>
	#app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;

		.contact-icon {
			color: var(--primary);
		}

		.contact-text {
			color: var(--secondary);
			text-decoration: none;
			font-weight: 600;
			transition: color 0.3s;

			&:hover {
				color: var(--primary);
			}

			&.lg {
				font-size: 1.25rem;
				line-height: 1.25rem;
			}

			&.sm {
				font-size: 0.85rem;
				line-height: 0.85rem;
			}
		}

		.wrapper {
			flex: 1;

			&.no-nav {
				padding-top: 0;
			}
		}

		.button-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin: -1rem;
			margin: 2rem 1rem 1rem 1rem;
			column-gap: 1em;
		}
	}
</style>
