<template>
	<svg xmlns="http://www.w3.org/2000/svg"
		 :width="width"
		 :height="height"
		 :viewBox="viewBox"
		 :aria-labelledby="name"
		 role="img"
		 :fill="color">

		<title v-if="!title && name" :id="name" lang="en">{{ name }} icon</title>
		<title v-else-if="title" :id="name" lang="en">{{ title }}</title>

		<g>
			<slot />
		</g>
	</svg>
</template>





<script>
export default {
	name: 'BaseIcon',
	props: {
		name: {
			type: String,
			required: false
		},
		width: {
			type: [Number, String],
			default: 18
		},
		height: {
			type: [Number, String],
			default: 18
		},
		viewBox: {
			type: String,
			default: '0 0 18 18'
		},
		color: {
			type: String,
			default: 'currentColor'
		},
		title: {
			type: String,
			required: false
		},
	}
};
</script>





<style scoped>
	svg {
		display: inline-block;
		vertical-align: middle;
		/* margin-bottom: -2px; */
	}
</style>