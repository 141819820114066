import path from './basePath';
import errorRoutes from './errorRoutes';
import mainRoutes from './mainRoutes';

export default [
	{
		path,
		component: () => import(/* webpackChunkName: "DefaultLayout" */ '@/layouts/Default'),
		props: route => ({ overflow: route.meta.overflow ? true : false }),
		children: [...mainRoutes, ...errorRoutes]
	},
	{
		path,
		component: () => import(/* webpackChunkName: "FluidLayout" */ '@/layouts/Fluid'),
		props: route => ({ overflow: route.meta.overflow ? true : false }),
		children: [
			{
				path: 'application',
				name: 'Application',
				component: () => import(/* webpackChunkName: "Application" */ '@/views/Application'),
				meta: {
					overflow: false,
					resetState: false,
					forceNavigation: true,
					bypassEnforcer: false,
					showFooter: false
				}
			}
		]
	},
	{
		path: `${path}/*`,
		redirect: { name: 'Error', params: { statusType: 404 } }
	},
	{
		path: '*',
		redirect: to => ({ path: path + to.path })
	}
];
