<template functional>
	<path
		d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,0.9C4.5,0.9,0.9,4.5,0.9,9s3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1
	S13.5,0.9,9,0.9z M7.6,13.1l-2.7-3c-0.1-0.2-0.1-0.4,0-0.6c0.2-0.1,0.4-0.1,0.6,0l2,2.2L13,5c0.1-0.2,0.4-0.2,0.6-0.1
	c0.2,0.1,0.2,0.4,0.1,0.6L7.6,13.1z"
	/>
</template>





<script>
export default {
	name: 'ThinCheckIcon'
};
</script>