<template functional>

    <path d="M7.9,11.3l-5,6.2C2.6,17.8,2.1,18,1.6,18c-0.4,0-0.8-0.1-1.1-0.4C0.2,17.5,0,17.2,0,16.9c0-0.2,0.1-0.5,0.3-0.7L6.1,9
	L0.4,1.9C0.2,1.7,0.1,1.4,0.1,1.1c0-0.3,0.2-0.6,0.5-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.5,0,1,0.2,1.3,0.5l5,6.1l4.9-6.1
	C13.1,0.2,13.6,0,14.1,0c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.5,0.5,0.5,0.8c0,0.2-0.1,0.4-0.2,0.6L9.6,9l5.8,7.3
	c0.1,0.2,0.2,0.4,0.2,0.6c0,0.3-0.2,0.6-0.5,0.8c-0.3,0.2-0.7,0.3-1.1,0.3c-0.5,0-1-0.2-1.4-0.5L7.9,11.3z"/>

</template>





<script>

    export default {

        name: 'CloseIcon'

    }

</script>