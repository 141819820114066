import { axios } from '@/utils';

/**
 * @typedef {object} task
 * @property {number} applicationId Application id
 * @property {string} applicationToken Application token
 * @property {number} taskId Task id
 * @property {number} taskType Task type
 * @property {number} taskStatus Task status
 * @property {number} taskVerificationType Task verification type
 * @property {string} data Task data
 * @property {string} moduleName Module
 */

/**
 * @param {number} applicationId The application id.
 * @returns {Promise<task[]>} The tasks for the application.
 */
const getTasks = async applicationId => {
	const { data } = await axios.get(`/api/Application/${applicationId}/Tasks`);

	return data;
};

export default { getTasks };
