import { axios } from '@/utils';

/**
 * @typedef {object} seller
 * @property {number} sellerTypeConfigurationId Seller Type
 * @property {string} name Seller Name
 * @property {string} PhoneNumber Seller Phone Number
 * @property {string} Email Seller Email
 */

/**
 * @typedef {object} lien
 * @property {string} name Lienholder Name
 * @property {string} phoneNumber Lienholder Phone Number
 * @property {string} accountNumber Lienholder Account Number
 */

/**
 * @typedef {object} sellerCreateViewModelRequest
 * @property {number} sellerTypeConfigurationId Seller Configuration Type
 * @property {number} taskId Task ID
 * @property {seller} seller Seller
 * @property {lien} lien Lienholder
 */

/**
 * @param {sellerCreateViewModelRequest} request The create seller request.
 * @returns {Promise<boolean>} True if the seller was created successfully.
 */
const createSeller = async request => {
	const { created } = await axios.post('/api/Seller', request);

	return created;
};

/**
 * @returns {Promise<sellerGetViewModelResponse>} Containing options required for form to load.
 */

const getSellerInfo = async (applicationId) => {
    const {
        data
    } = await axios.get(`/api/Seller/${applicationId}/`);

    return data;
}


export default { createSeller, getSellerInfo };
