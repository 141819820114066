<template>
	<div class="input-container">
		<header
			:class="[
				'input-header',
				{
					'is-small': isSmall,
					'sr-only': hideLabel
				}
			]"
		>
			<label :class="{ 'has-error': error }" :for="inputId" data-test-label>
				{{ label }}
				<span v-if="isOptional & !disabled" data-test-optional>(OPTIONAL)</span>
			</label>

			<StsTooltip v-if="tooltip" v-bind="tooltip" />
		</header>

		<input
			v-if="type !== 'date' && !mask"
			:class="{ 'is-small': isSmall, 'has-error': error, 'force-capitals': forceCapitalization }"
			:id="inputId"
			:name="label"
			:type="type"
			:placeholder="placeholder"
			v-model.trim="innerValue"
			:disabled="disabled"
			data-test-input
			:autocomplete="disableAutocomplete ? 'off' : 'on'"
			@change="$emit('change', $event)"
			@click="$emit('click', $event)"
			@blur="$emit('blur', $event)"
		/>

		<TheMask
			v-if="type !== 'date' && mask"
			:class="{ 'is-small': isSmall, 'has-error': error, 'force-capitals': forceCapitalization }"
			:id="inputId"
			:name="label"
			:type="type"
			:placeholder="placeholder"
			v-model.trim="innerValue"
			:mask="mask"
			:masked="maskOutput"
			:disabled="disabled"
			data-test-input
			:autocomplete="disableAutocomplete ? 'off' : 'on'"
			@change="$emit('change', $event)"
			@click="$emit('click', $event)"
			@blur="$emit('blur', $event)"
		/>

		<TheMask
			v-if="type === 'date'"
			:class="{ 'is-small': isSmall, 'has-error': error, 'force-capitals': forceCapitalization }"
			:id="inputId"
			:name="label"
			type="tel"
			:placeholder="placeholder"
			v-model.trim="innerValue"
			mask="##/##/####"
			masked
			:disabled="disabled"
			data-test-input
			:autocomplete="disableAutocomplete ? 'off' : 'on'"
			@change="$emit('change', $event)"
			@click="$emit('click', $event)"
			@blur="$emit('blur', $event)"
		/>

		<slot name="input-control" />

		<transition name="fade">
			<p class="has-error" v-if="error" data-test-error>
				{{ error }}
			</p>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'Input',
		components: {
			TheMask: () => import('vue-the-mask').then(({ TheMask }) => TheMask),
			StsTooltip: () => import('@/components/SwitchThink/StsTooltip')
		},
		props: {
			value: {
				required: true
			},
			label: {
				type: String,
				required: true
			},
			type: {
				type: String,
				required: false,
				default: 'text'
			},
			placeholder: {
				type: String,
				required: false
			},
			disabled: {
				type: Boolean,
				required: false
			},
			error: {
				type: String,
				required: false
			},
			mask: {
				type: [String, Array],
				required: false
			},
			maskOutput: {
				type: Boolean,
				required: false
			},
			isOptional: {
				type: Boolean,
				required: false
			},
			disableAutocomplete: {
				type: Boolean,
				required: false
			},
			isSmall: {
				type: Boolean,
				required: false,
				default: false
			},
			id: {
				type: [String, Number],
				required: false
			},
			tooltip: {
				type: Object,
				required: false,
				validator: ({ title, description }) => title && description,
				default: null
			},
			hideLabel: {
				type: Boolean,
				required: false
			},
			forceCapitalization: {
				type: Boolean,
				required: false
			}
		},
		computed: {
			inputId() {
				return this.id || `${this.label.replace(/\s/g, '')}-${this._uid}`;
			},
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {
					if (this.forceCapitalization) {
						val = val.toUpperCase();
					}
					this.$emit('input', val);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-container {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		.has-error {
			color: var(--danger);
		}

		.input-header {
			display: flex;
			gap: 0.5rem;
			align-items: baseline;

			label {
				display: block;
				color: var(--primary);
				font-size: 0.9rem;
				margin-bottom: 0.5rem;
				font-weight: 400;
				text-transform: uppercase;
			}
		}

		input {
			box-sizing: border-box;
			width: 100%;
			border: none;
			border-bottom: 1.5px solid rgb(215, 215, 215);
			background-color: transparent;
			font-size: 1.25rem;
			padding: 0.25rem;

			&::placeholder {
				color: var(--lightGrey);
				font-style: oblique;
			}

			&.has-error {
				border-bottom: 1.5px solid var(--danger);
			}

			&:disabled {
				color: var(--lightGrey);
				cursor: not-allowed;
			}
		}

		p {
			font-size: 0.85rem;
			margin-top: 0.25rem;
		}

		.is-small {
			width: auto;
			min-width: 12rem;
		}

		.force-capitals {
			text-transform: uppercase;
		}
	}
</style>
