<template functional>

	<section :class="[ 'loading-overlay', { 'is-dark': props.isDark } ]">
        <slot/>
    </section>

</template>





<script>

    export default {

        name: 'Overlay',
        props: {

            isDark: {
                type: Boolean,
                required: false
            }

        }

    };

</script>





<style lang="scss" scoped>

    .loading-overlay {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 20;
        background-color: rgba(255, 255, 255, 0.9);

        &.is-dark {
            background-color: var(--black-75);
        }

    }

</style>