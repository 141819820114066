export default {
	NONE: 0,
	SUBMITTED: 1,
	DECISIONED: 2,
	INFO_NEEDED: 3,
	PROCESSING: 4,
	FINALIZING: 5,
	COMPLETED: 6,
	PENDING_DECISION: 7,
	CONVEY_DECISION: 8,
	CLOSING_SCHEDULED: 9,
	OFFER_EXPIRED: 10,
	0: 'None',
	1: 'Submitted',
	2: 'Decisioned',
	3: 'Info Needed',
	4: 'Processing',
	5: 'Finalizing',
	6: 'Completed',
	7: 'Pending Decision',
	8: 'Ready to Convey Decision',
	9: 'Closing Scheduled',
	10: 'Offer Expired'
};
