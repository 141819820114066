<template functional>

    <path class="st0" d="M17.9,6.3C18,6.1,18,6,18,5.8s0-0.3-0.1-0.4S17.7,5.1,17.6,5L13,0.3c-0.4-0.4-1.2-0.5-1.6,0c0,0,0,0,0,0
	c-0.4,0.4-0.5,1.2,0,1.6c0,0,0,0,0,0L14,4.7H1.2C0.5,4.7,0,5.2,0,5.8S0.5,7,1.2,7H14l-2.7,2.7c-0.4,0.4-0.5,1.2,0,1.6c0,0,0,0,0,0
	c0.4,0.4,1.2,0.4,1.6,0l4.7-4.7L17.9,6.3z"/>

</template>





<script>

    export default {

        name: 'ArrowIcon'

    }

</script>