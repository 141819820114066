<template functional>

    <g>
        <path class="st0" d="M17.9,8.8c-0.2-0.2-4.1-6.1-9-6.1c-4.9,0-8.7,5.8-8.9,6.1C0,8.9,0,9.1,0.1,9.2c0.2,0.2,4.1,6.1,9,6.1
            c4.9,0,8.7-5.8,8.9-6.1C18,9.1,18,8.9,17.9,8.8z M9,14.5C5,14.5,1.6,10,0.8,9C1.6,8,5,3.5,9,3.5c4,0,7.5,4.5,8.2,5.6
            C16.4,10,13,14.5,9,14.5z"/>
        <path class="st0" d="M11.2,7c-0.5-0.6-1.3-0.9-2.1-0.9c-0.5,0-0.9,0.1-1.3,0.3C7.7,6.5,7.6,6.6,7.6,6.7c0,0.1,0,0.2,0.1,0.3
            c0.1,0.1,0.1,0.3,0.1,0.5c0,0.4-0.4,0.8-0.8,0.8c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.1-0.3,0S6.2,8.3,6.2,8.4l0,0.1
            c0,0.2-0.1,0.3-0.1,0.5c0,1.6,1.3,2.9,2.9,2.9S12,10.6,12,9C12,8.3,11.7,7.5,11.2,7z M9,11.2c-1.2,0-2.2-1-2.2-2.1c0,0,0,0,0,0
            C6.9,9,7,9,7.1,9c0.8,0,1.5-0.7,1.5-1.5c0-0.2,0-0.4-0.1-0.5c0.2-0.1,0.4-0.1,0.6-0.1c0.6,0,1.1,0.2,1.5,0.7
            C11,7.9,11.2,8.5,11.2,9C11.2,10.2,10.3,11.2,9,11.2z"/>
        <path class="st0" d="M11.8,4.6l-0.4,0.7c1.2,0.7,2.1,2.2,2.1,3.8c0,1.6-0.8,3.1-2.1,3.8l0.4,0.7c1.5-0.8,2.4-2.6,2.4-4.5
            C14.3,7.2,13.3,5.4,11.8,4.6z"/>
        <path class="st0" d="M4.5,9c0-1.6,0.8-3.1,2.1-3.8L6.2,4.6C4.7,5.4,3.8,7.2,3.8,9c0,1.9,1,3.6,2.4,4.5l0.4-0.7
            C5.3,12.2,4.5,10.6,4.5,9z"/>
    </g>

</template>





<script>

    export default {

        name: 'EyeIcon'

    }

</script>