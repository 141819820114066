import Vue from 'vue';
import Router from 'vue-router';

import handleGetError from '@/utils/handleGetError';
import routes from './routes';
import { enforceRoute, resetAppState, setPageData } from './middleware';
import store from '@/store';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		
		return typeof window.scroll === 'function'
			? window.scroll({ top: 0, left: 0, behavior: 'smooth' })
			: { x: 0, y: 0 };
		
	},
	routes
});

router.beforeEach(async (incoming, last, next) => {

	try {

		const redirect = await enforceRoute(incoming);

		if (redirect) { return next(redirect) };
		
		if (incoming.meta.resetState) { resetAppState() };

		await setPageData(incoming);

        if (incoming.meta.bypassEnforcer) {

			if (!incoming.params.sessionExpired) {

				store.commit('sessionTimer/resetTimers');

            }

        } else {

			if (!incoming.params.sessionExpired) {

				await store.dispatch('sessionTimer/reset');

            }

        };

		return next();

	} catch (error) {

		return handleGetError(error, incoming);

	};

});

export default router;