import { challengeAPI } from '@/api';
import { objectMutations } from '../shared';

export default {
	namespaced: true,
	state: {
		challengeId: '',
		challengeActionResult: 0,
		challengeDestinations: []
	},
	mutations: {
		...objectMutations
	},
	actions: {
		resetState(state) {
			state.challengeId = '';
			state.challengeActionResult = 0;
			state.challengeDestinations = [];
		},
		async getChallengeDestinations({ commit, state, rootState }) {
			try {
				if (state.challengeDestinations != null && state.challengeDestinations.length > 0) {
					return {
						challengeDestinations: state.challengeDestinations
					};
				}

				const challengeDestinations = await challengeAPI.getChallengeDestinations(
					rootState.applicant.applicantToken
				);

                commit('setData', { challengeDestinations });

				return challengeDestinations;
			} catch (error) {
				throw error;
			}
		},
		async createChallenge({ commit, rootState }, request) {
			try {
				const challengeId = await challengeAPI.createChallenge({
					...request,
					ApplicantToken: rootState.applicant.applicantToken
				});

				commit('setData', { challengeId: challengeId });

				return challengeId;
			} catch (error) {
				throw error;
			}
		},
		async createChallengeAction({ commit }, request) {
			try {
				const {
                    result,
                    sessionToken,
                    authenticated
                } = await challengeAPI.createChallengeAction(request);

				commit('setData', { challengeActionResult: result });
                commit('applicant/setData', { objName: 'authenticated', data: authenticated }, { root: true });

				return result;
			} catch (error) {
				throw error;
			}
		}
	},
	getters: {}
};
