export default {
    AUTHENTICATE : 1,
    NEW_APPLICANT : 2,
    LOAD_MEMBER : 3,
    ELIGIBILITY_LOAD : 4,
    ELIGIBILITY_CREATE : 5,
    ADDRESS_LOAD : 7,
    ADDRESS_CREATE : 8,
    ADDRESS_UPDATE : 9,
    ADDRESS_DELETE : 10,
    EMPLOYMENT_LOAD : 11,
    EMPLOYMENT_CREATE : 12,
    EMPLOYMENT_UPDATE : 13,
    EMPLOYMENT_DELETE : 14,
    INCOME_SOURCE_LOAD : 15,
    INCOME_SOURCE_CREATE : 16,
    INCOME_SOURCE_UPDATE : 17,
    INCOME_SOURCE_DELETE : 18,
    IDENTIFICATION_LOAD : 19,
    IDENTIFICATION_CREATE : 20,
    IDENTIFICATION_UPDATE : 21,
    LOAN_TYPE_LOAD : 22,
    LOAN_TYPE_UPDATE : 23,
    DISCLOSURE_CREATE : 24,
    CO_APPLICANT_CREATE : 26,
    APPLICANT_UPDATE : 27,
    SELECT_CO_APPLICANT : 28,
    LOAN_DETAIL_LOAD : 29,
    LOAN_DETAIL_UPDATE : 30,
    SUBMIT_APPLICATION : 31,
    IDENTITY_VERIFICATION_LOAD : 32,
    IDENTITY_VERIFICATION_SUBMIT : 33,
    REVIEW_INFORMATION_SUBMIT : 34,
    APPLICATION_IN_REVIEW : 35,
    APPLICATION_DECLINED : 36,
    APPLICATION_APPROVED : 37,
    PROPERTY_ADDRESS_LOAD : 38,
    PROPERTY_ADDRESS_UPDATE : 39,
    PROPERTY_TYPE_UPDATE : 40,
    DEMOGRAPHIC_LOAD : 41,
    DEMOGRAPHIC_CREATE : 42,
    DEMOGRAPHIC_UPDATE : 43,
    SITE_AVAILABILITY_LOAD: 44,
    ADDRESS_LOAD_CO : 45,
    ADDRESS_CREATE_CO : 46,
    ADDRESS_UPDATE_CO : 47,
    ADDRESS_DELETE_CO : 48,
    EMPLOYMENT_LOAD_CO : 49,
    EMPLOYMENT_CREATE_CO : 50,
    EMPLOYMENT_UPDATE_CO : 51,
    EMPLOYMENT_DELETE_CO : 52,
    INCOME_SOURCE_LOAD_CO : 53,
    INCOME_SOURCE_CREATE_CO : 54,
    INCOME_SOURCE_UPDATE_CO : 55,
    INCOME_SOURCE_DELETE_CO : 56,
    IDENTIFICATION_LOAD_CO : 57,
    IDENTIFICATION_CREATE_CO : 58,
    IDENTIFICATION_UPDATE_CO : 59,
    DEMOGRAPHIC_GET_CO : 60,
    DEMOGRAPHIC_CREATE_CO : 61,
    DEMOGRAPHIC_UPDATE_CO : 62,
    DEMOGRAPHIC_UPDATE_CO : 63,
    WELCOME_LETTER_LOAD : 64,
    APPLICATION_INELIGIBLE : 65,
    MEMBERSHIP_INELIGIBLE : 66,
    UNSUPPORTED_PROPERTY : 67,
    RESUME_LOAD : 68,
    RESUME_VERIFICATION : 69,
    DUPLICATE_DETECTION_CHECK : 70,
    DUPLICATE_DETECTION_DECISION : 71,
    DUPLICATE_DETECTION_CANCEL : 72,
    2: 'NewApplicant',
    3: 'LoadMember',
    4: 'EligibilityLoad',
    5: 'EligibilityCreate',
    7: 'AddressLoadPrimary',
    8: 'AddressCreatePrimary',
    9: 'AddressUpdatePrimary',
    10: 'AddressDeletePrimary',
    11: 'EmploymentLoadPrimary',
    12: 'EmploymentCreatePrimary',
    13: 'EmploymentUpdatePrimary',
    14: 'EmploymentDeletePrimary',
    15: 'IncomeSourceLoadPrimary',
    16: 'IncomeSourceCreatePrimary',
    17: 'IncomeSourceUpdatePrimary',
    18: 'IncomeSourceDeletePrimary',
    19: 'IdentificationLoadPrimary',
    20: 'IdentificationCreatePrimary',
    21: 'IdentificationUpdatePrimary',
    22: 'LoanTypeLoad',
    23: 'LoanTypeUpdate',
    24: 'DisclosureCreate',
    26: 'CoApplicantCreate',
    27: 'ApplicantUpdate',
    28: 'SelectCoApplicant',
    29: 'LoanDetailLoad',
    30: 'LoanDetailUpdate',
    31: 'SubmitApplication',
    32: 'IdentityVerificationLoad',
    33: 'IdentityVerificationSubmit',
    34: 'ReviewInformationSubmit',
    35: 'ApplicationInReview',
    36: 'ApplicationDeclined',
    37: 'ApplicationApproved',
    38: 'PropertyAddressLoad',
    39: 'PropertyAddressUpdate',
    40: 'PropertyTypeUpdate',
    41: 'DemographicGetPrimary',
    42: 'DemographicCreatePrimary',
    43: 'DemographicUpdatePrimary',
    44: 'SiteAvailabilityLoad',
    45: 'AddressLoadCo',
    46: 'AddressCreateCo',
    47: 'AddressUpdateCo',
    48: 'AddressDeleteCo',
    49: 'EmploymentLoadCo',
    50: 'EmploymentCreateCo',
    51: 'EmploymentUpdateCo',
    52: 'EmploymentDeleteCo',
    53: 'IncomeSourceLoadCo',
    54: 'IncomeSourceCreateCo',
    55: 'IncomeSourceUpdateCo',
    56: 'IncomeSourceDeleteCo',
    57: 'IdentificationLoadCo',
    58: 'IdentificationCreateCo',
    59: 'IdentificationUpdateCo',
    60: 'DemographicGetCo',
    61: 'DemographicCreateCo',
    62: 'DemographicUpdateCo',
    63: 'DemographicDefaultCo',
    64: 'WelcomeLetterLoad',
    65: 'ApplicationIneligible',
    66: 'MembershipIneligible',
    67: 'UnsupportedProperty',
    68: 'ResumeLoad',
    69: 'ResumeVerification',
    70: 'DuplicateDetectionCheck',
    71: 'DuplicateDetectionDecision',
    72: 'DuplicateDetectionCancel'
};