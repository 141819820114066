<template>
	<div class="warning-container">
		<p>{{ message }}</p>
		<button class="action" @click.stop="resetTimer">Renew Session</button>
	</div>
</template>





<script>
import { createToastInterface } from 'vue-toastification';
import { toastConfig } from '@/utils';

const toast = createToastInterface(toastConfig);

export default {
	name: 'SessionWarning',
	props: {
		message: {
			type: String,
			required: true
		},
		renewAction: {
			type: Function,
			required: true
		}
	},
	data() {
		return {
			disabled: false
		};
	},
	methods: {
		async resetTimer() {
			try {
				this.disabled = true;

				await this.renewAction();

				return (this.disabled = false);
			} catch (error) {
				toast.error(error);

				return (this.disabled = false);
			}
		}
	}
};
</script>





<style lang="scss" scoped>
.warning-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		font-size: 0.75rem;
	}

	.action {
		font-size: 0.65rem;
		margin-left: 1rem;
		padding: 0.5rem;
		border-radius: 0.5rem;
		color: white;
		cursor: pointer;
		border: thin solid currentColor;
		background: transparent;

		&:disabled {
			cursor: not-allowed;
			background: rgba(255, 255, 255, 0.2);
		}
	}
}
</style>