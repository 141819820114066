<template>
	<div id="disclaimer-container">
		<PortalTarget name="disclaimers" />
		<section class="disclaimer" v-if="isHELOC">
			<BaseIcon class="disclaimer-content" title="Equal Housing Opportunity" name="Equal Housing Opportunity" height="80" width="80" viewBox="0 0 200 200">
				<EHOLogo />
			</BaseIcon>
		</section>
		<section class="disclaimer">
			<NCUALink class="disclaimer-content" />
		</section>
	</div>
</template>

<script>
import { ProductType } from '@/constants';
import { NCUALink } from '@/components';
import { BaseIcon, EHOLogo } from '@/components/SwitchThink/icons';
import { PortalTarget } from 'portal-vue';

export default {
	name: 'Disclaimer',
	components: {
		NCUALink,
		BaseIcon,
		EHOLogo,
		PortalTarget
		},
	data() {
			return {
			};
        },
	props: {
		productTypeId: {
			type: Number,
			required: false
		}
	},
	computed: {
		isHELOC: function () {
			return this.productTypeId === ProductType.HELOC;
		}
	}
};
</script>
<style lang="scss" scoped>
  #disclaimer-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 0.7rem;
    width: 90%;

    .disclaimer {
      margin-top: 1rem;
    }

    .disclaimer-content {
      margin: 0 auto 0 auto;
      width: 100%;
    }
}
</style>