<template>
	<div class="auto-container" data-test-label>
		<header :class="['input-header', { 'is-small': isSmall }]">
			<label :class="{ 'has-error': error }" :for="id" data-test-label>
				{{ label }}
				<span v-if="isOptional & !disabled" data-test-optional>(OPTIONAL)</span>
			</label>
		</header>

		<Multiselect
			v-model="innerValue"
			:id="id"
			:trackBy="searchKey"
			:label="searchKey"
			:placeholder="placeholder"
			:options="filterOptions"
			:allowEmpty="true"
			:disabled="disabled"
			:loading="loading"
			internalSearch
			deselectLabel=""
			:class="{ error: error }"
			@search-change="query = $event"
			@close="$emit('blur')"
		/>

		<transition name="fade">
			<p class="has-error" v-if="error" data-test-error>
				{{ error }}
			</p>
		</transition>
	</div>
</template>


<script>
import Multiselect from 'vue-multiselect';

export default {
	name: 'Autocomplete',
	components: {
		Multiselect
	},
	data() {
		return {
			query: ''
		};
	},
	props: {
		value: {
			required: true
		},
		label: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			required: false
		},
		options: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		},
		loading: {
			type: Boolean,
			required: false
		},
		error: {
			type: String,
			required: false
		},
		searchKey: {
			type: String,
			required: false
		},
		isOptional: {
			type: Boolean,
			required: false,
			default: false
		},
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		innerValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		},
		filterOptions() {
			if (this.query) {
				const query = this.query.toLowerCase();
				let filtered = [];

				this.options.forEach(obj => {
					const splicedItem = obj[this.searchKey].toLowerCase().split(' ');
					for (var word of splicedItem) {
						if (word.startsWith(query.toLowerCase())) {
							filtered.push(obj);
						}
					}
				});

				return filtered;
			}
			return this.options;
		},
		id() {
			return `${this.label.replace(/\s/g, '')}-${this._uid}`;
		}
	}
};
</script>


<style lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.multiselect {
	&.error .multiselect__tags {
		border-bottom: 1.5px solid var(--danger);
	}

	.multiselect__select {
		width: auto;
		height: 100%;
		top: 6px;
		padding: 4px 6px;

		&::before {
			border-width: 5.2px 2.6px 0;
		}
	}

	.multiselect__tags {
		border: none;
		border-radius: 0;
		border-bottom: 1.5px solid rgb(215, 215, 215);
		font-size: 1.25rem;
		background: transparent;

		.multiselect__input,
		.multiselect__single {
			font-size: 1.25rem;
			padding: 0;
			color: #000;
			background: transparent;
		}

		.multiselect__input,
		.multiselect__placeholder {
			font-size: 1.25rem;
			color: var(--lightGrey);
			font-style: oblique;
			background: transparent;
		}

		&.has-error {
			border-bottom: 1.5px solid var(--danger);
		}
	}
}
</style>

<style lang="scss" scoped>
.auto-container {
	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	.has-error {
		color: var(--danger);
	}

	.input-header {
		align-items: baseline;

		label {
			display: block;
			color: var(--primary);
			font-size: 0.8rem;
			margin-bottom: 0.5rem;
			font-weight: 400;
			text-transform: uppercase;
		}
	}

	p {
		font-size: 0.85rem;
		margin-top: 0.25rem;
	}

	.is-small {
		width: auto;
		min-width: 12rem;
	}
}
</style>