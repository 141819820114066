<template functional>

    <g>
        <path d="M16.2,10.8l-3.7,3.7l-1.6-1.6l-1.8,1.8l3.4,3.4l5.4-5.4L16.2,10.8z M10.3,14.6l0.6-0.6l1.6,1.6l3.7-3.7
            l0.6,0.6l-4.3,4.3L10.3,14.6z"/>
        <path d="M4,14.7c-1.7-1.2-2.8-3-3.1-5C0.3,5.6,3.1,1.6,7.3,0.9c2-0.3,4.1,0.1,5.7,1.4c1.7,1.2,2.8,3,3.1,5
            c0.1,0.9,0.1,1.9-0.1,2.9l0.8,0.2c0.2-1.1,0.2-2.2,0.1-3.2c-0.4-2.2-1.6-4.2-3.4-5.6c-1.8-1.3-4.1-1.9-6.3-1.5
            C2.6,0.9-0.6,5.3,0.1,9.9c0.4,2.2,1.6,4.2,3.4,5.5C5,16.5,6.7,17,8.5,17c0.5,0,0.9,0,1.4-0.1l-0.1-0.8C7.7,16.4,5.7,15.9,4,14.7z"
            />
        <path d="M10.6,7.5V7l0-0.1c-0.1-0.8-0.7-1.3-1.5-1.3H8.9V4.4H8.1v1.2H8C7.2,5.7,6.5,6.2,6.5,7l0,0.6
            c0.1,0.6,0.5,1.1,1,1.2l1.8,0.5c0.2,0,0.4,0.2,0.4,0.4v0.6c-0.1,0.4-0.3,0.6-0.7,0.6H8c-0.4,0-0.7-0.3-0.7-0.6V9.9H6.5l0,0.5
            c0.1,0.8,0.7,1.3,1.5,1.3h0.1V13h0.8v-1.2H9c0.8,0,1.4-0.5,1.5-1.3l0,0l0-0.7c-0.1-0.6-0.5-1.1-1-1.2L7.7,8.1
            C7.5,8,7.3,7.8,7.3,7.7l0-0.6c0-0.3,0.3-0.6,0.7-0.6h1.1c0.4,0,0.6,0.2,0.7,0.6v0.5H10.6z"/>
    </g>

</template>





<script>

    export default {

        name: 'Payment'

    }

</script>