import axiosLib from 'axios';
import store from '@/store';
import router from '@/router';
import { constructUrl } from '@/utils'

const axios = axiosLib.create({ timeout: 0 });

axios.interceptors.request.use(

    async config => {

        const token = store.getters.xsrfToken;
        const sessionToken = store.getters["applicant/sessionToken"];

        if (token) { config.headers['X-XSRF-TOKEN'] = token };
        if (sessionToken) { config.headers['SessionToken'] = sessionToken };

        config.headers['Content-Type'] = 'application/json';

        return config;

    },
    error => {

        return Promise.reject(error);

    }

)

axios.interceptors.response.use(

    async response => { return response; },
    async error => {

        if (error.response.status == 401) {
            router.push(constructUrl('Authentication'))
        }

        return Promise.reject(error);
    }

);

export default axios;
export { axios };