<template functional>
	<path
		d="M7.7,18c-0.1,0-0.1,0-0.2,0C7.4,18,7.2,18,7.1,17.9C7,17.8,7,17.6,7,17.4l-3.7-3.7l0,0l-0.5-0.5l-1.3,1.3l2.7,2.7
		c0.1,0.1,0.1,0.4,0,0.5s-0.4,0.1-0.5,0l-3-3c-0.1-0.1-0.1-0.4,0-0.5l1.9-1.9c0.1-0.1,0.4-0.1,0.5,0l0.4,0.4C3.8,12.4,4.1,12,4.1,12
		c0-0.1,0-0.5,0.1-0.8c0-0.7,0-1.5,0.3-2.2C4.8,8.2,4.9,7.8,5.1,7c0.1-0.4,0.3-0.9,0.5-1.5C5.7,5.2,5.8,5.1,6,5
		c0.1-0.1,0.2-0.1,0.3-0.1V1.5C6.3,0.7,6.9,0,7.7,0h6.4c0.8,0,1.5,0.7,1.5,1.5v1.2c0.4-0.2,1-0.1,1.3,0.3l0,0c0.5,0.5,0.5,1.2,0,1.7
		l-0.4,0.3c0.1,0,0.1,0.1,0.2,0.1l0,0c0.2,0.2,0.4,0.4,0.4,0.8c0,0.3,0,0.6-0.2,0.9l0,0l-0.4,0.4c0.1,0,0.1,0.1,0.2,0.1l0,0l0,0
		c0.5,0.4,0.6,1.2,0.1,1.6l-0.4,0.6c0.1,0,0.1,0.1,0.2,0.1l0,0l0,0c0.5,0.4,0.5,1.1,0.1,1.6l-0.7,0.9c-0.1,0.1-0.3,0.2-0.5,0.3
		c0,0-0.1,0-0.1,0v0.4c0,0.8-0.7,1.5-1.5,1.5h-0.9c-0.4,0.6-1.7,2.4-4.5,2.2c-0.2,0.1-0.4,0.4-0.6,0.6c0.1,0.1,0.1,0.3-0.1,0.4
		C7.9,18,7.8,18,7.7,18z M4.1,13.5l3.4,3.4c0.2-0.2,0.5-0.5,0.7-0.7c0.1-0.1,0.2-0.1,0.3-0.1c2,0.1,3.1-0.8,3.7-1.5h-4
		c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.2-0.3,0.3-0.5,0.2S7.3,15,7.4,14.8c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.1-0.2-0.1-0.3
		c0-0.2,0.1-0.3,0.3-0.4c0.2-1.4-0.1-2.8-0.9-4c-0.1-0.1-0.1-0.2,0-0.3c0.3-0.8,0.4-1.7,0.5-2.6C7.2,6.4,7,6,6.7,5.6l0,0
		c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1C6.1,6.2,5.9,6.7,5.8,7.1c-0.3,0.8-0.4,1.3-0.7,2c-0.2,0.6-0.2,1.3-0.3,2
		c0,0.5,0,0.9-0.1,1.1C4.8,12.3,4.8,12.4,4.1,13.5z M13,13.9h1.1c0.4,0,0.7-0.3,0.7-0.7v-0.7l0,0l0,0l-0.1,0
		c-0.3-0.2-0.4-0.6-0.5-0.9c0-0.2,0-0.4,0.2-0.6l0.6-0.7c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0l-0.1,0c-0.3-0.3-0.5-0.6-0.5-0.9
		c0-0.2,0-0.4,0.2-0.6l0.6-0.7c-0.1,0-0.1-0.1-0.2-0.1l0,0l0,0c-0.5-0.4-0.6-1-0.3-1.4L15,5.7c-0.1-0.1-0.3-0.1-0.4-0.3l0,0
		l-0.1-0.1C14,4.8,14,4.2,14.3,3.8l0.5-0.5V1.5c0-0.4-0.3-0.7-0.7-0.7H7.7C7.3,0.7,7,1.1,7,1.5v3.4c0.1,0.1,0.3,0.2,0.4,0.3
		C7.8,5.7,8,6.3,7.9,7C7.9,7.9,7.7,8.7,7.4,9.6c0.8,1.3,1.2,2.8,0.9,4.3H13L13,13.9z M15.4,11.8c0.2,0.1,0.3,0.2,0.4,0.1l0.7-0.9
		c0.1-0.1,0.1-0.4,0-0.6l-0.1-0.1c-0.2-0.1-0.4-0.1-0.6,0l-0.7,0.9c0,0,0,0.2,0.2,0.3L15.4,11.8z M15.3,9.4
		C15.3,9.4,15.3,9.5,15.3,9.4c0.3,0.2,0.4,0.2,0.5,0.2l0.7-0.9c0.1-0.2,0.1-0.4,0-0.6l-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.1
		c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0.1l-0.7,0.9C15.1,9.1,15.1,9.3,15.3,9.4L15.3,9.4z M15.4,7.2c0.1,0.1,0.2,0.1,0.3,0.1
		c0,0,0.1,0,0.1,0l0.7-0.8c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.3l-0.1-0.1c-0.2-0.1-0.4-0.1-0.6,0L15,6.8
		c0,0,0,0.2,0.2,0.4L15.4,7.2C15.4,7.2,15.4,7.2,15.4,7.2z M15.1,4.8C15.1,4.8,15.1,4.8,15.1,4.8c0.2,0.2,0.4,0.3,0.5,0.3l0.9-0.8
		c0.1-0.2,0.1-0.4,0-0.6l-0.1,0c-0.2-0.2-0.5-0.2-0.7,0l-0.9,0.8C14.8,4.4,14.9,4.6,15.1,4.8L15.1,4.8z M3.3,15.4
		c-0.1,0-0.2,0-0.3-0.1l-0.4-0.4c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.1,0.4-0.1,0.5,0l0.4,0.4c0.1,0.1,0.1,0.4,0,0.5
		C3.4,15.3,3.3,15.4,3.3,15.4z M11.5,13.5h-1.1c-0.2,0-0.4-0.1-0.4-0.4s0.1-0.4,0.4-0.4h1.1c0.2,0,0.4,0.1,0.4,0.4
		S11.7,13.5,11.5,13.5z M13,11.6H9.2c-0.2,0-0.4-0.1-0.4-0.4V7.9c0-0.2,0.1-0.4,0.4-0.4h2.6c0.2,0,0.4,0.1,0.4,0.4
		c0,0.2-0.1,0.4-0.4,0.4H9.6v2.6h3V9.4C12.6,9.1,12.8,9,13,9s0.4,0.1,0.4,0.4v1.9C13.4,11.5,13.2,11.6,13,11.6z M11.1,10.5
		C11.1,10.5,11.1,10.5,11.1,10.5c-0.1,0-0.3-0.1-0.3-0.1l-0.7-1.1C10,9,10,8.8,10.2,8.7c0.2-0.1,0.4-0.1,0.5,0.1l0.5,0.7L12.7,8
		c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.4,0,0.5l-1.9,1.9C11.3,10.5,11.2,10.5,11.1,10.5z M13,6.4H9.2C9,6.4,8.9,6.2,8.9,6V2.6
		c0-0.2,0.1-0.4,0.4-0.4h2.6c0.2,0,0.4,0.1,0.4,0.4S12.1,3,11.9,3H9.6v2.6h3V4.1c0-0.2,0.1-0.4,0.4-0.4s0.4,0.1,0.4,0.4V6
		C13.4,6.2,13.2,6.4,13,6.4z M11.1,5.2C11.1,5.2,11.1,5.2,11.1,5.2c-0.1,0-0.3-0.1-0.3-0.1L10.1,4C10,3.8,10,3.6,10.2,3.4
		c0.2-0.1,0.4-0.1,0.5,0.1l0.5,0.7l1.5-1.6c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.4,0,0.5l-1.9,1.9C11.3,5.2,11.2,5.2,11.1,5.2z"
	/>
</template>





<script>
export default {
	name: 'MobileCheckIcon'
};
</script>