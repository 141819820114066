<template functional>
	<aside
		:class="[
			'notification',
			data.class,
			data.staticClass,
			{
				danger: props.isDanger
			}
		]"
		:style="[data.style, data.staticStyle]"
	>
		<h1>{{ props.title }}</h1>
		<div v-if="props.body" v-html="props.body" class="body"></div>
		<div v-else><slot /></div>
	</aside>
</template>





<script>
export default {
	name: 'Notification',
	props: {
		title: {
			type: String,
			required: true
		},
		body: {
			type: String,
			required: false
		},
		isDanger: {
			type: Boolean,
			required: false
		}
	}
};
</script>





<style lang="scss" scoped>
.notification {
	background: var(--white-50);
	padding: 1rem;
	margin-bottom: 1rem;
	border: 1px solid var(--primary);

	&.danger {
		border: 1px solid var(--danger);

		h1 {
			color: var(--danger);
		}
	}

	h1 {
		color: var(--primary);
		font-weight: 700;
		font-size: 1.2rem;
		margin-bottom: 0.5rem;
	}

	.body {
		color: var(--bodyCopy);

		*,
		&::v-deep * {
			font-weight: 400 !important;
			font-size: 0.8rem !important;
		}
	}
}
</style>