import { brandingAPI } from '@/api';

export default {

    namespaced: true,
    state: {

        colors: null,
        images: null,
        name: '',
        CSSLoaded: false

    },
    mutations: {

        setData(state, { objName, data }) { state[objName] = data; },
        resetState(state) {

            state.colors = null;
            state.images = null;
            state.name = '';
            state.CSSLoaded = false;

        }

    },
    actions: {

        async getBranding({ commit, getters, state }) {

            if (getters.hasBranding) {

                if (!state.CSSLoaded) {

                    brandingAPI.createCSSVariables(state.colors);

                    commit('setData', { objName: 'CSSLoaded', data: true });

                };

                return {
                    colors: state.colors,
                    images: state.images,
                    name: state.name,
                };

            };

            try {

                const {
                    colors,
                    images,
                    name
                } = await brandingAPI.getConfig();

                commit('setData', { objName: 'colors', data: colors });
                commit('setData', { objName: 'images', data: images });
                commit('setData', { objName: 'name', data: name });
                commit('setData', { objName: 'CSSLoaded', data: true });

                return { colors, images, name };

            } catch (error) {

                throw error;

            };
             
        }

    },
    getters: {

        hasBranding: state => state.colors && state.images && state.name && state.CSSLoaded ? true : false

    }

};