<template functional>

    <g>
        <rect x="6.2" y="7.1" transform="matrix(0.707 -0.7073 0.7073 0.707 -3.3327 6.8011)" width="0.8" height="0.7"/>
        <path d="M8,8.8c-0.3,0.3-0.5,0.8-0.5,1.2S7.6,11,8,11.3c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5
            c-0.3-0.3-0.8-0.5-1.2-0.5C8.7,8.3,8.3,8.5,8,8.8z M9.9,10.8c-0.4,0.4-1,0.4-1.4,0c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
            c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3C10.3,9.8,10.3,10.4,9.9,10.8z"/>
        <polygon points="7.2,13.3 1.1,7.2 6.3,2 12.7,8.3 13.2,7.8 6.3,0.9 0,7.2 6.7,13.9 	"/>
        <path d="M15,6.8c0.5-2,0.2-3.7-0.9-4.7C13,1,11.9,1,10.4,1l0.5-0.5L10.3,0L8.9,1.4l1.4,1.4l0.5-0.5l-0.5-0.5c1.4,0,2.3,0,3.2,0.9
            c0.9,0.9,1.1,2.3,0.7,4c-0.4,1.8-1.5,3.6-3.1,5.2C8,15,3.9,16.1,2,14.3c-0.9-0.9-1.1-2.4-0.7-4.1L0.6,10c-0.6,2-0.2,3.7,0.9,4.8
            c0.8,0.8,1.8,1.1,3,1.1c2.2,0,4.9-1.3,7.2-3.6C13.4,10.7,14.6,8.8,15,6.8z"/>
        <path d="M12.5,9L7,3.5L6.8,3.8C6.4,4.2,6,3.9,5.9,3.8L5.6,3.5L2.7,6.5L3,6.7c0.1,0.1,0.4,0.5,0,0.9L2.7,7.9l5.5,5.5l0.5-0.5l-5-5
            C4,7.4,4,6.9,3.7,6.5l1.9-1.9c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.5-0.1,0.7-0.2l5,5L12.5,9z"/>
        <path d="M13.6,10.7c-0.5,0.5-0.6,1.1-0.2,1.7l-1.9,1.9c-0.5-0.3-1.2-0.2-1.7,0.2l0.5,0.5c0.1-0.1,0.3-0.2,0.5-0.2
            c0.1,0,0.3,0.1,0.4,0.2l0.3,0.3l2.9-2.9l-0.3-0.3c-0.1-0.1-0.4-0.5,0-0.9L13.6,10.7z"/>
        <polygon points="14.8,9.3 14.2,9.9 16,11.7 10.8,16.9 8.9,15 8.4,15.6 10.8,18 17.1,11.7 	"/>
    </g>

</template>





<script>

    export default {

        name: 'RevolvingMoney'

    }

</script>