<template functional>

    <g>
        <path class="st0" d="M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S14,0,9,0z M9,16.4c-4.1,0-7.4-3.3-7.4-7.4c0-4.1,3.3-7.4,7.4-7.4
            c4.1,0,7.4,3.3,7.4,7.4C16.4,13.1,13.1,16.4,9,16.4z"/>
        <path class="st0" d="M13.6,4.8c-0.4-0.3-0.9-0.2-1.1,0.1L7.6,11L6,9.2c-0.3-0.3-0.8-0.4-1.2,0c-0.3,0.3-0.4,0.8,0,1.2l2.9,3.2
            l6-7.6C14,5.6,14,5,13.6,4.8z"/>
    </g>

</template>





<script>

    export default {

        name: 'HollowCheckIcon'

    }

</script>