import { POSITION } from 'vue-toastification';

export default {
	transition: 'Vue-Toastification__bounce',
	maxToasts: 20,
	newestOnTop: true,
	position: POSITION.TOP_CENTER,
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	hideCloseButton: false,
	hideProgressBar: false,
    icon: true,
    filterBeforeCreate: (toast, toasts) => {
        if (typeof toast.content == "string")
        {
            return toast;
        }
        else if (typeof toast.content == "object")
        {
            if (toast.content.response && toast.content.response.status == "401") {
                //Filter out all 401 errors from displaying toasts
                return false;
            }
            toast.content = toast.content.message;

            return toast;
        }
    }
};
