<template functional>
	<g>
		<g fill="#203E77">
			<path
				d="M4.5,9.1V7.3h7.2v0.5h0.7V5c0-0.6-0.4-1-1-1H4.8c-0.6,0-1,0.5-1,1v4.1c0,0.5,0.5,1,1,1h0.6V9.4H4.8
			C4.6,9.4,4.5,9.3,4.5,9.1z M4.5,5c0-0.1,0.1-0.3,0.3-0.3h6.7c0.1,0,0.3,0.1,0.3,0.3v0.3H4.5V5z M4.5,6h7.2v0.6H4.5V6z"
			/>
			<path
				d="M13.2,7.9H6.6c-0.6,0-1,0.5-1,1V13c0,0.5,0.5,1,1,1h6.7c0.5,0,1-0.4,1-1V8.9C14.2,8.3,13.8,7.9,13.2,7.9z
			 M13.5,13c0,0.1-0.1,0.3-0.3,0.3H6.6c-0.2,0-0.3-0.1-0.3-0.3V8.9c0-0.1,0.1-0.3,0.3-0.3h6.7c0.1,0,0.3,0.1,0.3,0.3V13z"
			/>
			<rect x="6.7" y="12" width="1.1" height="0.7" />
			<rect x="8.5" y="12" width="1.1" height="0.7" />
			<rect x="10.2" y="12" width="1.1" height="0.7" />
			<rect x="11.9" y="12" width="1.1" height="0.7" />
			<path
				d="M7.4,11.2h1.1c0.4,0,0.8-0.4,0.8-0.8V10c0-0.4-0.4-0.8-0.8-0.8H7.4c-0.4,0-0.8,0.4-0.8,0.8v0.4
			C6.6,10.8,6.9,11.2,7.4,11.2z M7.3,10C7.3,9.9,7.3,9.9,7.3,10l1.2-0.1c0,0,0.1,0,0.1,0.1v0.4c0,0,0,0.1-0.1,0.1H7.4
			c0,0-0.1,0-0.1-0.1V10z"
			/>
		</g>
		<path
			fill="#2391EC"
			d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9s3.8,8.4,8.4,8.4
		s8.4-3.8,8.4-8.4S13.6,0.6,9,0.6z"
		/>
	</g>
</template>





<script>
export default {
	name: 'CardFunding'
};
</script>