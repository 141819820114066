var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.validationRules,"name":_vm.name,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('section',[_vm._l((_vm.options),function(option,index){return _c('div',{key:option.label,class:[
				'check-group',
				{
					'no-margin': index + 1 === _vm.options.length,
					'is-small': _vm.isSmall
				}
			]},[_c('StsCheckbox',{attrs:{"optionValue":option.value,"label":option.label,"name":_vm.name,"disabled":_vm.disabled || option.disabled,"hasError":errors.length > 0,"isSmall":_vm.isSmall},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}),(_vm.$slots.default && _vm.innerValue === option.value)?_vm._t("default"):_vm._e()],2)}),_c('transition',{attrs:{"name":"fade"}},[(errors.length > 0)?_c('p',{staticClass:"has-error",attrs:{"data-test-error":""}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }