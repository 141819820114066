<template functional>
	<div class="browser-message">
		<div class="wrapper">
			<div class="box">
				<div class="header">
					<h1>Sorry, your browser is not compatible with this page.</h1>
					<h2>To apply for a loan online, please use one of the following browsers:</h2>
				</div>

				<div class="content">
					<a href="https://www.google.com/chrome/" target="_blank" title="Download Chrome">
						<table>
							<tr>
								<td class="icon">
									<img src="./img/chrome.png" alt="Chrome logo" />
								</td>
								<td>Google Chrome</td>
							</tr>
						</table>
					</a>
					<a href="https://www.apple.com/safari/" target="_blank" title="Download Safari">
						<table>
							<tr>
								<td class="icon">
									<img src="./img/safari.png" alt="Safari logo" />
								</td>
								<td>Apple Safari</td>
							</tr>
						</table>
					</a>
					<a href="https://www.mozilla.org/en-US/firefox/" target="_blank" title="Download Firefox">
						<table>
							<tr>
								<td class="icon">
									<img src="./img/firefox.png" alt="Firefox logo" />
								</td>
								<td>Mozilla Firefox</td>
							</tr>
						</table>
					</a>
					<a href="https://www.microsoft.com/en-us/edge" target="_blank" title="Download Edge">
						<table>
							<tr>
								<td class="icon">
									<img src="./img/edge.png" alt="Edge logo" />
								</td>
								<td>Microsoft Edge</td>
							</tr>
						</table>
					</a>
					<!-- <a href="https://www.opera.com/" target="_blank" title="Download Opera">
                        <table>
                            <tr>
                                <td class="icon">
                                    <img src="./img/opera.png" alt="Opera logo">
                                </td>
                                <td>
                                    Opera
                                </td>
                            </tr>
                        </table>
                    </a> -->
				</div>
			</div>
		</div>
	</div>
</template>





<script>
export default {
	name: 'BrowserMessage'
};
</script>





<style lang="scss" scoped>
.browser-message {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 99999;
	display: table;
	width: 100%;
	height: 100%;
	background: #fff;

	.wrapper {
		display: table-cell;
		vertical-align: middle;

		.box {
			max-width: 33rem;
			margin: 0 auto;
			border: 2px solid #404041;

			.header {
				background-color: #404041;
				padding: 1rem;
				color: #fff;
				text-align: center;

				h1 {
					font-size: 1.25rem;
					font-weight: 400;
					margin: 0;
					margin-bottom: 0.5rem;
					padding: 0;
				}

				h2 {
					font-size: 0.875rem;
					font-weight: 300;
					margin: 0;
					padding: 0;
				}
			}

			.content {
				padding: 1rem;

				a {
					display: block;
					color: #00b3e7;

					&:hover {
						color: #939597;

						img {
							opacity: 0.7;
						}
					}
				}

				table {
					width: 100%;

					td {
						padding: 0.5rem;

						&.icon {
							width: 37.5%;

							img {
								float: right;
							}
						}
					}
				}
			}
		}
	}
}
</style>