<template>
	<article v-show="isActive">
		<slot />
	</article>
</template>





<script>
export default {
	name: 'WizardTab',
	data() {
		return {
			isActive: false
		};
	}
};
</script>