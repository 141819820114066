<template functional>
	<g>
		<path
			fill="#003C7B"
			d="M12.4,3c1.8,0,3.2,1.4,3.2,3.2s-1.4,3.2-3.2,3.2S9.2,8,9.2,6.2S10.6,3,12.4,3z M16.9,13.1
		c0-0.1,0.1-0.1,0.1-0.2c0.4-0.6,0.5-1.3,0.6-2c-0.6-2.2-2.5-2.2-2.5-2.2c-1.5,1.5-2.6,1.3-2.6,1.3c-1.5,0.1-3-1.1-3-1.1
		c-1.9-0.1-2.3,1.7-2.3,1.7c-0.8,1.7-0.3,4.9-0.3,4.9c1.9,1.3,4.4,1.6,5.4,1.7c1-0.7,2.1-1.3,3-2.1c0.1-0.1,0.1-0.2,0.2-0.2
		C15.9,14.2,16.4,13.7,16.9,13.1L16.9,13.1z"
		/>
		<path
			fill="#ADB4BA"
			d="M6.6,7.1c1.4,0,2.6,1.2,2.6,2.6S8,12.3,6.6,12.3S4,11.1,4,9.7S5.1,7.1,6.6,7.1z M10.9,13.8c-0.4-2-2.1-2-2.1-2
		c-1.2,1.2-2.2,1.1-2.2,1.1c-1.2,0.1-2.5-1-2.5-1c-1.6-0.1-1.9,1.4-1.9,1.4c-0.1,0.3-0.2,0.6-0.3,1c0,0.1,0.1,0.1,0.1,0.2
		s0.1,0.2,0.1,0.3c0.2,0.1,0.3,0.1,0.4,0.3c0.3,0.1,0.5,0.3,0.6,0.5c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0,0.1,0.1,0.2,0.1
		c0.8,0.4,1.6,0.7,2.3,1c0.8,0.3,1.6,0.4,2.4,0.4c0.5,0,1,0,1.4,0.2c0.1,0,0.1,0,0.2,0c0.4-0.3,0.7-0.5,0.7-0.5
		C11.3,15,10.9,13.8,10.9,13.8z"
		/>
		<path
			fill="#2391EC"
			d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9s3.8,8.4,8.4,8.4
		s8.4-3.8,8.4-8.4S13.6,0.6,9,0.6z"
		/>
	</g>
</template>





<script>
export default {
	name: 'JointOwner'
};
</script>