import { applicationAPI, collateralAPI, documentAPI, noteAPI, stipulationAPI, taskAPI, welcomeMessageAPI } from '@/api';
import { CollateralType, DocumentSearchStatus, DocumentType, LoanDecision } from '@/constants';
import { formatMoney, formatRate } from '@/utils';
import application from '../../api/application';

const state = {
	applicationId: 0,
	applicationToken: '',
	externalApplicationId: '',
	productTypeId: 0,
	loanStatus: 0,
	loanDecision: 0,
	submitDate: '',
	loanDetail: null,
	loanCollateral: null,
	loanTasks: [],
	documentSyncResult: 0,
	documents: [],
	applicationStatus: [],
	stipulations: [],
	notes: []
};

/**
 * @param state
 */
function resetState(state) {
	state.applicationId = 0;
	state.applicationToken = '';
	state.externalApplicationId = '';
	state.productTypeId = 0;
	state.loanStatus = 0;
	state.loanDecision = 0;
	state.submitDate = '';
	state.loanDetail = null;
	state.loanCollateral = null;
	state.loanTasks = [];
	state.documentSyncResult = 0;
	state.documents = [];
	state.applicationStatus = [];
	state.stipulations = [];
	state.notes = [];
}

const mutations = {};

const actions = {
	setApplication({ commit }, applicationId) {
		commit('setData', { objName: 'applicationId', data: applicationId });
	},
	async getApplication({ commit, state }) {
		if (state.externalApplicationId) {
			return state;
		}

		try {
			const {
				applicationToken,
				loanConfigurationId,
				applicationStatus,
				decisionStatus,
				submitDate,
				externalApplicationId,
				downPayment,
				loanRate,
				loanTermInMonths,
				monthlyPayment,
				totalLoanAmount,
				make,
				mileage,
				model,
				trim,
				year,
				loanDescriptor,
				loanDescriptorFullName,
				collateralType
				//Address,
				//City,
				//State,
				//Zip
			} = await applicationAPI.getApplicationDetails(state.applicationId);

			const allowedDecisions = [LoanDecision.APPROVED, LoanDecision.COUNTER_OFFERED];
			const canDisplayRate = allowedDecisions.includes(decisionStatus);

			const canDisplayValue = function (detail) {
				if (!(detail > 0) && loanConfigurationId == 4) {
					return false;
				} else {
					return true;
				}
			};

			const loanDetail = {
				downPayment: formatMoney(downPayment.toString()),
				loanRate: canDisplayRate ? formatRate(loanRate.toString()) : 'TBD',
				loanTermInMonths: canDisplayValue(loanTermInMonths) ? `${loanTermInMonths} Months` : 'TBD',
				monthlyPayment:
					canDisplayRate && canDisplayValue(monthlyPayment) ? formatMoney(monthlyPayment.toString()) : 'TBD',
				totalLoanAmount: formatMoney(totalLoanAmount.toString()),
				loanDescriptor,
				loanDescriptorFullName
			};

			const loanCollateral = {
				collateralType,
				make,
				model,
				mileage,
				trim,
				year
			};

			commit('setData', { objName: 'applicationToken', data: applicationToken });
			commit('setData', { objName: 'productTypeId', data: loanConfigurationId });
			commit('setData', { objName: 'loanStatus', data: applicationStatus });
			commit('setData', { objName: 'loanDecision', data: decisionStatus });
			commit('setData', { objName: 'submitDate', data: submitDate });
			commit('setData', { objName: 'externalApplicationId', data: externalApplicationId });
			commit('setData', { objName: 'loanDetail', data: loanDetail });
			commit('setData', { objName: 'loanCollateral', data: loanCollateral });

			return state;
		} catch (error) {
			throw error;
		}
	},
	async getDocuments({ commit, state }) {
		if (state.documentSyncResult !== DocumentSearchStatus.NOT_RUN) {
			return state.documents;
		}

		try {
			let documents = [];

			const { hasDocuments } = await documentAPI.syncDocumentsByApplication(state.applicationId);

			commit('setData', {
				objName: 'documentSyncResult',
				data: hasDocuments ? DocumentSearchStatus.HAS_DOCUMENTS : DocumentSearchStatus.NO_DOCUMENTS
			});

			if (hasDocuments) {
				documents = await documentAPI.getDocumentsByApplication(state.applicationId);
				commit('setData', { objName: 'documents', data: documents });
			}

			return documents;
		} catch (error) {
			throw error;
		}
	},
	async getApplicationStatus({ commit, state }) {
		try {
			const { statusBar } = await applicationAPI.getApplicationStatus(state.applicationId);

			commit('setData', { objName: 'applicationStatus', data: statusBar });

			return statusBar;
		} catch (error) {
			throw error;
		}
	},
	async getWelcomeMessage({ commit, state, rootState }) {
		try {
			const message = await welcomeMessageAPI.getMessage(state.applicationId, rootState.applicant.applicantToken);
			commit('applicant/setData', { objName: 'welcomeMessage', data: message }, { root: true });

			return message;
		} catch (error) {
			throw error;
		}
	},
	getTabTitle({ state }) {
		return `${state.loanDetail.loanDescriptor} LOAN (${state.externalApplicationId})`;
	},
	getProductType({ state }) {
		return state.productTypeId;
	},
	getDetailSectionTitle({ state }) {
		return `${state.loanDetail.loanDescriptorFullName} DETAILS`;
	},
	getStatusSectionTitle({ state }) {
		return `${state.loanDetail.loanDescriptorFullName} STATUS`;
	},
	hasPreQualificationDocument({ state }) {
		return (
			state.documentSyncResult === DocumentSearchStatus.HAS_DOCUMENTS &&
			state.documents.findIndex(x => x.documentType === DocumentType.PRE_QUALIFICATION_LETTER) >= 0
		);
	},
	getDocumentsByType({ state }, documentType) {
		return state.documents.filter(x => x.documentType === documentType);
	},
	async loadStipulations({ commit, state, rootState }) {
		try {
			const { stipulations } = await stipulationAPI.getStipulations(
				state.applicationId,
				rootState.applicant.applicantToken
			);

			for (let i = 0; i < stipulations.length; i++) {
				let item = stipulations[i];

				item.applicationId = state.applicationId;
				item.applicationToken = state.applicationToken;
				item.moduleName = `stipulation${item.stipulationId}`;
			}

			commit('setData', { objName: 'stipulations', data: stipulations });

			return stipulations;
		} catch (error) {
			throw error;
		}
	},
	async loadTasks({ commit, state }) {
		const { tasks } = await taskAPI.getTasks(state.applicationId);

		if (tasks != null) {
			for (const task of tasks) {
				task.applicationId = state.applicationId;
				task.applicationToken = state.applicationToken;
				task.moduleName = `task${task.id}`;
			}
		}

		commit('setData', { objName: 'tasks', data: tasks });

		return tasks;
	},
	async getCollateralDescription({ state }) {
		return await collateralAPI.getCollateralDescription(state.applicationId);
	},
	async getApplicationDisclosures({ state }) {
		return await applicationAPI.getApplicationDisclosures(state.applicationId);
	},
	async getLoanDetailOverrides({ state }) {
		return await applicationAPI.getLoanDetailOverrides(state.applicationId);
	},
	async loadNotes({ commit, state }) {
		const { notes } = await noteAPI.getNotes(state.applicationId);

		if (notes !== null) {
			for (const note of notes) {
				note.applicationId = state.applicationId;
				note.applicationToken = state.applicationToken;
				note.moduleName = `note${note.id}`;
			}
		}

		commit('setData', { objName: 'notes', data: notes });

		return notes;
	}
};

const getters = {};

export { state, resetState, actions, getters, mutations };

export default {
	state,
	resetState,
	actions,
	getters,
	mutations
};
