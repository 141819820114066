export default (captchaUseConfigurations = [], auditProcessingStage = '', captchaImplementationType = '') => {

    let captchaResults = [];

    try {

        for (let i = 0; i < captchaUseConfigurations.length; i++) {

            const useConfig = captchaUseConfigurations[i];

            if (auditProcessingStage === useConfig.processingStage) {

                if (!captchaImplementationType || (captchaImplementationType === useConfig.implementation)) {

                    captchaResults.push(useConfig);

                }

            }

        }
        
    } catch (error) {

        console.error(error);

    }

    return captchaResults;

};